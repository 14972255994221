export default {
  'sourceInformation.router.name': 'Media',
  'sourceInformation.router.nameBreadcrumb': 'Media Management',
  'sourceInformation.tabs.media': 'Media',
  'sourceInformation.tabs.template': 'Template',
  'sourceInformation.tabs.playlist': 'Playlist',
  'sourceInformation.tabs.mediaShare': 'Shared Media',
  'sourceInformation.breadcrumbs.media.name': 'Media',
  'sourceInformation.breadcrumbs.template.name': 'Template',
  'sourceInformation.breadcrumbs.playlist.name': 'Playlist',
  'sourceInformation.breadcrumbs.mediaShares.name': 'Shared Media',
  'sourceInformation.router.media.name': 'Media',
  'sourceInformation.media.title': 'Media List',
  'sourceInformation.media.paginationKey': 'Items per page',
  'sourceInformation.media.upload.choose': 'Choose',
  'sourceInformation.media.upload.title': 'or drag files here',
  'sourceInformation.media.upload.note':
    'Supports files up to 1GB and performs best with files under 300MB.',
  'sourceInformation.media.code.format.video': 'Video',
  'sourceInformation.media.code.format.image': 'Image',
  'sourceInformation.media.code.format.stream': 'Live Streaming',
  'sourceInformation.media.code.format.fmhz': 'FM Channel',
  'sourceInformation.media.code.format.audio': 'Audio',
  'sourceInformation.media.delete.title': 'Confirm Media File Deletion',
  'sourceInformation.media.delete.content': 'Are you sure to delete this media?',
  'sourceInformation.media.approve.title': 'Media Approval Confirmation',
  'sourceInformation.media.approve.content': 'Will selected Media be approved for broadcasting?',
  'sourceInformation.media.filter.approvalStatus': 'Status',
  'sourceInformation.media.filter.approvalStatus.approve': 'Approved',
  'sourceInformation.media.filter.approvalStatus.notApprovedYet': 'Waiting for Approval',
  'sourceInformation.media.filter.approvalStatus.refuse': 'Refused',
  'sourceInformation.media.filter.format.code': 'Media Type',
  'sourceInformation.media.filter.field.id': 'Category',
  'sourceInformation.media.filter.createdAt': 'Created Date',
  'sourceInformation.media.modal.create': 'Add Media',
  'sourceInformation.media.modal.update': 'Update Media File',
  'sourceInformation.media.modal.form.name': 'Media Name',
  'sourceInformation.media.modal.form.name.invalid': 'Invalid media file name format',
  'sourceInformation.media.modal.form.fieldId': 'Category',
  'sourceInformation.media.modal.form.resourceType': 'Content',
  'sourceInformation.media.modal.form.resourceType.0': 'File',
  'sourceInformation.media.modal.form.resourceType.1': 'Download from URL',
  'sourceInformation.media.modal.form.resourceType.2': 'Live Streaming',
  'sourceInformation.media.modal.form.resourceType.3': 'FM Channel',
  'sourceInformation.media.modal.form.file': 'File',
  'sourceInformation.media.modal.form.resourceUrl.url': 'URL',
  'sourceInformation.media.modal.form.resourceUrl.stream': 'Live Streaming Link',
  'sourceInformation.media.modal.form.resourceUrl.fm': 'Channel Frequency',
  'sourceInformation.media.modal.notEnoughCapacity.title': 'You Have Run Out of Storage Space',
  'sourceInformation.media.modal.notEnoughCapacity.content':
    'Please change to another subscription or free up space in the media library to upload new media.',
  'sourceInformation.media.modal.upgradePackage.warning.title': 'Upgrade Subscription Failed',
  'sourceInformation.media.modal.upgradePackage.warning.content':
    'Your account already has a pending subscription. To change to another subscription, please contact the system admin.',
  'sourceInformation.media.modal.refuseApproval.title': 'Refuse to Approve Media',
  'sourceInformation.media.modal.refuseApproval.content':
    'Are you sure to refuse to approve these media files?',
  'sourceInformation.media.modal.refuseApproval.label': 'Reason for Refusal',
  'sourceInformation.media.modal.refuseApproval.reasonRefusal': 'Reason for Refusal',
  'sourceInformation.media.modal.refuseApproval.reasonRefusal.placeholder':
    'Please enter the reason here',
  'sourceInformation.media.modal.shareInfo.title': 'Sharing Media',
  'sourceInformation.media.modal.shareInfo.tree.title': 'Choose location to share',
  'sourceInformation.media.modal.shareInfo.tree.required':
    'Please select at least one location to share with',
  'sourceInformation.media.modal.shareInfo.info.title': 'Media Information',
  'sourceInformation.media.modal.shareInfo.info.name': 'Media Name',
  'sourceInformation.media.modal.shareInfo.info.field': 'Category',
  'sourceInformation.media.modal.shareInfo.info.formatName': 'Media Type',
  'sourceInformation.media.modal.shareInfo.info.formatSize': 'Duration',
  'sourceInformation.router.media.info.name': 'Media Information',
  'sourceInformation.media.info.tabs.media': 'Media Information',
  'sourceInformation.media.info.tabs.usageNews': 'Usage Broadcast',
  'sourceInformation.media.info.breadcrumb.name': 'Media Information',
  'sourceInformation.media.info.breadcrumb.usageNews': 'Usage Broadcast',
  'sourceInformation.media.info.sidebar.title': 'Media Information',
  'sourceInformation.media.info.sidebar.name': 'Media Name',
  'sourceInformation.media.info.sidebar.field': 'Category',
  'sourceInformation.media.info.sidebar.formatName': 'Media Type',
  'sourceInformation.media.info.sidebar.resourceUrl.1': 'Content',
  'sourceInformation.media.info.sidebar.resourceUrl.2': 'Content',
  'sourceInformation.media.info.sidebar.duration': 'Duration',
  'sourceInformation.media.info.sidebar.size': 'Capacity',
  'sourceInformation.media.info.sidebar.approvalStatus': 'Status',
  'sourceInformation.media.info.sidebar.approverName': 'Approver',
  'sourceInformation.media.info.sidebar.creatorName': 'Creator',
  'sourceInformation.media.info.sidebar.createdAt': 'Created Date',
  'sourceInformation.media.info.sidebar.description': 'Description',
  'sourceInformation.media.info.sidebar.reasonRefusal': 'Reason for Refusal',
  'sourceInformation.media.info.sidebar.facilityShare': 'Shared Location',
  'sourceInformation.media.info.sidebar.processingStatus.param':
    '{status,select, 1 {Processing} 2 {Processed} other {Other}}',
  'sourceInformation.media.info.sidebar.status.param':
    '{status,select, 1 {Approved} 2 {Wait for Approval} 3 {Rejected} other {Other}}',
  'sourceInformation.media.info.follow.title': 'Monitor the sharing status of Media',
  'sourceInformation.media.info.table.facility.name': 'Shared Location',
  'sourceInformation.media.info.table.sharingTime': 'Sharing Time',
  'sourceInformation.media.info.table.receptionStatus': 'Reception Status',
  'sourceInformation.media.info.table.receptionStatus.param':
    '{status,select, 1 {Not Received} 2 {Received} other {Other}}',
  'sourceInformation.media.info.delete.title': 'Media File Deletion Confirmation',
  'sourceInformation.media.info.delete.content': 'Are you sure to delete this media?',
  'sourceInformation.media.info.approve.title': 'Media Approval Confirmation',
  'sourceInformation.media.info.approve.content':
    'Will selected Media be approved for broadcasting?',
  'sourceInformation.media.info.filter.sharingTime': 'Sharing Time',
  'sourceInformation.media.info.filter.receptionStatus': 'Status',
  'sourceInformation.media.info.filter.receptionStatus.notUsedYet': 'Not Received',
  'sourceInformation.media.info.filter.receptionStatus.used': 'Received',
  'sourceInformation.router.template.name': 'Template',
  'sourceInformation.template.title': 'List of Templates',
  'sourceInformation.template.table.name': 'Template Name',
  'sourceInformation.template.table.resolutionWidth': 'Screen Ratio',
  'sourceInformation.template.table.createdAt': 'Created Date',
  'sourceInformation.template.table.creator.name': 'Creator',
  'sourceInformation.template.table.usageStatus': 'Status',
  'sourceInformation.template.table.usageStatus.param':
    '{status,select,  1 {Unused} 2 {In Used} other {Undefined}}',
  'sourceInformation.template.delete.title': 'Template Deletion Confirmation',
  'sourceInformation.template.delete.content':
    'Are you sure to delete selected templates and cannot recover them?',
  'sourceInformation.template.filter.createdAt': 'Created Date',
  'sourceInformation.template.filter.userStatus': 'Status',
  'sourceInformation.router.template.edit': 'Update Template',
  'sourceInformation.router.template.create': 'Create Template',
  'sourceInformation.template.create.breadcrumb.info.name': 'Template Information',
  'sourceInformation.template.create.code.format.video': 'Video',
  'sourceInformation.template.create.code.format.image': 'Image',
  'sourceInformation.template.create.code.format.stream': 'Live Streaming',
  'sourceInformation.template.create.code.format.fmhz': 'FM Channel',
  'sourceInformation.template.create.code.format.audio': 'Audio',
  'sourceInformation.template.create.audio': 'Audio Used',
  'sourceInformation.template.create.name': 'Template Name',
  'sourceInformation.template.create.ratio': 'Screen Ratio',
  'sourceInformation.template.create.ratioWidth.required': 'Please enter width',
  'sourceInformation.template.create.ratioHeight.required': 'Please enter height',
  'sourceInformation.template.create.ratioWidth': 'Width',
  'sourceInformation.template.create.ratioHeight': 'Height',
  'sourceInformation.template.create.duration': 'Duration',
  'sourceInformation.template.create.duration.required': 'Please enter duration',
  'sourceInformation.template.create.media': 'Media',
  'sourceInformation.template.create.media.search.placeholder': 'Search...',
  'sourceInformation.template.create.size': 'Size (%)',
  'sourceInformation.template.create.width': 'Width',
  'sourceInformation.template.create.height': 'Height',
  'sourceInformation.template.create.position': 'Position (%)',
  'sourceInformation.template.create.positionX': 'X-axis',
  'sourceInformation.template.create.positionY': 'Y-axis',
  'sourceInformation.template.create.zIndex': 'Z-index',
  'sourceInformation.template.create.zIndex.placeholder': 'Z-index',
  'sourceInformation.template.info.tabs.name': 'Template Information',
  'sourceInformation.template.info.tabs.newsLetters': 'Usage Broadcast',
  'sourceInformation.template.info.breadcrumbs.newsLetters': 'Usage Broadcast',
  'sourceInformation.template.info.breadcrumbs.name': 'Template Information',
  'sourceInformation.router.playlist.name': 'Playlist',
  'sourceInformation.playlist.title': 'Playlist',
  'sourceInformation.playlist.table.name': 'Playlist Name',
  'sourceInformation.playlist.table.duration': 'Playback Duration',
  'sourceInformation.playlist.table.field.name': 'Category',
  'sourceInformation.playlist.table.creator.name': 'Creator',
  'sourceInformation.playlist.table.createdAt': 'Created Date',
  'sourceInformation.playlist.table.usageStatus': 'Status',
  'sourceInformation.playlist.table.usageStatus.param':
    '{status,select,  1 {Unused} 2 {In Used} other {Undefined}}',
  'sourceInformation.playlist.delete.title': 'Playlist Deletion Confirmation',
  'sourceInformation.playlist.delete.content':
    'Are you sure to delete these selected playlists and cannot recover them?',
  'sourceInformation.playlist.filter.fields': 'Category',
  'sourceInformation.playlist.filter.createdAt': 'Created Date',
  'sourceInformation.router.playlist.create.name': 'Create Playlist',
  'sourceInformation.router.playlist.update.name': 'Update Playlist',
  'sourceInformation.playlist.info.code.format.video': 'Video',
  'sourceInformation.playlist.info.code.format.image': 'Image',
  'sourceInformation.playlist.info.code.format.stream': 'Live Streaming',
  'sourceInformation.playlist.info.code.format.fmhz': 'FM Channel',
  'sourceInformation.playlist.info.code.format.audio': 'Audio',
  'sourceInformation.playlist.info.form.emptySource.warn': 'The list of media is empty',
  'sourceInformation.playlist.info.mainCard.title': 'List of Media Source',
  'sourceInformation.playlist.info.form.name.warning': 'Please enter playlist name',
  'sourceInformation.playlist.info.form.name.warning255': 'Name length exceeds 255 characters',
  'sourceInformation.playlist.info.form.name': 'Name',
  'sourceInformation.playlist.info.form.name.placeholder': 'Name',
  'sourceInformation.playlist.info.form.field': 'Category',
  'sourceInformation.playlist.info.form.field.placeholder': 'Category',
  'sourceInformation.playlist.info.form.playMode': 'Play Mode',
  'sourceInformation.playlist.info.form.playMode.1': 'In Order',
  'sourceInformation.playlist.info.form.playMode.2': 'Random Order',
  'sourceInformation.playlist.info.form.duration': 'Total Broadcast Duration',
  'sourceInformation.playlist.info.form.duration.placeholder': 'Total Broadcast Duration',
  'sourceInformation.playlist.info.form.description': 'Description',
  'sourceInformation.playlist.info.form.description.placeholder': 'Description',
  'sourceInformation.playlist.info.table.order': 'Playback Order',
  'sourceInformation.playlist.info.table.name': 'Media Name',
  'sourceInformation.playlist.info.table.duration': 'Duration',
  'sourceInformation.playlist.info.table.sourceType': 'Media',
  'sourceInformation.playlist.info.table.sourceType.1': 'Template',
  'sourceInformation.playlist.info.table.sourceType.2': 'Media',
  'sourceInformation.playlist.info.form.modal.sourceOptions.title':
    'Select the media sources to add to the playlist',
  'sourceInformation.playlist.info.form.modal.tableTemplate.table.name': 'Media Name',
  'sourceInformation.playlist.info.form.modal.tableTemplate.table.duration': 'Duration',
  'sourceInformation.playlist.info.form.modal.tableTemplate.table.sourceCount':
    'Number of Additions',
  'sourceInformation.playlist.info.form.modal.sourceSelected.title': 'Selected Media Sources',
  'sourceInformation.playlist.info.form.modal.tableTemplateDisplay.table.name': 'Name',
  'sourceInformation.playlist.info.form.modal.tableTemplateDisplay.table.duration': 'Duration',
  'sourceInformation.playlist.info.form.modal.tableTemplateDisplay.table.sourceRemove': 'Remove',
  'sourceInformation.playlist.info.form.modal.durationWarning':
    'The duration of all media sources must be greater than 1',
  'sourceInformation.playlist.info.form.modal.filter.field': 'Category',
  'sourceInformation.playlist.info.form.modal.addSource': 'Add Media Source',
  'sourceInformation.playlist.info.form.modal.sourceType': 'Media Source',
  'sourceInformation.playlist.info.form.modal.sourceType.1': 'Template',
  'sourceInformation.playlist.info.form.modal.sourceType.2': 'Media',
  'sourceInformation.router.playlist.info.name': 'Playlist Information',
  'sourceInformation.playlist.info.tabs.detail': 'Playlist Information',
  'sourceInformation.playlist.info.tabs.detail.news': 'Broadcast Usage',
  'sourceInformation.playlist.info.breadcrumbs.detail.news': 'Broadcast Usage',
  'sourceInformation.playlist.info.breadcrumbs.detail': 'Playlist Information',
  'sourceInformation.playlist.info.detail.sidebar.title': 'Playlist Information',
  'sourceInformation.playlist.info.detail.sidebar.name': 'Playlist Name',
  'sourceInformation.playlist.info.detail.sidebar.field': 'Category',
  'sourceInformation.playlist.info.detail.sidebar.creator': 'Creator',
  'sourceInformation.playlist.info.detail.sidebar.createdAt': 'Created Date',
  'sourceInformation.playlist.info.detail.sidebar.duration': 'Playback Duration',
  'sourceInformation.playlist.info.detail.sidebar.playMode': 'Play Mode',
  'sourceInformation.playlist.info.detail.sidebar.playMode.1': 'In Order',
  'sourceInformation.playlist.info.detail.sidebar.playMode.2': 'Random Order',
  'sourceInformation.playlist.info.detail.sidebar.description': 'Description',
  'sourceInformation.playlist.info.detail.title': 'List of Media Source',
  'sourceInformation.playlist.info.detail.delete.title': 'Playlist Deletion Confirmation',
  'sourceInformation.playlist.info.detail.delete.content':
    'Are you sure to delete these selected playlists and cannot recover them?',
  'sourceInformation.playlist.info.detail.table.order': 'Playback Order',
  'sourceInformation.playlist.info.detail.table.sourceName': 'Media Name',
  'sourceInformation.playlist.info.detail.table.duration': 'Duration',
  'sourceInformation.playlist.info.detail.table.sourceType': 'Media',
  'sourceInformation.playlist.info.detail.table.sourceType.1': 'Template',
  'sourceInformation.playlist.info.detail.table.sourceType.2': 'Media',
  'sourceInformation.router.mediaShares.name': 'Shared Media',
  'sourceInformation.mediaShares.title': 'Shared Media',
  'sourceInformation.mediaShares.table.media.name': 'Media Name',
  'sourceInformation.mediaShares.table.media.field.name': 'Category',
  'sourceInformation.mediaShares.table.media.creator.name': 'Creator',
  'sourceInformation.mediaShares.table.media.createdAt': 'Created Date',
  'sourceInformation.mediaShares.table.media.facility.name': 'Shared Location',
  'sourceInformation.mediaShares.table.sharingTime': 'Shared Date',
  'sourceInformation.mediaShares.table.play.tooltip': 'Play',
  'sourceInformation.mediaShares.filter.fields': 'Category',
  'sourceInformation.mediaShares.filter.createdAt': 'Created Date',
  'sourceInformation.mediaShares.check.tooltip': 'Receive',
  'sourceInformation.mediaShares.check.title': 'Confirmation of Media',
  'sourceInformation.mediaShares.check.content':
    'The media you choose to receive will become your media.',
  'sourceInformation.mediaShares.delete.title': 'Confirm Deletion of Shared Media',
  'sourceInformation.mediaShares.delete.content':
    'The selected media will be deleted from your location and cannot be recovered.',
  'sourceInformation.mediaShares.modal.title': 'Shared Media Details',
  'sourceInformation.mediaShares.modal.nameFile': 'Media Name',
  'sourceInformation.mediaShares.modal.approver': 'Approver',
  'sourceInformation.mediaShares.modal.fields': 'Category',
  'sourceInformation.mediaShares.modal.creator': 'Creator',
  'sourceInformation.mediaShares.modal.type': 'File Format - File Type',
  'sourceInformation.mediaShares.modal.createAt': 'Created Date',
  'sourceInformation.mediaShares.modal.resourceUrl': 'Attached File or Content Link',
  'sourceInformation.mediaShares.modal.facility': 'Shared Location',
  'sourceInformation.mediaShares.modal.duration': 'Playback Duration',
  'sourceInformation.mediaShares.modal.sharingTime': 'Shared Date',
  'sourceInformation.mediaShares.modal.code.format.video': 'Video',
  'sourceInformation.mediaShares.modal.code.format.image': 'Image',
  'sourceInformation.mediaShares.modal.code.format.stream': 'Live Streaming',
  'sourceInformation.mediaShares.modal.code.format.fmhz': 'FM Channel',
  'sourceInformation.mediaShares.modal.code.format.audio': 'Audio',
};
