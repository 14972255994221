export default {
  'settings.systemUser.router.name': 'Cài đặt hệ thống',
  'settings.systemUser.router.role.name': 'Quản lý người dùng',
  'settings.systemUser.router.role.breadcrumb': 'Quản lý vai trò',
  'settings.systemUser.user.tab.name': 'Người dùng',
  'settings.systemUser.role.tab.name': 'Danh sách vai trò',
  'settings.systemUser.user.breadCrumbs': 'Người dùng',
  'settings.systemUser.role.breadCrumbs': 'Danh sách vai trò',
  'settings.systemUser.user.havePermission': 'Đã phân quyền',
  'settings.systemUser.user.noPermission': 'Chưa phân quyền',
  'settings.systemUser.user.noPermission.title': 'Danh sách người dùng chưa phân quyền',
  'settings.systemUser.user.title': 'Danh sách người dùng đã phân quyền',
  'settings.systemUser.user.table.username': 'Tên đăng nhập',
  'settings.systemUser.user.table.name': 'Họ và tên',
  'settings.systemUser.user.table.phoneNumber': 'Số điện thoại',
  'settings.systemUser.user.table.email': 'Email',
  'settings.systemUser.user.table.role.name': 'Vai trò',
  'settings.systemUser.user.table.status': 'Trạng thái',
  'settings.systemUser.user.table.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',
  'settings.systemUser.user.table.lastAccessedAt': 'Truy cập lần cuối',
  'settings.systemUser.user.delete.title': 'Xác nhận xóa tài khoản?',
  'settings.systemUser.user.delete.content': 'Dữ liệu thông tin tài khoản này sẽ bị xóa.',
  'settings.systemUser.user.filter.role.name': 'Vai trò',
  'settings.systemUser.user.filter.status': 'Trạng thái',
  'settings.systemUser.user.filter.status.active': 'Đang hoạt động',
  'settings.systemUser.user.filter.status.notActive': 'Ngưng hoạt động',
  'settings.systemUser.user.modal.update': 'Cập nhật người dùng',
  'settings.systemUser.user.modal.information': 'Thông tin người dùng',
  'settings.systemUser.user.modal.create': 'Tạo người dùng',
  'settings.systemUser.user.modal.remind.password':
    'Nếu không nhập mật khẩu, thì mật khẩu sẽ được tự động tạo và gửi về email của người dùng.',
  'settings.systemUser.user.modal.form.username': 'Tên đăng nhập',
  'settings.systemUser.user.modal.form.username.placeholder': 'Vui lòng nhập tên đăng nhập',
  'settings.systemUser.user.modal.form.username.note': 'Tên đăng nhập để truy cập là',
  'settings.systemUser.user.modal.form.validatorUserName': 'Tên đăng nhập không đúng định dạng',
  'settings.systemUser.user.modal.form.name': 'Họ và tên',
  'settings.systemUser.user.modal.form.validatorUndefined': 'Họ và tên không đúng định dạng',
  'settings.systemUser.user.modal.form.email': 'Email',
  'settings.systemUser.user.modal.form.password': 'Mật khẩu',
  'settings.systemUser.user.modal.form.password.required':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'settings.systemUser.user.modal.form.identifierNumber': 'Số CMND/CCCD',
  'settings.systemUser.user.modal.form.roleId': 'Vai trò',
  'settings.systemUser.user.modal.form.roleId.validator': 'Vui lòng nhập vai trò',
  'settings.systemUser.user.modal.form.isActive': 'Trạng thái hoạt động',
  'settings.systemUser.user.modal.form.isActive.validator': 'Vui lòng nhập trạng thái hoạt động',
  'settings.systemUser.user.modal.form.status.active': 'Đang hoạt động',
  'settings.systemUser.user.modal.form.status.notActive': 'Ngưng hoạt động',
  'settings.systemUser.user.modal.form.userPhone': 'Số điện thoại',
  'settings.systemUser.user.modal.form.timezoneId': 'Múi giờ',
  'settings.systemUser.user.modal.form.timezoneId.validator': 'Vui lòng nhập múi giờ',
  'settings.systemUser.user.modal.form.confirmPassWord': 'Xác nhận mật khẩu',
  'settings.systemUser.user.modal.form.confirmPassWord.required':
    'Hai mật khẩu bạn đã nhập không khớp',
  'settings.systemUser.user.modal.form.userAvatar': 'Hình đại diện',
  'settings.systemUser.role.title': 'Danh sách vai trò',
  'settings.systemUser.role.table.name': 'Tên vai trò',
  'settings.systemUser.role.table.code': 'Mã vai trò',
  'settings.systemUser.role.table.description': 'Mô tả',
  'settings.systemUser.role.delete.title': 'Xác nhận xóa vai trò?',
  'settings.systemUser.role.delete.content': 'Dữ liệu thông tin vai trò này sẽ bị xóa.',
  'settings.systemUser.router.role.add': 'Thêm vai trò',
  'settings.systemUser.router.role.update': 'Cập nhật vai trò',
  'settings.systemUser.router.role.info': 'Chi tiết vai trò',
  'settings.systemUser.role.info.titleRight': 'Phân quyền chức năng',
  'settings.systemUser.role.info.required': 'Vui lòng chọn phân quyền chức năng',
  'settings.systemUser.role.form.table.resource': 'Nhóm chức năng',
  'settings.systemUser.role.form.table.permissions': 'Chức năng',
  'settings.systemUser.role.form.name': 'Tên vai trò',
  'settings.systemUser.role.form.name.validatorUndefined': 'Vui lòng nhập tên vai trò',
  'settings.systemUser.role.form.name.validatorName': 'Tên vai trò không đúng định dạng',
  'settings.systemUser.role.form.code': 'Mã vai trò',
  'settings.systemUser.role.form.code.validatorCode': 'Mã vai trò không đúng định dạng',
  'settings.systemUser.role.form.code.validatorUndefinedCode': 'Vui lòng nhập mã vai trò',
  'settings.systemUser.role.form.description': 'Mô tả',
  'settings.endUser.router.user.name': 'Cài đặt hệ thống',
  'settings.endUser.user.title': 'Danh sách người dùng hệ thống',
  'settings.endUser.router.userLogs.name': 'Nhật ký người dùng',
  'settings.endUser.userLogs.title': 'Nhật ký người dùng',
  'settings.endUser.userLogs.ApprovalStatus.params.media':
    '{status, select, 1 {Đã duyệt} 2 {Chờ duyệt} 3 {Từ chối duyệt} other {--}}',
  'settings.endUser.userLogs.ApprovalStatus.params.news':
    '{status, select, 1 {Chờ duyệt} 2 {Đã duyệt} 3 {Từ chối duyệt} other {--}}',
  'settings.endUser.userLogs.OperationStatus.params':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',
  'settings.endUser.userLogs.PlayingStatus.params':
    '{status, select, 1 {Chưa phát} 2 {Đang phát} 3 {Tạm ngưng} 4 {Đã hủy} 5 {Đã kết thúc} 6 {Chờ phát}  other {--}}',
  'settings.endUser.userLogs.ConnectionStatus.params':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Khác}}',
  'settings.endUser.userLogs.PlayMode.params':
    '{status,select, 1 {Có thứ tự} 2 {Ngẫu nhiên} other {Khác}}',
  'settings.endUser.userLogs.SourceType.params':
    '{status,select, 1 {Link tải hoặc tệp tin} 2 {Link phát trực tiếp} 3 {Kênh FM} other {Khác}}',
  'settings.endUser.userLogs.Status.params':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {--}}',
  'settings.endUser.userLogs.table.actor.name': 'Người tác động',
  'settings.endUser.userLogs.table.impactTime': 'Thời gian tác động',
  'settings.endUser.userLogs.table.actionType': 'Chức năng bị tác động',
  'settings.endUser.userLogs.table.properties.oldValue': 'Nội dung cũ',
  'settings.endUser.userLogs.table.properties.newValue': 'Nội dung mới',
  'settings.endUser.userLogs.filter.createdAt': 'Thời gian',
  'settings.endUser.userLogs.actionType.create': 'Tạo',
  'settings.endUser.userLogs.actionType.update': 'Cập nhật',
  'settings.endUser.userLogs.actionType.delete': 'Xóa',
  'settings.endUser.userLogs.actionType.approve': 'Phê duyệt',
  'settings.endUser.userLogs.actionType.resufe': 'Từ chối',
  'settings.endUser.userLogs.actionType.share': 'Chia sẻ',
  'settings.endUser.userLogs.actionType.reception': 'Tiếp nhận',
  'settings.endUser.userLogs.actionType.cancel': 'Hủy',
  'settings.endUser.userLogs.resourceType.facility': 'Cơ sở',
  'settings.endUser.userLogs.resourceType.user': 'Người dùng',
  'settings.endUser.userLogs.resourceType.device': 'Thiết bị',
  'settings.endUser.userLogs.resourceType.media': 'Media',
  'settings.endUser.userLogs.resourceType.template': 'Template',
  'settings.endUser.userLogs.resourceType.playlist': 'Danh sách phát',
  'settings.endUser.userLogs.resourceType.newsletter': 'Bản tin',
  'settings.endUser.userLogs.resourceType.field': 'Lĩnh vực',
  'settings.endUser.userLogs.resourceType.supplier': 'Nhà cung cấp',
  'settings.endUser.userLogs.resourceType.tariffPackage': 'Gói cước',
  'settings.endUser.userLogs.resourceType.organization': 'Tổ chức',
  'settings.endUser.userLogs.Username': 'Họ và tên',
  'settings.endUser.userLogs.Email': 'Email',
  'settings.endUser.userLogs.Name': 'Tên',
  'settings.endUser.userLogs.PhoneNumber': 'Số điện thoại',
  'settings.endUser.userLogs.Status': 'Trạng thái',
  'settings.endUser.userLogs.IdentifierNumber': 'Số CMND/CCCD',
  'settings.endUser.userLogs.Role': 'Vai trò',
  'settings.endUser.userLogs.Code': 'Mã',
  'settings.endUser.userLogs.ResolutionWidth': 'Chiều ngang',
  'settings.endUser.userLogs.ResolutionHeight': 'Chiều dọc',
  'settings.endUser.userLogs.Duration': 'Thời lượng',
  'settings.endUser.userLogs.Source': 'Nguồn',
  'settings.endUser.userLogs.Field.Name': 'Lĩnh vực',
  'settings.endUser.userLogs.PlayMode': 'Chế độ phát',
  'settings.endUser.userLogs.Title': 'Tiêu đề',
  'settings.endUser.userLogs.ReasonRefusal': 'Lý do từ chối',
  'settings.endUser.userLogs.SourceType': 'Loại nguồn tin',
  'settings.endUser.userLogs.ApprovalStatus': 'Trạng thái duyệt',
  'settings.endUser.userLogs.Media.Name': 'Tên media',
  'settings.endUser.userLogs.Template.Name': 'Tên bản mẫu',
  'settings.endUser.userLogs.Playlist.Name': 'Tên phát',
  'settings.endUser.userLogs.Checksum': 'Kiểm tra',
  'settings.endUser.userLogs.Approver.Name': 'Tên',
  'settings.endUser.userLogs.Facility.Name': 'Tên cơ sở',
  'settings.endUser.userLogs.Parent.Name': 'Tên',
  'settings.endUser.userLogs.Account.Name': 'Tên',
  'settings.endUser.userLogs.IsAutoRenewal': 'Gia hạn',
  'settings.endUser.userLogs.OperationStatus': 'Trạng thái hoạt động',
  'settings.endUser.userLogs.TariffPackage.Code': 'Mã',
  'settings.endUser.userLogs.SpecificAddress': 'Địa chỉ',
  'settings.endUser.userLogs.PlayingStatus': 'Trạng thái phát',
  'settings.endUser.userLogs.ConnectionStatus': 'Trạng thái kết nối',
  'settings.endUser.userLogs.Supplier.Name': 'Tên nhà cung cấp',
  'settings.endUser.userLogs.Content': 'Nội dung',
  'settings.endUser.userLogs.Role.Name': 'Vai trò',
  'settings.endUser.userLogs.FormatCode': 'Loại media',
  'settings.endUser.userLogs.ParentId': 'ID cơ sở cha',
  'settings.endUser.userLogs.ParentName': 'Tên cơ sở cha',
  'settings.endUser.suppliers.title': 'Danh sách nhà cung cấp',
  'settings.endUser.suppliers.router.name': 'Nhà cung cấp',
  'settings.endUser.suppliers.router.nameBreadcrumb': 'Quản lý nhà cung cấp',
  'settings.endUser.suppliers.table.code': 'Mã nhà cung cấp',
  'settings.endUser.suppliers.table.name': 'Tên nhà cung cấp',
  'settings.endUser.suppliers.table.description': 'Mô tả',
  'settings.endUser.suppliers.delete.title': 'Xác nhận xóa nhà cung cấp',
  'settings.endUser.suppliers.delete.content': 'Nhà cung cấp này sẽ bị xóa khỏi hệ thống',
  'settings.endUser.suppliers.modal.create': 'Thêm thông tin nhà cung cấp',
  'settings.endUser.suppliers.modal.update': 'Cập nhật thông tin nhà cung cấp',
  'settings.endUser.suppliers.modal.form.code': 'Mã nhà cung cấp',
  'settings.endUser.suppliers.modal.form.validatorCode': 'Mã nhà cung cấp không đúng định dạng',
  'settings.endUser.suppliers.modal.form.name': 'Tên nhà cung cấp',
  'settings.endUser.suppliers.modal.form.validatorName': 'Tên nhà cung cấp không đúng định dạng',
  'settings.endUser.suppliers.modal.form.description': 'Mô tả',
  'settings.endUser.router.fields.name': 'Lĩnh vực',
  'settings.endUser.router.fields.nameBreadcrumb': 'Quản lý lĩnh vực',
  'settings.endUser.fields.title': 'Danh sách lĩnh vực',
  'settings.endUser.fields.delete.title': 'Xác nhận xóa lĩnh vực',
  'settings.endUser.fields.delete.content': 'Lĩnh vực này sẽ bị xóa khỏi hệ thống',
  'settings.endUser.fields.table.name': 'Tên lĩnh vực',
  'settings.endUser.fields.table.code': 'Mã lĩnh vực',
  'settings.endUser.fields.modal.form.code': 'Mã lĩnh vực',
  'settings.endUser.fields.modal.form.code.validatorCode': 'Mã lĩnh vực không đúng định dạng',
  'settings.endUser.fields.modal.form.name': 'Tên lĩnh vực',
  'settings.endUser.fields.modal.form.name.validatorName': 'Tên lĩnh vực không đúng định dạng',
  'settings.endUser.fields.modal.create': 'Thêm mới lĩnh vực',
  'settings.endUser.fields.modal.update': 'Cập nhật lĩnh vực',
  'settings.endUser.fields.modal.information': 'Thông tin lĩnh vực',
  'settings.endUser.router.organizationSetting.name': 'Thiết lập hệ thống',
  'settings.endUser.organizationSetting.title': 'Thiết lập hệ thống',
  'settings.endUser.organizationSetting.newsletterCreationMode': 'Chế độ tạo bản tin',
  'settings.endUser.organizationSetting.newsletterCreationMode.DistributeOnFacility':
    'Phát theo cơ cở',
  'settings.endUser.organizationSetting.newsletterCreationMode.DistributeOnDevice':
    'Phát theo thiết bị',
  'settings.endUser.organizationSetting.timezoneId': 'Múi giờ',
  'settings.endUser.organizationSetting.timezoneId.placeholder': 'Chọn múi giờ',
  'settings.endUser.organizationSetting.modal.form.newsletterCreationMode': 'Chế độ tạo bản tin',
  'settings.endUser.organizationSetting.modal.form.newsletterCreationMode.DistributeOnFacility':
    'Phát theo cơ cở',
  'settings.endUser.organizationSetting.modal.form.newsletterCreationMode.DistributeOnDevice':
    'Phát theo thiết bị',
  'settings.endUser.organizationSetting.modal.form.timezoneId': 'Múi giờ',
  'settings.endUser.organizationSetting.modal.form.timezoneId.placeholder': 'Chọn múi giờ',
  'settings.endUser.organizationSetting.modal.update': 'Chỉnh sửa thiết lập hệ thống',
  DevicesCreate: 'Tạo thiết bị',
  DevicesDelete: 'Xóa thiết bị',
  DevicesUpdate: 'Cập nhật thiết bị',
  DevicesView: 'Xem danh sách thiết bị',
  DevicesViewDetail: 'Xem chi tiết thiết bị',
  FacilitiesCreate: 'Tạo cơ sở trực thuộc',
  FacilitiesDelete: 'Xóa cơ sở trực thuộc',
  FacilitiesDevicesCreate: 'Tạo thiết bị cho cơ sở trực thuộc',
  FacilitiesDevicesDelete: 'Xóa thiết bị cho cơ sở trực thuộc',
  FacilitiesDevicesUpdate: 'Cập nhật thiết bị cho cơ sở trực thuộc',
  FacilitiesDevicesView: 'Xem danh sách thiết bị của cơ sở trực thuộc',
  FacilitiesDevicesViewDetail: 'Xem chi tiết thiết bị của cơ sở trực thuộc',
  FacilitiesMediasApprove: 'Phê duyệt media cho cơ sở trực thuộc',
  FacilitiesMediasCreate: 'Tạo media cho cơ sở trực thuộc',
  FacilitiesMediasDelete: 'Xóa media cho cơ sở trực thuộc',
  FacilitiesMediasShare: 'Chia sẻ media cho cơ sở trực thuộc',
  FacilitiesMediasUpdate: 'Cập nhật media cho cơ sở trực thuộc',
  FacilitiesMediasView: 'Xem danh sách media của cơ sở trực thuộc',
  FacilitiesMediasViewDetail: 'Xem chi tiết media của cơ sở trực thuộc',
  FacilitiesPlaylistsCreate: 'Tạo danh sách phát cho cơ sở trực thuộc',
  FacilitiesPlaylistsDelete: 'Xóa danh sách phát cho cơ sở trực thuộc',
  FacilitiesPlaylistsUpdate: 'Cập nhật danh sách phát cho cơ sở trực thuộc',
  FacilitiesPlaylistsView: 'Xem các danh sách phát của cơ sở trực thuộc',
  FacilitiesPlaylistsViewDetail: 'Xem chi tiết danh sách phát của cơ sở trực thuộc',
  FacilitiesTemplatesCreate: 'Tạo bản mẫu cho cơ sở trực thuộc',
  FacilitiesTemplatesDelete: 'Xóa bản mẫu cho cơ sở trực thuộc',
  FacilitiesTemplatesUpdate: 'Cập nhật bản mẫu cho cơ sở trực thuộc',
  FacilitiesTemplatesView: 'Xem danh sách  bản mẫu của cơ sở trực thuộc',
  FacilitiesTemplatesViewDetail: 'Xem chi tiết bản mẫu của cơ sở trực thuộc',
  FacilitiesUpdate: 'Cập nhật cơ sở',
  FacilitiesUsersCreate: 'Tạo người dùng cho cơ sở trực thuộc',
  FacilitiesUsersDelete: 'Xóa người dùng cho cơ sở trực thuộc',
  FacilitiesUsersUpdate: 'Cập nhật người dùng cho cơ sở trực thuộc',
  FacilitiesUsersView: 'Xem danh sách người dùng của cơ sở trực thuộc',
  FacilitiesUsersViewDetail: 'Xem chi tiết người dùng của cơ sở trực thuộc',
  FacilitiesView: 'Xem danh sách cơ sở trực thuộc',
  FacilitiesViewDetail: 'Xem chi tiết cơ sở trực thuộc',
  FieldsCreate: 'Tạo lĩnh vực',
  FieldsDelete: 'Xóa lĩnh vực',
  FieldsUpdate: 'Cập nhật lĩnh vực',
  FieldsView: 'Xem danh sách lĩnh vực',
  FieldsViewDetail: 'Xem chi tiết lĩnh vực',
  MediasApprove: 'Phê duyệt media',
  MediasCreate: 'Tạo media',
  MediasDelete: 'Xóa media',
  MediaSharesDelete: 'Xóa media được chia sẻ',
  MediaSharesReception: 'Tiếp nhận/Xóa media được chia sẻ',
  MediaSharesView: 'Xem danh sách media được chia sẻ',
  MediasShare: 'Chia sẻ media',
  MediasUpdate: 'Cập nhật media',
  MediasView: 'Xem danh sách media',
  MediasViewDetail: 'Xem chi tiết media',
  NewslettersApprove: 'Phê duyệt/từ chối duyệt bản tin',
  NewslettersCancel: 'Hủy bản tin',
  NewslettersCreate: 'Tạo bản tin',
  NewslettersDelete: 'Xóa bản tin',
  NewslettersUpdate: 'Cập nhật bản tin',
  NewslettersView: 'Xem danh sách bản tin',
  NewslettersViewDetail: 'Xem chi tiết bản tin',
  OrganizationsCreate: 'Tạo tổ chức',
  OrganizationsDelete: 'Xóa tổ chức',
  OrganizationsUpdate: 'Cập nhật tổ chức',
  OrganizationsView: 'Xem tổ chức',
  OrganizationsViewDetail: 'Xem chi tiết tổ chức',
  PlaylistsCreate: 'Tạo danh sách phát',
  PlaylistsDelete: 'Xóa danh sách phát',
  PlaylistsUpdate: 'Cập nhật danh sách phát',
  PlaylistsView: 'Xem các danh sách phát',
  PlaylistsViewDetail: 'Xem chi tiết danh sách phát',
  RolesCreate: 'Tạo vai trò',
  RolesDelete: 'Xóa vai trò',
  RolesUpdate: 'Cập nhật vai trò',
  RolesView: 'Xem danh sách vai trò',
  RolesViewDetail: 'Xem chi tiết vai trò',
  SuppliersCreate: 'Tạo nhà cung cấp',
  SuppliersDelete: 'Xóa nhà cung cấp',
  SuppliersUpdate: 'Cập nhật nhà cung cấp',
  SuppliersView: 'Xem danh sách nhà cung cấp',
  SuppliersViewDetail: 'Xem chi tiết nhà cung cấp',
  TemplatesCreate: 'Tạo bản mẫu',
  TemplatesDelete: 'Xóa bản mẫu',
  TemplatesUpdate: 'Cập nhật bản mẫu',
  TemplatesView: 'Xem danh sách bản mẫu',
  TemplatesViewDetail: 'Xem chi tiết bản mẫu',
  UsersCreate: 'Tạo người dùng',
  UsersDelete: 'Xóa người dùng',
  UsersUpdate: 'Cập nhật người dùng',
  UsersView: 'Xem danh sách người dùng',
  UsersViewDetail: 'Xem chi tiết người dùng',
  UsersViewUnAuthorized: 'Xem danh sách người dùng chưa phân quyền',
  AnnouncementsCreate: 'Tạo thông báo',
  AnnouncementsDelete: 'Xóa thông báo',
  AnnouncementsUpdate: 'Cập nhật thông báo',
  AnnouncementsView: 'Xem thông báo',
  AnnouncementsViewDetail: 'Xem chi tiết thông báo',
  DevicesAdjustVolume: 'Điều chỉnh âm lượng thiết bị',
  DevicesReboot: 'Khởi động lại thiết bị',
  FacilitiesDevicesAdjustVolume: 'Điều chỉnh âm lượng thiết bị của cơ sở trực thuộc',
  FacilitiesDevicesReboot: 'Khởi động lại thiết bị của cơ sở trực thuộc',
  NewslettersPlayback: 'Ngưng/Tiếp tục phát',
  FacilitiesDevicesPlaylogsView: 'Xem lịch sử phát bản tin thiết bị của cơ sở trực thuộc',
  FacilitiesMediaSharesView: 'Xem media chia sẻ của cơ sở trực thuộc',
  Facilities: 'Cơ sở',
  Devices: 'Thiết bị',
  Fields: 'Lĩnh vực',
  Medias: 'Medias',
  MediaShares: 'Media được chia sẻ',
  Newsletters: 'Bản tin',
  Playlists: 'Danh sách phát',
  Roles: 'Vai trò',
  Suppliers: 'Nhà cung cấp',
  Templates: 'Bản mẫu',
  Users: 'Người dùng',
  Organizations: 'Tổ chức',
  OverviewsEndUser: 'Trang tổng quan',
  OverviewsEndUserView: 'Xem trang tống quan người dùng cuối',
  NewslettersPlaylogsView: 'Xem lịch sử phát bản tin',
  NewslettersSubUnitView: 'Xem bản tin cơ sở con',
  NewslettersSubUnitViewDetail: 'Xem chi tiết bản tin cơ sở con',
  NewslettersPlayLog: 'Xem chi tiết bản tin cơ sở con',
  NewslettersSubUnitPlaylogsView: 'Xem lịch sử phát bản tin cơ sở con',
  OrganizationsPaymentHistoriesView: 'Xem lịch sử thanh toán tổ chức',
  OrganizationsSettingsUpdate: 'Chỉnh sửa cài đặt',
  OrganizationsSettingsView: 'Xem cài đặt',
  OrganizationsPackagesRenewal: 'Gia hạn gói cước',
  OrganizationsPackagesUnUsed: 'Cập nhật gói cước',
  DevicesPlaylogsView: 'Xem lịch sử phát của thiết bị ',
  Announcements: 'Thông báo',
  OrganizationsRenewal: 'Gia hạn gói cước tổ chức',
  OrganizationsTruncate: 'Hủy tổ chức',
  OrganizationsUnUsed: 'Nâng cấp cước tổ chức',
  OrganizationsManage: 'Quản lý cơ sở',
  Packages: 'Gói cước',
  PackagesCreate: 'Thêm gói cước',
  PackagesDelete: 'Xóa gói cước',
  PackagesPaymentHistoriesView: 'Xem lịch sử giao dịch',
  PackagesUpdate: 'Cập nhật gói cước',
  PackagesView: 'Xem gói cước',
  PackagesViewDetail: 'Xem chi tiết gói cước',
  OverviewsSystemUser: 'Trang tống quan hệ thống',
  OverviewsSystemUserView: 'Xem trang tống quan hệ thống',
  DevicesActivitieslogsView: 'Xem lịch sử hoạt động của thiết bị',
  FacilitiesDevicesSchedulesView: 'Xem lịch phát thiết bị của cơ sở trực thuộc',
  FacilitiesDevicesActivitieslogsView: 'Xem lịch sử hoạt động thiết bị của cơ sở trực thuộc',
  PaymentMethods: 'Phương thức thanh toán',
  PaymentMethodsUpdate: 'Cập nhật phương thức thanh toán',
  PaymentMethodsView: 'Xem phương thức thanh toán',

  // Update device group
  DeviceGroups: 'Nhóm thiết bị',
  DeviceGroupsCreate: 'Thêm nhóm thiết bị',
  DeviceGroupsDelete: 'Xóa nhóm thiết bị',
  DeviceGroupsUpdate: 'Cập nhật nhóm thiết bị',
  DeviceGroupsView: 'Xem chi tiết nhóm thiết bị',
};
