export default {
  'stepsView.typing.title': 'Nhập thông tin tổ chức của bạn',
  'stepsView.congratulation': 'Chúc mừng!',
  'stepsView.congratulation.desc': 'Tổ chức của bạn đã được tạo thành công!',
  'stepsView.go.mtc.solution': 'Quay về trang chủ',
  'stepsView.go.mtc.login': 'Đi tới MTC',
  'stepsView.form.organization.name': 'Tên tổ chức',
  'stepsView.form.organization.code': 'Mã định danh tổ chức',
  'stepsView.form.organization.code.placeholder': 'Vui lòng nhập mã định danh tổ chức',
  'stepsView.form.organization.code.maxLength': 'Mã định danh tổ chức tối đa 255 kí tự',
  'stepsView.form.organization.code.required': 'Mã định danh tổ chức không đúng định dạng',
  'stepsView.form.organization.code.exited': 'Mã định danh tổ chức đã tồn tại',
  'stepsView.form.organization.timeZone': 'Múi giờ',
  'stepsView.form.organization.timeZone.placeholder': 'Vui lòng chọn múi giờ',
  'stepsView.form.fullName': 'Họ và tên',
  'stepsView.form.phoneNumber': 'Số điện thoại',
  'stepsView.form.email': 'Email',
  'stepsView.form.email.placeholder': 'Vui lòng nhập email',
  'stepsView.form.email.invalid': 'Email không hợp lệ',
  'stepsView.form.email.already.exist': 'Email đã tồn tại',
  'stepsView.form.username': 'Tên đăng nhập',
  'stepsView.form.username.placeholder': 'Vui lòng tên đăng nhập',
  'stepsView.form.username.required': 'Tên đăng nhập không đúng định dạng',
  'stepsView.form.username.note': 'Tên đăng nhập để truy cập là',
  'stepsView.form.password': 'Mật khẩu',
  'stepsView.form.password.placeholder': 'Vui lòng nhập mật khẩu',
  'stepsView.form.password.required':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'stepsView.form.confirmPassWord': 'Xác nhận lại mật khẩu ',
  'stepsView.form.confirmPassWord.required': 'Hai mật khẩu bạn đã nhập không khớp',
  'stepsView.form.confirmPassWord.placeholder': 'Vui lòng nhập xác nhận mật khẩu',
  'stepsView.form.role.label': 'Phân quyền',
  'stepsView.form.role.value': 'Quản trị viên tổ chức',
};
