import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerMediaFacilityInfo: IRouter = {
  path: '/facility/:facilityId/facility-media/:mediaId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.FacilitiesMediasViewDetail,
};

export const routerOrganizationMediaFacilityInfo: IRouter = {
  path: '/organization/:organizationId/facility/:facilityId/facility-media/:mediaId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(organizationId, facility, mediaId) {
    return `/organization/${organizationId}/facility/${facility}/facility-media/${mediaId}`;
  },
};
