import React from 'react';
import { HardDrive } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerDeviceGroup: IRouter = {
  path: '/settings/device-group',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.deviceGroup.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'settings.endUser.router.deviceGroup.nameBreadcrumb',
  masterLayout: true,
  icon: <HardDrive />,
  permissionCode: PermissionEnum.DeviceGroupsView,
};

export const routerOrganizationDeviceGroup: IRouter = {
  path: '/settings/organization/:organizationId/device-group',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.deviceGroup.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'settings.endUser.router.deviceGroup.nameBreadcrumb',
  masterLayout: true,
  icon: <HardDrive />,
  activePath: '/device-group',
  generatePath(organizationId) {
    return `/settings/organization/${organizationId}/device-group`;
  },
  permissionCode: PermissionEnum.DeviceGroupsView,
};
