import httpRepository from '@core/repository/http';
import NotificationsEntity from './notificationsEntity';
import { PaginationEntity } from '@core/pagination/entity';
import { OptionEntity, TableEntity } from '@core/table';
import React from 'react';

// API GET
export const getListNotifications = (pagination: PaginationEntity, options: OptionEntity) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: '/api/Notifications',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: NotificationsEntity.createListNotifications(res?.pagedData),
        info: res?.pageInfo,
        moreInfo: res?.moreInfo,
      };
    },
  });
};

//API notificationsMarkAllAsSeen
export const notificationsMarkAllAsSeen = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Notifications/MarkAllAsSeen',
    method: 'post',
    showSuccess: false,
    payload,
  });
};

//API notificationsMarkAsSeenMany
export const notificationsMarkAsSeenMany = (ids: React.Key[]) => {
  return httpRepository.execute({
    path: '/api/Notifications/MarkAsSeenMany',
    method: 'post',
    showSuccess: false,
    payload: {
      ids,
    },
  });
};
