export default {
  'packageManagement.router.name': 'Quản lý gói cước',
  'packageManagement.menu.name': 'Quản lý gói cước',
  'packageManagement.summary.current.title': 'Gói cước hiện tại',
  'packageManagement.summary.current.infinite': 'vô hạn',
  'packageManagement.summary.current.infinite.2': 'Vô hạn',
  'packageManagement.summary.current.paypal.text': 'PayPal',
  'packageManagement.summary.current.btn.cancel': 'Hủy gia hạn',
  'packageManagement.summary.current.btn.upgrade': 'Nâng cấp',
  'packageManagement.summary.current.btn.renew': 'Gia hạn lại',
  'packageManagement.summary.future.nextRenewal': 'Lần gia hạn tiếp theo',
  'packageManagement.summary.future.nextPackage': 'Gói cước tiếp theo',
  'packageManagement.summary.future.expiryTime': 'Thời gian hết hạn',
  'packageManagement.summary.future.label.startTime': 'Thời gian bắt đầu',
  'packageManagement.summary.current.cancel.title.1': 'Xác nhận hủy gia hạn tự động',
  'packageManagement.summary.current.cancel.title.2': 'Xác nhận hủy gói cước',
  'packageManagement.summary.current.cancel.content.title.1': 'Gói cước gia hạn',
  'packageManagement.summary.current.cancel.content.title.2': 'Gói cước tiếp theo',
  'packageManagement.summary.current.cancel.content.capacity': 'Dung lượng',
  'packageManagement.summary.current.cancel.content.duration': 'Thời hạn',
  'packageManagement.summary.current.cancel.content.label': 'Nội dung',
  'packageManagement.summary.current.cancel.content.text.1':
    'Khi bạn hủy gia hạn tự động, nếu bạn không tiến hành đăng ký gia hạn lại cho đến ngày {date}',
  'packageManagement.summary.current.cancel.content.text.2':
    'tổ chức của của bạn sẽ bị ngưng hoạt động và dữ liệu sẽ <strong>bị xóa vĩnh viễn sau 30 ngày</strong> kể từ ngày hết hạn.',
  'packageManagement.summary.current.cancel.content.text.3':
    'Nếu bạn hủy gói cước tiếp theo này chúng tôi sẽ tiếp tục gia hạn gói cước đang sử dụng hiện tại cho kỳ gia hạn tiếp theo của bạn.',
  'packageManagement.modal.extend.tariff.upgradePackage.warning.title':
    'Nâng cấp gói cước thất bại',
  'packageManagement.modal.extend.tariff.upgradePackage.warning.content':
    'Tổ chức của bạn đã có gói cước chờ tới ngày sử dụng. Để thay đổi gói cước khác vui lòng liên hệ Admin hệ thống.',
  'packageManagement.summary.upgradePackage.warning.title': 'Nâng cấp gói cước thất bại',
  'packageManagement.summary.upgradePackage.warning.content':
    'Tổ chức của bạn đã có gói cước chờ tới ngày sử dụng. Để thay đổi gói cước khác vui lòng liên hệ Admin hệ thống.',
  'packageManagement.modal.extend.tariff.status': 'Trạng thái',
  'packageManagement.modal.extend.tariff.content.title': 'Gói cước được gia hạn',
  'packageManagement.modal.extend.tariff.end': 'Ngưng kinh doanh',
  'packageManagement.modal.extend.tariff.error.1': 'Vui lòng lựa chọn gói cước khác để sử dụng',
  'packageManagement.modal.extend.tariff.capacity': 'Dung lượng',
  'packageManagement.modal.extend.tariff.time': 'Thời hạn',
  'packageManagement.modal.extend.tariff.btn.otherPackage': 'Gói khác',
  'packageManagement.renewalHistory.title.1': 'Thông tin gói cước',
  'packageManagement.renewalHistory.title.2': 'Lịch sử gia hạn gói cước',
  'packageManagement.renewalHistory.tariffPackagesMonth': 'Gói cước hiện tại',
  'packageManagement.renewalHistory.tariffPackagesTime': 'Thời gian hết hạn',
  'packageManagement.renewalHistory.tariffPackages.ghn': 'Gia hạn ngay',
  'packageManagement.renewalHistory.tariffPackages.btn.ghn': 'Gia hạn',
  'packageManagement.renewalHistory.tariffPackagesUpdate': 'Nâng cấp gói cước',
  'packageManagement.renewalHistory.tariffPackagesUpdate.btn': 'Nâng cấp',
  'packageManagement.renewalHistory.createdAt': 'Thời gian gia hạn',
  'packageManagement.renewalHistory.upgradePackage.warning.title': 'Nâng cấp gói cước thất bại',
  'packageManagement.renewalHistory.upgradePackage.warning.content':
    'Tổ chức của bạn đã có gói cước chờ tới ngày sử dụng. Để thay đổi gói cước khác vui lòng liên hệ Admin hệ thống.',
  'packageManagement.renewalHistory.table.payer.name': 'Người gia hạn',
  'packageManagement.renewalHistory.table.payer.phoneNumber': 'Số điện thoại',
  'packageManagement.renewalHistory.table.gracetime': 'Thời gian gia hạn',
  'packageManagement.renewalHistory.table.effectiveTime': 'Thời gian hiệu lực',
  'packageManagement.renewalHistory.table.capacity': 'Gói cước gia hạn',
  'packageManagement.renewalHistory.table.price': 'Giá gói cước',
  'packageManagement.renewalHistory.table.paymentStatus': 'Trạng thái',
  'packageManagement.renewalHistory.table.paymentStatus.params':
    '{status,select, 1 {Thành công} 2 {Đang xử lý} 3 {Thất bại} 4 {Đã hủy} 5 {Chưa thanh toán} other {Khác}}',
  'packageManagement.renewalHistory.table.paymentMethod': 'Phương thức thanh toán',
  'packageManagement.renewalHistory.table.paymentMethod-1': 'Trực tiếp',
  'packageManagement.renewalHistory.table.paymentMethod-2': 'Ví điện tứ',
  'packageManagement.renewalHistory.table.paymentMethod-3': 'VNPay',
  'packageManagement.renewalHistory.table.paymentMethod-5': 'PayPal',
  'packageManagement.renewalHistory.table.paymentMethod-6': 'OnePay',
  'packageManagement.renewalHistory.table.paymentMethod-4': '--',
  'packageManagement.renewalHistory.table.paymentMethod-other': 'Phương thức khác',
  //
  'packageManagement.summary.current.deviceQuantity': 'Tối đa {deviceQuantity} thiết bị',
  'packageManagement.summary.current.cancel.content.deviceLimit.unlimited': 'Không giới hạn',
  'packageManagement.summary.current.cancel.content.deviceLimit': 'Thiết bị',
  'packageManagement.modal.extend.tariff.deviceQuantity': 'Tối đa {deviceQuantity} thiết bị',
  'packageManagement.modal.extend.tariff.deviceLimit.unlimited': 'Không giới hạn',
  'packageManagement.modal.extend.tariff.deviceLimit': 'Thiết bị',
};
