import { Menu, MenuProps } from 'antd';
import lodash from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import * as Icon from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import { CheckPermissionFunc } from '@hoc/CheckPermission';
import { RootState } from '@modules';
import { OrganizationsSelector } from '@modules/organizations/organizationStore';
import { IRouter } from '@routers/interface';

interface IRenderMenuProps {
  listNav: Array<IRouter>;
  location: string;
  collapse?: boolean;
}

const renderMenuItem = (
  data: IRouter,
  facilityId?: string,
  listPermissionCode?: string[],
  children?: MenuProps['items'],
  type?: string,
  navigate?: NavigateFunction,
) => {
  let path = data.path;
  if (data.menu?.generatePath) {
    path = data.menu.generatePath(facilityId, listPermissionCode);
  } else if (data.generatePath) {
    path = data.generatePath(facilityId, listPermissionCode);
  }
  return {
    key: data.menu?.activePath || data.activePath || data.path,
    label: (
      <div className="class-border">
        <div className={'item-label'}>
          <FormattedMessage id={data.name} defaultMessage={data.name} />
        </div>
      </div>
    ),
    icon: data.menu?.icon || data.icon,
    children: children,
    onClick: () => {
      if (path != null) navigate?.(path);
    },
    type: type,
  };
};

const MenuCustom: React.FC<IRenderMenuProps> = (props: IRenderMenuProps) => {
  const listNav = props.listNav;
  const { listPermissionCode, user } = useSelector((state: RootState) => state.profile);
  const organization = useSelector(OrganizationsSelector);
  const [selectedKeys, setSelectedKeys] = useState<string[]>();
  const location = useLocation();
  const navigate = useNavigate();
  const [items, setItems] = useState<MenuProps['items']>([]);

  useEffect(() => {
    const newItem: MenuProps['items'] = [];
    listNav?.forEach((item: IRouter) => {
      if (
        [
          item.menu != null,
          !item.menu?.hideInNavbar === true,
          CheckPermissionFunc(item.permissionCode, listPermissionCode),
        ].every(it => it === true)
      ) {
        if ([item.routes != null, !lodash.isEmpty(item.routes)].every(it => it === true)) {
          const children: any = [];
          item?.routes?.forEach((route: IRouter) => {
            if (
              [
                !route.menu?.hideInNavbar === true,
                CheckPermissionFunc(route.permissionCode, listPermissionCode),
              ].every(it => it === true)
            ) {
              children.push(
                renderMenuItem(
                  route,
                  user?.facility?.id || organization?.id,
                  listPermissionCode,
                  undefined,
                  route?.type,
                  navigate,
                ),
              );
            }
          });
          newItem.push(
            renderMenuItem(
              item,
              user?.facility?.id || organization?.id,
              listPermissionCode,
              children,
              item?.type,
              navigate,
            ),
          );
        } else {
          newItem.push(
            renderMenuItem(
              item,
              user?.facility?.id || organization?.id,
              listPermissionCode,
              undefined,
              item?.type,
              navigate,
            ),
          );
        }
      }
    });
    setItems(newItem);
  }, [listPermissionCode]);

  useMemo(() => {
    if (items == null) return;
    items.forEach((item: any) => {
      if (item.children) {
        item.children.forEach((it: any) => {
          if (location.pathname.includes(it.key)) {
            setSelectedKeys([it.key]);
          }
        });
      } else {
        if (location.pathname.includes(item.key)) {
          setSelectedKeys([item.key]);
        }
      }
    });
  }, [location.pathname, items]);

  const handleChangeMenu = e => {
    setSelectedKeys(e.selectedKeys);
    sessionStorage.clear();
  };

  return (
    <Menu
      defaultSelectedKeys={selectedKeys}
      selectedKeys={selectedKeys}
      onSelect={handleChangeMenu}
      mode="vertical"
      // triggerSubMenuAction="click"
      items={items}
      expandIcon={<Icon.MoreVertical />}
    />
  );
};

export default MenuCustom;
