import httpRepository from '@core/repository/http';
import User from '@modules/user/entity';

export const login = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Users/Auth',
    method: 'post',
    payload,
  });
};

export const resetToken = (payload: { refreshToken: string }) => {
  return httpRepository.execute({
    path: '/api/Users/Refresh',
    method: 'post',
    payload,
    showSuccess: false,
  });
};
export const logout = () => {
  return httpRepository.execute({
    path: '/api/Users/logout',
    method: 'get',
    showSuccess: false,
  });
};
export const resetPassword = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Users/ResetPassword',
    method: 'post',
    payload,
  });
};
export const getProfile = (organizationId?: string) => {
  return httpRepository.execute({
    // path: '/api/Users/me',
    path: organizationId != null ? `/api/Users/${organizationId}/me` : '/api/Users/me',
    showSuccess: false,
    convert: res => {
      return new User(res);
    },
  });
};

export const getUnusedTariffPackage = () => {
  return httpRepository.execute({
    path: '/api/Organizations/me/UnusedTariffPackage',
    showSuccess: false,
    showError: false,
  });
};
export const updateProfile = (id: string, payload: any) => {
  return httpRepository.execute({
    path: '/api/Users/' + id,
    method: 'put',
    payload,
    config: {
      isFormData: true,
    },
    convert: res => {
      return new User(res);
    },
  });
};
export const updateProfileChangePassword = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Users/me/ChangePassword',
    method: 'put',
    payload,
  });
};
export const uploadAvatar = (payload: any) => {
  return httpRepository.execute({
    path: 'api/Users',
    method: 'put',
    payload,
  });
};
export const updateProfileUser = (id: any, payload: any) => {
  return httpRepository.execute({
    path: `api/Users/${id}`,
    method: 'put',
    payload,
    config: { isFormData: true },
  });
};

export const createPlans = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Plans/Organizations',
    method: 'post',
    payload,
    config: {
      isFormData: true,
    },
  });
};

export const checkOrganization = (payload: any, sessionId: string) => {
  return httpRepository.execute({
    path: '/api/Plans/Organizations/PreValidation' + `/${sessionId}`,
    method: 'post',
    payload,
    showSuccess: false,
    showError: false,
  });
};
