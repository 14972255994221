import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerMediaShares: IRouter = {
  path: `/source-information?tab=${PermissionEnum.MediaSharesView}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.mediaShares.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(organizationId) {
    if (organizationId == null) {
      return '/source-information?tab=4';
    }
    return `/organization/${organizationId}/source-information?tab=${PermissionEnum.MediaSharesView}`;
  },
};
