import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerFacilitiesDevices: IRouter = {
  path: `/facility/:facilityId?tab=${PermissionEnum.FacilitiesDevicesView}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.router.device.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.FacilitiesDevicesView,
};
