import React from 'react';

import { IRouter } from '@routers/interface';

export const routerTemplateFacilityAdd: IRouter = {
  path: '/facility/:facilityId/template/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.template.router.create.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerTemplateFacilityEditInfo: IRouter = {
  path: '/facility/:facilityId/template/:type/:id',
  loader: React.lazy(() => import('../PagesInfo/index')),
  exact: true,
  name: 'facility.template.router.create.edit', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerOrganizationFacilityEditInfo: IRouter = {
  path: '/organizations/:organizationId/facility/:facilityId/template/:type/:id',
  loader: React.lazy(() => import('../PagesInfo/index')),
  exact: true,
  name: 'facility.template.router.create.edit', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(organizationId, facilityId) {
    return `/organizations/${organizationId}/facility/${facilityId}/template/:type/:id`;
  },
};

export const routerOrganizationTemplateFacilityAdd: IRouter = {
  path: '/organizations/:organizationId/facility/:facilityId/template/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.template.router.create.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
