import React from 'react';

import { IRouter } from '@routers/interface';

export const routerViewProfile: IRouter = {
  path: '/profile',
  name: 'profile.router.name',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: false,
};

export const routerRenewalHistory: IRouter = {
  path: '/renewal-history',
  name: 'profile.router.renewalHistory.name',
  loader: React.lazy(() => import('./components/RenewalHistory')),
  exact: true,
  masterLayout: false,
};
