export default {
  'common.page.notfound': 'We couldn’t find this page',
  'common.404error': '404 Error',
  'common.login': 'Log in again',
  'common.404.welcome':
    'Hello {name}! The page you requested is currently not accessible. Please try again after the admin has granted access.',
  'common.404note':
    'It seems something is disconnected. The page you requested cannot be found, but there are some ways to get you back on track. You can go back to the previous page or visit our homepage.',
  'common.error.authorization':
    'Your account currently does not have access rights. Please contact {email} for support.',
  'common.error.title.authorization': 'Account Authorization Confirmation!',
};
