import React from 'react';
import { Bell } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerAnnouncements: IRouter = {
  path: '/announcements',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'announcements.router.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'announcements.router.nameBreadcrumb',
  masterLayout: true,
  menu: {
    icon: <Bell />,
  },
  permissionCode: PermissionEnum.AnnouncementsView,
};
