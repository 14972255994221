export default {
  'notifications.title': 'Notifications',
  'notifications.more': 'See more',
  'notifications.readAll': 'Mark all as read',
  'notifications.tabs.organizations': 'Account Notifications',
  'notifications.tabs.internal': 'Internal Notifications',
  'notifications.today': 'Today',
  'notifications.time': '{value} minutes ago',
  'notifications.hour': '{value} hours ago',
  APPROVE_MEDIA: 'Source approved',
  REFUSE_MEDIA: 'Media refused',
  SHARE_MEDIA: 'Sharing Media',
  RECEPT_MEDIA: 'Media received',
  APPROVE_NEWSLETTER: 'Broadcast Schedule approved',
  REFUSE_NEWSLETTER: 'Broadcast Schedule refused',
  OVERLAP_NEWSLETTER: 'Broadcast Schedule conflict',
  EXPIRING_TARIFF_PACKAGE: 'Storage subscription expiring soon',
  LOWSTORAGE_TARIFF_PACKAGE: 'Storage running low',
  MEDIA_FILE: 'Media file',
  APPROVED_BY: 'approved by',
  REFUSED_BY: 'refused by',
  YOU_HAVE: 'You have',
  SHARED_BY: 'shared from location',
  YOUR_FACILITY_HAVE: 'Your location has',
  RECEPTED_BY: 'received by',
  NEWSLETTER: 'Broadcast',
  OVERLAP_WITH: 'has a scheduling conflict with broadcast',
  CREATED_BY_FACILITY: 'created by location',
  TARIFF_PACKAGE: 'Storage Subscription',
  LEFT: 'left',
  WILL_EXPIRE: 'days left until expiration.',
  WILL_LOW_ON_STORAGE: 'days left until storage is full.',
  MEDIA_NEED_APPROVE: 'Source needs approval',
  CREATED_BY: 'created by',
  NEEDS_TO_BE_APPROVED: 'needs to be approved',
  NEWSLETTER_NEED_APPROVE: 'Broadcast needs approval',
};
