export default {
  'dashboard.router.manager': 'Dashboard',
  'dashboard.summary.organizations': 'Accounts',
  'dashboard.summary.organizations.expired': 'Expired Accounts',
  'dashboard.summary.organizations.due': 'Active Accounts',
  'dashboard.summary.capacityUsed.text': 'In Used Storage',
  'dashboard.summary.capacity.text': 'Total Registered',
  'dashboard.filter.organizations': 'Accounts',
  'dashboard.filter.status': 'Status',
  'dashboard.filter.status.active': 'Active',
  'dashboard.filter.status.notActive': 'Inactive',
  'dashboard.marker.device.name': 'Device Name',
  'dashboard.marker.organizations': 'Account',
  'dashboard.marker.specificAddress': 'Installation Address',
  'dashboard.marker.latitude': 'Latitude',
  'dashboard.marker.longitude': 'Longitude',
  'dashboard.marker.status': 'Status',
  'dashboard.marker.device.info': 'Device Information',
  'dashboard.marker.devices.operationStatus.id':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'dashboard.summary.facilities': 'Total Locations',
  'dashboard.summary.totalDevice': 'Devices',
  'dashboard.summary.totalNewsletter': 'Total Broadcast',
  'dashboard.summary.capacity': 'Total Capacity',
  'dashboard.summary.totalDevice.label': 'Maximum',
  'dashboard.summary.totalDevice.valueDesc': 'device(s)',

  // dashboard device
  'dashboard.device.title': 'Device Dashboard',
  'dashboard.device.name': 'Device',
  'dashboard.device.facility.name': 'Facility',
  'dashboard.device.deviceGroups': 'Device Groups',

  'dashboard.device.connectionStatus': 'Connection Status',
  'dashboard.device.connectionStatus.connecting': 'Connecting',
  'dashboard.device.connectionStatus.notConnectedYet': 'Disconnected',

  'dashboard.device.playingStatus': 'Broadcast Status',
  'dashboard.device.playingStatus.playing': 'Playing',
  'dashboard.device.playingStatus.notScheduledYet': 'Stopped',
  'dashboard.device.playingStatus.undefined': 'Undefined',

  'dashboard.device.operationStatus': 'Operational Status',
  'dashboard.device.operationStatus.active': 'Active',
  'dashboard.device.operationStatus.deactive': 'Inactive',

  'dashboard.device.information': 'Information',

  'dashboard.device.filter.connectionStatus': 'Connection Status',
  'dashboard.device.filter.connectionStatus.connecting': 'Connecting',
  'dashboard.device.filter.connectionStatus.notConnectedYet': 'Disconnected',

  'dashboard.device.filter.playingStatus': 'Broadcast Status',
  'dashboard.device.filter.playingStatus.playing': 'Playing',
  'dashboard.device.filter.playingStatus.notScheduledYet': 'Stopped',
  'dashboard.device.filter.playingStatus.undefined': 'Undefined',

  'dashboard.device.filter.operationStatus': 'Operational Status',
  'dashboard.device.filter.operationStatus.active': 'Active',
  'dashboard.device.filter.operationStatus.deactive': 'Inactive',

  'dashboard.device.chart.title.connectionStatus': 'Device Connection Rate',
  'dashboard.device.chart.title.playingStatus': 'Device Broadcast Rate',
  'dashboard.device.chart.title.operationStatus': 'Device Operational Rate',

  'dashboard.device.chart.title': 'List of device group',
  'dashboard.device.export': 'Export excel file',

  'dashboard.marker.device.deviceGroup': 'Device group',
};
