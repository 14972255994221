import OrganizationsEntity from '@modules/organizations/organizationsEntity';
import UserEntity from '@modules/user/entity';
import dayjs from 'dayjs';
import { PaymentMethodEnum, PaymentStatus } from './interface';

class PaymentHistoriesEntity {
  //copy props from backend:
  createdAt = '';

  organization?: OrganizationsEntity;

  gracetime: string = '';

  capacity?: number;

  periodByMonth?: number;

  price?: number;

  payer?: UserEntity;

  paymentMethod?: PaymentMethodEnum;

  paymentStatus?: PaymentStatus;

  id: string = '';

  constructor(paymentHistories: Partial<PaymentHistoriesEntity>) {
    if (!paymentHistories) {
      return;
    }
    Object.assign(this, paymentHistories);
    // convert entity type here
    this.createdAt = paymentHistories.createdAt
      ? dayjs(paymentHistories.createdAt).format('DD/MM/YYYY')
      : '';
  }

  static createListPaymentHistories(listPaymentHistories: Array<Partial<PaymentHistoriesEntity>>) {
    if (!Array.isArray(listPaymentHistories)) {
      return [];
    }
    return listPaymentHistories.map((paymentHistories: Partial<PaymentHistoriesEntity>) => {
      return new PaymentHistoriesEntity(paymentHistories);
    });
  }
}
export default PaymentHistoriesEntity;
