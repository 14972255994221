import React from 'react';

import { SourceIcon } from '@assets/icon/sourceIcon';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerSourceInformation: IRouter = {
  path: '/source-information',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'sourceInformation.router.nameBreadcrumb',
  menu: {
    icon: <SourceIcon />,
  },
  permissionCode: [
    PermissionEnum.MediasView,
    PermissionEnum.TemplatesView,
    PermissionEnum.PlaylistsView,
    PermissionEnum.MediaSharesView,
  ],
  generatePath(_, listPermission) {
    const permissionTabs: any = [
      PermissionEnum.MediasView,
      PermissionEnum.TemplatesView,
      PermissionEnum.PlaylistsView,
      PermissionEnum.MediaSharesView,
    ];
    if (permissionTabs.find(item => listPermission?.includes(item))) {
      return `/source-information?tab=${permissionTabs.find(item =>
        listPermission.includes(item),
      )}`;
    }
    return `/source-information?tab=${PermissionEnum.MediasView}`;
  },
};

export const routerOrganizationSourceInformation: IRouter = {
  path: '/organization/:organizationId/source-information',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'sourceInformation.router.nameBreadcrumb',
  activePath: '/source-information',
  menu: {
    icon: <SourceIcon />,
  },
  permissionCode: [
    PermissionEnum.MediasView,
    PermissionEnum.TemplatesView,
    PermissionEnum.PlaylistsView,
    PermissionEnum.MediaSharesView,
  ],
  generatePath: organizationId =>
    `/organization/${organizationId}/source-information?tab=${PermissionEnum.MediasView}`,
};
