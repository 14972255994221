import dayjs from 'dayjs';

import FacilitiesEntity from '@modules/facilities/facilitiesEntity';
import MediasEntity from '@modules/medias/mediasEntity';
import UserEntity from '@modules/user/entity';

export enum TemplateUsageStatus {
  NotUsedYet = 1,
  Used = 2,
}

export enum NewsLettersType {
  NORMAL = 1,
  PRIORITIZE = 2,
}

export enum NewsLettersRepeatTypeStatus {
  NONE = 1,
  DAILY = 2,
  WEEKLY = 3,
  MONTHLY = 4,
}

export enum NewsLettersApprovalStatus {
  PENDING = 1,
  APPROVED = 2,
  REFUSE = 3,
}

export enum NewsLettersPlayingStatus {
  NOT_BROADCASTED = 1,
  BROADCASTING = 2,
  PAUSE = 3,
  CANCELLED = 4,
  ENDED = 5,
  WAITING = 6,
  COMING = 7,
}

class TemplateEntity {
  //copy props from backend:
  createdAt = '';

  id = '';

  name = '';

  code = '';

  title = '';

  resolutionWidth = '';

  resolutionHeight = '';

  duration = 0;

  medias: MediasEntity[] = [];

  usageStatus: TemplateUsageStatus = TemplateUsageStatus.NotUsedYet;

  creator: UserEntity = new UserEntity();

  value: string = '';

  label: string = '';

  format: any;

  type?: NewsLettersType;

  repeatType?: NewsLettersRepeatTypeStatus;

  approvalStatus?: NewsLettersApprovalStatus;

  playingStatus?: NewsLettersPlayingStatus;

  facilities?: Array<{
    code: string;
    name: string;
    operationStatus: number;
    hasChild: boolean;
    id: string;
    createdAt: string;
  }>;

  timeSlots?: Array<{
    startTime: string;
    endTime: string;
    loopCount: number;
    id: string;
    createdAt: string;
  }>;

  facility?: FacilitiesEntity;

  finalDate: any;

  releaseDate: any;

  constructor(template: Partial<TemplateEntity>) {
    if (!template) {
      return;
    }
    Object.assign(this, template);
    // convert entity type here
    this.createdAt = template.createdAt
      ? dayjs(template.createdAt).format('DD/MM/YYYY HH:mm:ss')
      : '';
    this.medias = template.medias
      ? template.medias.map((media: Partial<MediasEntity>) => {
          return new MediasEntity(media);
        })
      : [];
    this.finalDate = template.finalDate ? dayjs(template.finalDate).format('DD/MM/YYYY') : '';
    this.releaseDate = template.releaseDate ? dayjs(template.releaseDate).format('DD/MM/YYYY') : '';

    this.value = template.id ? template.id : '';
    this.label = template.name ? template.name : '';
  }

  static createListTemplate(listTemplate: Array<Partial<TemplateEntity>>) {
    if (!Array.isArray(listTemplate)) {
      return [];
    }
    return listTemplate.map((template: Partial<TemplateEntity>) => {
      return new TemplateEntity(template);
    });
  }
}
export default TemplateEntity;
