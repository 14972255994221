import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerFormRolesAdd: IRouter = {
  path: '/settings/roles/add',
  loader: React.lazy(() => import('./FormRoles')),
  exact: true,
  name: 'settings.systemUser.router.role.add', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.RolesCreate,
};

export const routerFormRolesEdit: IRouter = {
  path: '/settings/roles/:type/:id',
  loader: React.lazy(() => import('./FormRoles')),
  exact: true,
  name: 'settings.systemUser.router.role.update', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.RolesUpdate, PermissionEnum.RolesViewDetail],
};

export const routerOrganizationFormRolesAdd: IRouter = {
  path: '/organization/:organizationId/settings/roles/add',
  loader: React.lazy(() => import('./FormRoles')),
  exact: true,
  name: 'settings.systemUser.router.role.add', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerOrganizationFormRolesEdit: IRouter = {
  path: '/organization/:organizationId/settings/roles/:type/:id',
  loader: React.lazy(() => import('./FormRoles')),
  exact: true,
  name: 'settings.systemUser.router.role.update', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
