import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerTemplateAdd: IRouter = {
  path: '/source-information/template/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.template.create', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.TemplatesCreate,
};

export const routerTemplateEditInfo: IRouter = {
  path: '/source-information/template/:type/:id',
  loader: React.lazy(() => import('../PagesInfo/index')),
  exact: true,
  name: 'sourceInformation.router.template.edit', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.TemplatesUpdate, PermissionEnum.TemplatesViewDetail],
};

export const routerOrganizationTemplateAdd: IRouter = {
  path: '/organization/:organizationId/source-information/template/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.template.create', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerOrganizationTemplateEditInfo: IRouter = {
  path: '/organization/:organizationId/source-information/template/:type/:id',
  loader: React.lazy(() => import('../PagesInfo/index')),
  exact: true,
  name: 'sourceInformation.router.template.edit', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerTemplateEditInfoFacility: IRouter = {
  path: '/source-information/template/:type/:id/:facilityId',
  loader: React.lazy(() => import('../PagesInfo/index')),
  exact: true,
  name: 'sourceInformation.router.template.edit', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerOrganizationTemplateEditInfoFacility: IRouter = {
  path: '/organization/:organizationId/source-information/template/:type/:id/:facilityId',
  loader: React.lazy(() => import('../PagesInfo/index')),
  exact: true,
  name: 'sourceInformation.router.template.edit', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
