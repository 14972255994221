export default {
  'settings.endUser.router.deviceGroup.name': 'Device group',
  'settings.endUser.router.deviceGroup.nameBreadcrumb': 'Device distributor category',
  'settings.endUser.deviceGroup.title': 'Device group list',
  'settings.endUser.deviceGroup.table.name': 'Device group name',
  'settings.endUser.deviceGroup.table.description': 'Description',
  'settings.endUser.deviceGroup.table.device': 'Number of devices in group',
  'settings.endUser.deviceGroup.table.update': 'Update',
  'settings.endUser.deviceGroup.modal.form.name.validator':
    'Device group name is in an invalid format',
  'settings.endUser.deviceGroup.modal.form.name': 'Device group name',
  'settings.endUser.deviceGroup.modal.form.description': 'Description',
  'settings.endUser.deviceGroup.modal.title.update': 'Update device group',
  'settings.endUser.deviceGroup.modal.title.create': 'Add device group',
  'settings.endUser.deviceGroup.delete.title': 'Confirm device group deletion',
  'settings.endUser.deviceGroup.delete.content':
    'This device group will be removed from the system',
};
