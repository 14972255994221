import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerFormTariffAdd: IRouter = {
  path: '/tariff/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'tariff.TariffManagement.router.add.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.PackagesCreate],
};
export const routerFormTariffUpdate: IRouter = {
  path: '/tariff/update/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'tariff.TariffManagement.router.update.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.PackagesUpdate],
};
