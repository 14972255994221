import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { logo } from '@assets/images';
import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';
import { RootState } from '@modules';
import { OrganizationsSelector } from '@modules/organizations/organizationStore';
import UserEntity, { typeUser } from '@modules/user/entity';
import { Selector } from '@reduxjs/toolkit';
import { endUserPages, organizationsPage, systemUserPages } from '@routers/mainRouter';

import MenuCustom from './MenuCustom';

interface IHeaderComponent {
  profile?: UserEntity;
}
const HeaderComponentSelector: Selector<RootState, IHeaderComponent> = (state: RootState) => {
  return {
    profile: state.profile.user,
  };
};

const SiderComponent: React.FC<{
  className: string;
  setClassName: (className: string) => void;
}> = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const refMouseEnter = useRef<any>();
  const { className, setClassName } = props;
  const [width, setWidth] = useState<string | number>();
  const [collapse, setCollapse] = useState<boolean>(false);
  const organization = useSelector(OrganizationsSelector);
  const onClick = (e: any) => {
    setClassName('sider-component big');
    e.preventDefault();
    e.stopPropagation();
    setCollapse(false);
  };
  const { profile } = useSelector(HeaderComponentSelector);

  const menuProps = React.useMemo(() => {
    if (organization == null) {
      return { listNav: systemUserPages };
    } else {
      return { listNav: organizationsPage, key: organization.id };
    }
  }, [organization, systemUserPages, organizationsPage]);

  const handleOnClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
    if (collapse === false) {
      setClassName('sider-component');
    } else {
      setClassName('sider-component big');
      setCollapse(false);
    }
    e.stopPropagation();
  };

  const onMouseEnter = e => {
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
    refMouseEnter.current = setTimeout(() => {
      onClick(e);
    }, 800);
  };

  const onMouseLeave = () => {
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
  };

  useEffect(() => {
    if (className === 'sider-component') {
      setWidth('30rem');
      setCollapse(true);
    } else {
      setWidth('100%');
    }
  }, [className]);

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className="icon"
        onClick={handleOnClick}
        onMouseEnter={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {collapse ? <UilAngleRight /> : <UilAngleLeft />}
      </div>
      <div className="mask" style={{ width }}>
        <div className="wrapper-top">
          <div className="wrapper-logo">
            <div className={`logo ${collapse ? 'logo-small' : ''}`}>
              <img src={logo} alt="logo" onClick={() => navigate('/')} />
            </div>
          </div>
          <div className="wrapper-sidebar">
            {profile?.type === typeUser.System && (
              <MenuCustom {...menuProps} location={location.pathname} collapse={collapse} />
            )}
            {profile?.type === typeUser.Organization && (
              <MenuCustom listNav={endUserPages} location={location.pathname} collapse={collapse} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiderComponent;
