import { Modal } from 'antd';
import React, { ReactNode } from 'react';

import { SyncOutlined } from '@ant-design/icons';

const { confirm } = Modal;
interface IFaceConfirm {
  handleOk?: (...args: any[]) => any;
  handleCancel?: (...args: any[]) => any;
  content?: any;
  title?: any;
  okText?: string;
  width?: any;
  cancelText?: string;
  type?: 'info' | 'success' | 'error' | 'warn' | 'warning' | 'confirm';
  icon?: ReactNode;
  modifiers?: 'red' | 'green' | 'orange';
  idButtonOK?: string;
  idButtonCancel?: string;
  hiddenOk?: boolean;
  hiddenCancel?: boolean;
  className?: string;
}

export const ConfirmReStart = (props: IFaceConfirm) => {
  const {
    handleOk,
    handleCancel,
    modifiers,
    content,
    title,
    okText,
    width,
    cancelText,
    type,
    icon,
    idButtonOK,
    idButtonCancel,
    hiddenOk,
    hiddenCancel,
    className,
  } = props;
  return confirm({
    style: { marginTop: '5%' },
    width: width || 500,
    title,
    content,
    type: type || 'confirm',
    cancelText,
    okText,
    className: `modal-confirm-reStart ${modifiers} ${className || ''}`,
    onCancel: handleCancel,
    onOk: handleOk,
    okButtonProps: { id: idButtonOK, style: { display: hiddenOk === true ? 'none' : 'block' } },
    cancelButtonProps: {
      id: idButtonCancel,
      style: { display: hiddenCancel === true ? 'none' : 'block' },
    },
    icon:
      icon != null ? (
        <span className="container-icon">{icon}</span>
      ) : (
        <span className="container-icon">
          <SyncOutlined />
        </span>
      ),
  });
};
