export default {
  'profile.code.title': 'Tài khoản bị ngưng hoạt động',
  'profile.code.content':
    'Tài khoản của bạn hiện đang ngưng hoạt động nên không thể truy cập vào hệ thống. Vui lòng liên hệ admin hệ thống để giải quyết.',
  'profile.router.name': 'Hồ sơ',
  'profile.title': 'Thông tin cơ bản',
  'profile.fullName': 'Họ và tên',
  'profile.phone': 'Số điện thoại',
  'profile.role': 'Vai trò',
  'profile.timezone': 'Múi giờ',
  'profile.email': 'Email',
  'profile.tariffPackagesMonth': 'Gói cước hiện tại',
  'profile.tariffPackagesTime': 'Thời gian hết hạn',
  'profile.tariffPackages.ghn': 'Gia hạn ngay',
  'profile.tariffPackages.btn.ghn': 'Gia hạn',
  'profile.tariffPackagesUpdate': 'Nâng cấp gói cước',
  'profile.tariffPackagesUpdate.btn': 'Nâng cấp',
  'profile.tariffPackages.history': 'Lịch sử gia hạn',
  'profile.upgradePackage.warning.title': 'Nâng cấp gói cước thất bại',
  'profile.upgradePackage.warning.content':
    'Tổ chức của bạn đã có gói cước chờ tới ngày sử dụng. Để thay đổi gói cước khác vui lòng liên hệ Admin hệ thống.',
  'profile.modal.extend.tariff.upgradePackage.warning.title': 'Nâng cấp gói cước thất bại',
  'profile.modal.extend.tariff.upgradePackage.warning.content':
    'Tổ chức của bạn đã có gói cước chờ tới ngày sử dụng. Để thay đổi gói cước khác vui lòng liên hệ Admin hệ thống.',
  'profile.modal.extend.tariff.status': 'Trạng thái',
  'profile.modal.extend.tariff.end': 'Ngưng kinh doanh',
  'profile.modal.extend.tariff.error.1': 'Vui lòng lựa chọn gói cước khác để sử dụng',
  'profile.modal.extend.tariff.capacity': 'Dung lượng',
  'profile.modal.extend.tariff.time': 'Ngày hiệu lực',
  'profile.router.renewalHistory.name': 'Hồ sơ',
  'profile.renewalHistory.title.1': 'Thông tin gói cước',
  'profile.renewalHistory.title.2': 'Lịch sử gia hạn gói cước',
  'profile.renewalHistory.tariffPackagesMonth': 'Gói cước hiện tại',
  'profile.renewalHistory.tariffPackagesTime': 'Thời gian hết hạn',
  'profile.renewalHistory.tariffPackages.ghn': 'Gia hạn ngay',
  'profile.renewalHistory.tariffPackages.btn.ghn': 'Gia hạn',
  'profile.renewalHistory.tariffPackagesUpdate': 'Nâng cấp gói cước',
  'profile.renewalHistory.tariffPackagesUpdate.btn': 'Nâng cấp',
  'profile.renewalHistory.createdAt': 'Thời gian gia hạn',
  'profile.renewalHistory.upgradePackage.warning.title': 'Nâng cấp gói cước thất bại',
  'profile.renewalHistory.upgradePackage.warning.content':
    'Tổ chức của bạn đã có gói cước chờ tới ngày sử dụng. Để thay đổi gói cước khác vui lòng liên hệ Admin hệ thống.',
  'profile.renewalHistory.table.payer.name': 'Người gia hạn',
  'profile.renewalHistory.table.payer.phoneNumber': 'Số điện thoại',
  'profile.renewalHistory.table.gracetime': 'Thời gian gia hạn',
  'profile.renewalHistory.table.effectiveTime': 'Thời gian hiệu lực',
  'profile.renewalHistory.table.capacity': 'Gói cước gia hạn',
  'profile.renewalHistory.table.price': 'Giá gói cước',
  'profile.renewalHistory.table.paymentStatus': 'Trạng thái',
  'profile.renewalHistory.table.paymentStatus.params':
    '{status,select, 1 {Thành công} 2 {Đang xử lý} 3 {Thất bại} 4 {Đã hủy} other {Khác}}',
  'profile.renewalHistory.table.paymentMethod': 'Phương thức thanh toán',
  'profile.renewalHistory.table.paymentMethod-1': 'Trực tiếp',
  'profile.renewalHistory.table.paymentMethod-2': 'Ví điện tứ',
  'profile.renewalHistory.table.paymentMethod-3': 'VNPay',
  'profile.renewalHistory.table.paymentMethod-5': 'PayPal',
  'profile.renewalHistory.table.paymentMethod-6': 'OnePay',
  'profile.renewalHistory.table.paymentMethod-4': '--',
  'profile.renewalHistory.table.paymentMethod-other': 'Phương thức khác',
};
