import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Routes, useLocation } from 'react-router-dom';

import DefaultLayout from '@layout/index';
import authenticationPresenter from '@modules/authentication/authenticationPresenter';
import { TokenSelector, UserSelector } from '@modules/authentication/profileStore';
import { typeUser } from '@modules/user/entity';
import { endUserPages, organizationsPage, systemUserPages } from '@routers/mainRouter';
import Loading from '@shared/components/Loading';
import { useSingleAsync } from '@shared/hook/useAsync';

import useRouter from './useRouter';

const PrivatePage: React.FC = () => {
  const token = useSelector(TokenSelector);
  const location = useLocation();
  const getProfile = useSingleAsync(authenticationPresenter.getProfile);
  const { user, status } = useSelector(UserSelector);

  useEffect(() => {
    if (token) {
      getProfile.execute();
    }
  }, [token]);

  const { views, routes } = useRouter(
    {
      routers:
        user?.type === typeUser.System ? [...organizationsPage, ...systemUserPages] : endUserPages,
      privateRoute: true,
    },
    [user],
  );

  const showMasterLayout = React.useMemo(() => {
    const r = routes.find(it => {
      if (it.activePath != null) {
        return location.pathname.includes(it.activePath);
      }
      return it.path && matchPath(it.path, location.pathname);
    });
    return r?.masterLayout !== false;
  }, [location.pathname, routes]);

  return (
    <>
      {status !== true ? (
        <Loading />
      ) : (
        <DefaultLayout
          hideSideBar={!showMasterLayout}
          hideHeader={!showMasterLayout}
          loading={getProfile.status !== 'ready'}
        >
          <Routes>{views}</Routes>
        </DefaultLayout>
      )}
    </>
  );
};
export default React.memo(PrivatePage);
