import React from 'react';
import { Command } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerDashboard: IRouter = {
  path: '/dashboard',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'Dashboard', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'dashboard.router.manager',
  menu: {
    icon: <Command />,
  },
  permissionCode: [PermissionEnum.OverviewsSystemUserView, PermissionEnum.OverviewsEndUserView],
};

export const routerDashboardOrganizations: IRouter = {
  path: '/organizations/:organizationId/dashboard',
  loader: React.lazy(() => import('./index')),
  activePath: '/dashboard',
  exact: true,
  name: 'Dashboard', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'dashboard.router.manager',
  generatePath(organizationId) {
    return `/organizations/${organizationId}/dashboard`;
  },
  menu: {
    icon: <Command />,
  },
};
