import dayjs from 'dayjs';

import FacilitiesEntity from '@modules/facilities/facilitiesEntity';
import MediasEntity from '@modules/medias/mediasEntity';
import PlaylistsEntity from '@modules/playlists/playlistsEntity';
import TemplateEntity from '@modules/template/templateEntity';
import UserEntity from '@modules/user/entity';

export enum NewsTableType {
  NORMAL = 1,
  PRIORITIZE = 2,
}

export enum NewsletterAprroveStatus {
  PENDING = 1,
  APPROVED = 2,
  REFUSE = 3,
}

export enum NewsTableBulletinStatus {
  PENDING = 1, // Chờ duyệt
  REFUSE = 2, // Từ chối
  CANCELLED = 3, // Đã hủy
  NOT_BROADCASTED = 4, // Chưa phát lần nào
  BROADCASTING = 5, // Đang phát
  PAUSE = 6, // Tạm ngưng
  ENDED = 7, // Đã kết thúc
  WAITING = 8, // Chờ phát
  COMING = 9, // Sắp đến giờ phát
}

export enum NewsTableApprovalStatus {
  PENDING = 1,
  APPROVED = 2,
  REFUSE = 3,
}

export enum NewsTablePlayingStatus {
  NOT_BROADCASTED = 1,
  BROADCASTING = 2,
  PAUSE = 3,
  CANCELLED = 4,
  ENDED = 5,
  WAITING = 6,
  COMING = 7,
}
export enum NewsTableRepeatTypeStatus {
  NONE = 1,
  DAILY = 2,
  WEEKLY = 3,
  MONTHLY = 4,
}

export enum NewAddSourceType {
  TEMPLATE = 1,
  MEDIA = 2,
  PLAYLIST = 3,
}

class NewsEntity {
  code: string = '';

  title: string = '';

  duration: number = 0;

  field?: {
    name: string;
    code: string;
    id: string;
    createdAt: string;
  };

  sourceType?: NewAddSourceType;

  type?: NewsTableType;

  bulletinStatus?: NewsTableBulletinStatus;

  approvalStatus?: NewsTableApprovalStatus;

  playingStatus?: NewsTablePlayingStatus;

  releaseDate: any;

  finalDate: any;

  timeSlots?: Array<{
    startTime: string;
    endTime: string;
    loopCount: number;
    id: string;
    createdAt: string;
  }>;

  repeatType?: NewsTableRepeatTypeStatus;

  repeatValue?: any;

  reasonRefusal: string = '';

  facilities?: Array<{
    code: string;
    name: string;
    operationStatus: number;
    hasChild: boolean;
    id: string;
    createdAt: string;
  }>;

  media?: MediasEntity;

  template?: TemplateEntity;

  playlist?: PlaylistsEntity;

  creator?: UserEntity;

  id: string = '';

  sourceId: string = '';

  createdAt: string = '';

  isLoop?: boolean;

  facilityIds?: any;

  facility?: FacilitiesEntity;

  devices?: any;

  constructor(news: Partial<NewsEntity>) {
    if (!news) {
      return;
    }
    Object.assign(this, news);
    // convert entity type here
    this.createdAt = news.createdAt ? dayjs(news.createdAt).format('DD/MM/YYYY') : '';
    this.finalDate = news.finalDate ? dayjs(news.finalDate).format('DD/MM/YYYY') : '';
    this.releaseDate = news.releaseDate ? dayjs(news.releaseDate).format('DD/MM/YYYY') : '';
  }

  static createListNews(listNews: Array<Partial<NewsEntity>>) {
    if (!Array.isArray(listNews)) {
      return [];
    }
    return listNews.map((news: Partial<NewsEntity>) => {
      return new NewsEntity(news);
    });
  }
}
export default NewsEntity;
