export default {
  'sourceInformation.router.name': 'Nguồn thông tin',
  'sourceInformation.router.nameBreadcrumb': 'Quản lý nguồn thông tin',
  'sourceInformation.tabs.media': 'Media',
  'sourceInformation.tabs.template': 'Bản mẫu',
  'sourceInformation.tabs.playlist': 'Danh sách phát',
  'sourceInformation.tabs.mediaShare': 'Media được chia sẻ',
  'sourceInformation.breadcrumbs.media.name': 'Media',
  'sourceInformation.breadcrumbs.template.name': 'Bản Mẫu',
  'sourceInformation.breadcrumbs.playlist.name': 'Danh sách phát',
  'sourceInformation.breadcrumbs.mediaShares.name': 'Media được chia sẻ',
  'sourceInformation.router.media.name': 'Media',
  'sourceInformation.media.title': 'Danh sách Media cơ sở đang quản lý',
  'sourceInformation.media.paginationKey': 'phần tử trong mỗi trang',
  'sourceInformation.media.upload.choose': 'Chọn',
  'sourceInformation.media.upload.title': 'hoặc kéo tệp vào đây',
  'sourceInformation.media.upload.note':
    'Hỗ trợ cho tệp lên tới 1GB và đạt hiệu quả cao với tệp dưới 300MB.',
  'sourceInformation.media.code.format.video': 'Video',
  'sourceInformation.media.code.format.image': 'Hình ảnh',
  'sourceInformation.media.code.format.stream': 'Phát trực tiếp',
  'sourceInformation.media.code.format.fmhz': 'Kênh FM',
  'sourceInformation.media.code.format.audio': 'Âm thanh',
  'sourceInformation.media.delete.title': 'Xác nhận xóa file media',
  'sourceInformation.media.delete.content':
    'Các media file được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'sourceInformation.media.approve.title': 'Xác nhận duyệt media file',
  'sourceInformation.media.approve.content': 'Media file bạn chọn sẽ được phê duyệt',
  'sourceInformation.media.filter.approvalStatus': 'Trạng thái',
  'sourceInformation.media.filter.approvalStatus.approve': 'Đã duyệt',
  'sourceInformation.media.filter.approvalStatus.notApprovedYet': 'Chờ duyệt',
  'sourceInformation.media.filter.approvalStatus.refuse': 'Từ chối duyệt',
  'sourceInformation.media.filter.format.code': 'Loại media',
  'sourceInformation.media.filter.field.id': 'Lĩnh vực',
  'sourceInformation.media.filter.createdAt': 'Ngày tạo',
  'sourceInformation.media.modal.create': 'Thêm file media',
  'sourceInformation.media.modal.update': 'Cập nhật file media',
  'sourceInformation.media.modal.form.name': 'Tên media file',
  'sourceInformation.media.modal.form.name.invalid': 'Tên media file không đúng định dạng',
  'sourceInformation.media.modal.form.fieldId': 'Lĩnh vực',
  'sourceInformation.media.modal.form.resourceType': 'Nội dung',
  'sourceInformation.media.modal.form.resourceType.0': 'Tệp tin',
  'sourceInformation.media.modal.form.resourceType.1': 'Tải từ đường dẫn',
  'sourceInformation.media.modal.form.resourceType.2': 'Phát trực tiếp',
  'sourceInformation.media.modal.form.resourceType.3': 'Kênh FM',
  'sourceInformation.media.modal.form.file': 'Tệp tin',
  'sourceInformation.media.modal.form.resourceUrl.url': 'Đường dẫn',
  'sourceInformation.media.modal.form.resourceUrl.stream': 'Liên kết phát trực tiếp',
  'sourceInformation.media.modal.form.resourceUrl.fm': 'Tần số kênh',
  'sourceInformation.media.modal.notEnoughCapacity.title': 'Bạn đã hết dung lượng lưu trữ',
  'sourceInformation.media.modal.notEnoughCapacity.content':
    'Hãy thay đổi gói cước khác hoặc giải phóng kho media file để tải media mới.',
  'sourceInformation.media.modal.upgradePackage.warning.title': 'Nâng cấp gói cước thất bại',
  'sourceInformation.media.modal.upgradePackage.warning.content':
    'Tổ chức của bạn đã có gói cước chờ tới ngày sử dụng. Để thay đổi gói cước khác vui lòng liên hệ Admin hệ thống.',
  'sourceInformation.media.modal.refuseApproval.title': 'Xác nhận từ chối duyệt media',
  'sourceInformation.media.modal.refuseApproval.content':
    'Bạn có chắc chắn muốn từ chối duyệt media này không?',
  'sourceInformation.media.modal.refuseApproval.label': 'Lý do từ chối',
  'sourceInformation.media.modal.refuseApproval.reasonRefusal': 'Lý do từ chối',
  'sourceInformation.media.modal.refuseApproval.reasonRefusal.placeholder':
    'Vui lòng nhập lý do từ chối',
  'sourceInformation.media.modal.shareInfo.title': 'Chia sẻ media',
  'sourceInformation.media.modal.shareInfo.tree.title': 'Chọn cơ sở để chia sẻ',
  'sourceInformation.media.modal.shareInfo.tree.required':
    'Vui lòng chọn ít nhất 1 cơ sở để chia sẻ',
  'sourceInformation.media.modal.shareInfo.info.title': 'Thông tin file media',
  'sourceInformation.media.modal.shareInfo.info.name': 'Tên media file',
  'sourceInformation.media.modal.shareInfo.info.field': 'Lĩnh vực',
  'sourceInformation.media.modal.shareInfo.info.formatName': 'Loại file',
  'sourceInformation.media.modal.shareInfo.info.formatSize': 'Thời lượng phát',
  'sourceInformation.router.media.info.name': 'Thông tin media file',
  'sourceInformation.media.info.tabs.media': 'Thông tin media file',
  'sourceInformation.media.info.tabs.usageNews': 'Bản tin sử dụng',
  'sourceInformation.media.info.breadcrumb.name': 'Thông tin media file',
  'sourceInformation.media.info.breadcrumb.usageNews': 'Bản tin sử dụng',
  'sourceInformation.media.info.sidebar.title': 'Thông tin media file',
  'sourceInformation.media.info.sidebar.name': 'Tên media file',
  'sourceInformation.media.info.sidebar.field': 'Lĩnh vực',
  'sourceInformation.media.info.sidebar.formatName': 'Loại file',
  'sourceInformation.media.info.sidebar.resourceUrl.1': 'Nội dung',
  'sourceInformation.media.info.sidebar.resourceUrl.2': 'Nội dung',
  'sourceInformation.media.info.sidebar.duration': 'Thời lượng phát',
  'sourceInformation.media.info.sidebar.size': 'Dung lượng',
  'sourceInformation.media.info.sidebar.approvalStatus': 'Trạng thái',
  'sourceInformation.media.info.sidebar.approverName': 'Người phê duyệt',
  'sourceInformation.media.info.sidebar.creatorName': 'Người tạo',
  'sourceInformation.media.info.sidebar.createdAt': 'Ngày tạo',
  'sourceInformation.media.info.sidebar.description': 'Mô tả',
  'sourceInformation.media.info.sidebar.reasonRefusal': 'Lý do từ chối',
  'sourceInformation.media.info.sidebar.facilityShare': 'Cơ sở chia sẻ',
  'sourceInformation.media.info.sidebar.processingStatus.param':
    '{status,select, 1 {Đang xử lý} 2 {Đã xử lý} other {Khác}}',
  'sourceInformation.media.info.sidebar.status.param':
    '{status,select, 1 {Đã duyệt} 2 {Chờ duyệt} 3 {Từ chối duyệt} other {Khác}}',
  'sourceInformation.media.info.follow.title': 'Theo dõi tình trạng chia sẻ của media',
  'sourceInformation.media.info.table.facility.name': 'Cơ sở chia sẻ',
  'sourceInformation.media.info.table.sharingTime': 'Thời gian chia sẻ',
  'sourceInformation.media.info.table.receptionStatus': 'Trạng thái tiếp nhận',
  'sourceInformation.media.info.table.receptionStatus.param':
    '{status,select, 1 {Chưa tiếp nhận} 2 {Đã tiếp nhận} other {Khác}}',
  'sourceInformation.media.info.delete.title': 'Xác nhận xóa file media',
  'sourceInformation.media.info.delete.content':
    'Các media file được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'sourceInformation.media.info.approve.title': 'Xác nhận duyệt media file',
  'sourceInformation.media.info.approve.content': 'Media file bạn chọn sẽ được phê duyệt',
  'sourceInformation.media.info.filter.sharingTime': 'Thời gian chia sẻ',
  'sourceInformation.media.info.filter.receptionStatus': 'Trạng thái',
  'sourceInformation.media.info.filter.receptionStatus.notUsedYet': 'Chưa tiếp nhận',
  'sourceInformation.media.info.filter.receptionStatus.used': 'Đã tiếp nhận',
  'sourceInformation.router.template.name': 'Bản Mẫu',
  'sourceInformation.template.title': 'Danh sách bản mẫu',
  'sourceInformation.template.table.name': 'Tên bản mẫu',
  'sourceInformation.template.table.resolutionWidth': 'Tỷ lệ màn hình',
  'sourceInformation.template.table.createdAt': 'Ngày tạo',
  'sourceInformation.template.table.creator.name': 'Người tạo',
  'sourceInformation.template.table.usageStatus': 'Trạng thái sử dụng',
  'sourceInformation.template.table.usageStatus.param':
    '{status,select,  1 {Chưa sử dụng} 2 {Đang sử dụng} other {Không xác định}}',
  'sourceInformation.template.delete.title': 'Xác nhận xóa bản mẫu',
  'sourceInformation.template.delete.content':
    'Các bản mẫu được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục',
  'sourceInformation.template.filter.createdAt': 'Ngày tạo',
  'sourceInformation.template.filter.userStatus': 'Trạng thái sử dụng',
  'sourceInformation.router.template.edit': 'Cập nhật bản mẫu',
  'sourceInformation.router.template.create': 'Tạo bản mẫu',
  'sourceInformation.template.create.breadcrumb.info.name': 'Thông tin bản mẫu',
  'sourceInformation.template.create.code.format.video': 'Video',
  'sourceInformation.template.create.code.format.image': 'Hình ảnh',
  'sourceInformation.template.create.code.format.stream': 'Phát trực tiếp',
  'sourceInformation.template.create.code.format.fmhz': 'Kênh FM',
  'sourceInformation.template.create.code.format.audio': 'Âm thanh',
  'sourceInformation.template.create.audio': 'Âm thanh sử dụng',
  'sourceInformation.template.create.name': 'Tên bản mẫu',
  'sourceInformation.template.create.ratio': 'Tỷ lệ màn hình',
  'sourceInformation.template.create.ratioWidth.required': 'Vui lòng nhập chiều ngang',
  'sourceInformation.template.create.ratioHeight.required': 'Vui lòng nhập chiều dọc',
  'sourceInformation.template.create.ratioWidth': 'Chiều ngang',
  'sourceInformation.template.create.ratioHeight': 'Chiều dọc',
  'sourceInformation.template.create.duration': 'Thời lượng',
  'sourceInformation.template.create.duration.required': 'Vui lòng nhập Thời lượng',
  'sourceInformation.template.create.media': 'Nguồn thông tin',
  'sourceInformation.template.create.media.search.placeholder': 'Tìm kiếm ...',
  'sourceInformation.template.create.size': 'Kích thước (%)',
  'sourceInformation.template.create.width': 'Ngang',
  'sourceInformation.template.create.height': 'Dọc',
  'sourceInformation.template.create.position': 'Vị trí (%)',
  'sourceInformation.template.create.positionX': 'Trục X',
  'sourceInformation.template.create.positionY': 'Trục Y',
  'sourceInformation.template.create.zIndex': 'Z-index',
  'sourceInformation.template.create.zIndex.placeholder': 'Z-index',
  'sourceInformation.template.info.tabs.name': 'Thông tin bản mẫu',
  'sourceInformation.template.info.tabs.newsLetters': 'Bản tin sử dụng',
  'sourceInformation.template.info.breadcrumbs.newsLetters': 'Bản tin sử dụng',
  'sourceInformation.template.info.breadcrumbs.name': 'Thông tin bản mẫu',
  'sourceInformation.router.playlist.name': 'Danh sách phát',
  'sourceInformation.playlist.title': 'Danh sách phát',
  'sourceInformation.playlist.table.name': 'Tên danh sách phát',
  'sourceInformation.playlist.table.duration': 'Thời lượng phát',
  'sourceInformation.playlist.table.field.name': 'Lĩnh vực',
  'sourceInformation.playlist.table.creator.name': 'Người tạo',
  'sourceInformation.playlist.table.createdAt': 'Ngày tạo',
  'sourceInformation.playlist.table.usageStatus': 'Trạng thái sử dụng',
  'sourceInformation.playlist.table.usageStatus.param':
    '{status,select, 1 {Chưa sử dụng} 2 {Đang sử dụng}  other {Undefined}}',
  'sourceInformation.playlist.delete.title': 'Xác nhận xóa danh sách phát',
  'sourceInformation.playlist.delete.content':
    'Các danh sách phát được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'sourceInformation.playlist.filter.fields': 'Lĩnh vực',
  'sourceInformation.playlist.filter.createdAt': 'Ngày tạo',
  'sourceInformation.router.playlist.create.name': 'Thêm danh sách phát',
  'sourceInformation.router.playlist.update.name': 'Cập nhật danh sách phát',
  'sourceInformation.playlist.info.code.format.video': 'Video',
  'sourceInformation.playlist.info.code.format.image': 'Hình ảnh',
  'sourceInformation.playlist.info.code.format.stream': 'Phát trực tiếp',
  'sourceInformation.playlist.info.code.format.fmhz': 'Kênh FM',
  'sourceInformation.playlist.info.code.format.audio': 'Âm thanh',
  'sourceInformation.playlist.info.form.emptySource.warn': 'Danh sách các nguồn thông tin trống',
  'sourceInformation.playlist.info.mainCard.title': 'Danh sách các nguồn thông tin',
  'sourceInformation.playlist.info.form.name.warning': 'Vui lòng nhập tên danh sách phát',
  'sourceInformation.playlist.info.form.name.warning255': 'Chiều dài quá 255 ký tự',
  'sourceInformation.playlist.info.form.name': 'Tên',
  'sourceInformation.playlist.info.form.name.placeholder': 'Tên',
  'sourceInformation.playlist.info.form.field': 'Lĩnh vực',
  'sourceInformation.playlist.info.form.field.placeholder': 'Lĩnh vực',
  'sourceInformation.playlist.info.form.playMode': 'Chế độ phát',
  'sourceInformation.playlist.info.form.playMode.1': 'Có thứ tự',
  'sourceInformation.playlist.info.form.playMode.2': 'Ngẫu nhiên',
  'sourceInformation.playlist.info.form.duration': 'Thời lượng danh sách phát',
  'sourceInformation.playlist.info.form.duration.placeholder': 'Thời lượng danh sách phát',
  'sourceInformation.playlist.info.form.description': 'Mô tả',
  'sourceInformation.playlist.info.form.description.placeholder': 'Mô tả',
  'sourceInformation.playlist.info.table.order': 'Thứ tự phát',
  'sourceInformation.playlist.info.table.name': 'Tên nguồn phát',
  'sourceInformation.playlist.info.table.duration': 'Thời lượng ',
  'sourceInformation.playlist.info.table.sourceType': 'Nguồn thông tin',
  'sourceInformation.playlist.info.table.sourceType.1': 'Bản mẫu',
  'sourceInformation.playlist.info.table.sourceType.2': 'Media',
  'sourceInformation.playlist.info.form.modal.sourceOptions.title':
    'Chọn các nguồn thông tin cần thêm vào danh sách phát',
  'sourceInformation.playlist.info.form.modal.tableTemplate.table.name': 'Tên nguồn phát',
  'sourceInformation.playlist.info.form.modal.tableTemplate.table.duration': 'Thời lượng',
  'sourceInformation.playlist.info.form.modal.tableTemplate.table.sourceCount': 'Số lần thêm',
  'sourceInformation.playlist.info.form.modal.sourceSelected.title': 'Nguồn thông tin đã chọn',
  'sourceInformation.playlist.info.form.modal.tableTemplateDisplay.table.name': 'Tên',
  'sourceInformation.playlist.info.form.modal.tableTemplateDisplay.table.duration': 'Thời lượng',
  'sourceInformation.playlist.info.form.modal.tableTemplateDisplay.table.sourceRemove': 'Gỡ',
  'sourceInformation.playlist.info.form.modal.durationWarning':
    'Thời lượng của tất cả nguồn thông tin phải lớn hơn 0',
  'sourceInformation.playlist.info.form.modal.filter.field': 'Lĩnh vực',
  'sourceInformation.playlist.info.form.modal.addSource': 'Thêm nguồn thông tin',
  'sourceInformation.playlist.info.form.modal.sourceType': 'Nguồn thông tin',
  'sourceInformation.playlist.info.form.modal.sourceType.1': 'Bản mẫu',
  'sourceInformation.playlist.info.form.modal.sourceType.2': 'Media',
  'sourceInformation.router.playlist.info.name': 'Thông tin danh sách phát',
  'sourceInformation.playlist.info.tabs.detail': 'Thông tin danh sách phát',
  'sourceInformation.playlist.info.tabs.detail.news': 'Bản tin sử dụng',
  'sourceInformation.playlist.info.breadcrumbs.detail.news': 'Bản tin sử dụng',
  'sourceInformation.playlist.info.breadcrumbs.detail': 'Thông tin danh sách phát',
  'sourceInformation.playlist.info.detail.sidebar.title': 'Thông tin danh sách phát',
  'sourceInformation.playlist.info.detail.sidebar.name': 'Tên danh sách phát',
  'sourceInformation.playlist.info.detail.sidebar.field': 'Lĩnh vực',
  'sourceInformation.playlist.info.detail.sidebar.creator': 'Người tạo',
  'sourceInformation.playlist.info.detail.sidebar.createdAt': 'Ngày tạo',
  'sourceInformation.playlist.info.detail.sidebar.duration': 'Thời lượng phát',
  'sourceInformation.playlist.info.detail.sidebar.playMode': 'Chế độ phát',
  'sourceInformation.playlist.info.detail.sidebar.playMode.1': 'Có thứ tự',
  'sourceInformation.playlist.info.detail.sidebar.playMode.2': 'Ngẫu nhiên',
  'sourceInformation.playlist.info.detail.sidebar.description': 'Mô tả',
  'sourceInformation.playlist.info.detail.title': 'Danh sách các nguồn thông tin',
  'sourceInformation.playlist.info.detail.delete.title': 'Xác nhận xóa danh sách phát',
  'sourceInformation.playlist.info.detail.delete.content':
    'Các danh sách phát được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'sourceInformation.playlist.info.detail.table.order': 'Thứ tự phát',
  'sourceInformation.playlist.info.detail.table.sourceName': 'Tên nguồn phát',
  'sourceInformation.playlist.info.detail.table.duration': 'Thời lượng ',
  'sourceInformation.playlist.info.detail.table.sourceType': 'Nguồn thông tin',
  'sourceInformation.playlist.info.detail.table.sourceType.1': 'Bản mẫu',
  'sourceInformation.playlist.info.detail.table.sourceType.2': 'Media',
  'sourceInformation.router.mediaShares.name': 'Media được chia sẻ',
  'sourceInformation.mediaShares.title': 'Danh sách media được chia sẻ',
  'sourceInformation.mediaShares.table.media.name': 'Tên file media',
  'sourceInformation.mediaShares.table.media.field.name': 'Lĩnh vực',
  'sourceInformation.mediaShares.table.media.creator.name': 'Người tạo',
  'sourceInformation.mediaShares.table.media.createdAt': 'Ngày tạo',
  'sourceInformation.mediaShares.table.media.facility.name': 'Cơ sở chia sẻ',
  'sourceInformation.mediaShares.table.sharingTime': 'Ngày chia sẻ',
  'sourceInformation.mediaShares.table.play.tooltip': 'Phát',
  'sourceInformation.mediaShares.filter.fields': 'Lĩnh vực',
  'sourceInformation.mediaShares.filter.createdAt': 'Ngày tạo',
  'sourceInformation.mediaShares.check.tooltip': 'Tiếp nhận',
  'sourceInformation.mediaShares.check.title': 'Xác nhận tiếp nhận media',
  'sourceInformation.mediaShares.check.content':
    'Các media bạn chọn để tiếp nhận sẽ trở thành media của bạn.',
  'sourceInformation.mediaShares.delete.title': 'Xác nhận xoá media được chia sẻ',
  'sourceInformation.mediaShares.delete.content':
    'Các media được chọn sẽ bị xóa khỏi cơ sở của bạn và không thể khôi phục.',
  'sourceInformation.mediaShares.modal.title': 'Chi tiết media được chia sẻ',
  'sourceInformation.mediaShares.modal.nameFile': 'Tên file media',
  'sourceInformation.mediaShares.modal.approver': 'Người phê duyệt',
  'sourceInformation.mediaShares.modal.fields': 'Lĩnh vực',
  'sourceInformation.mediaShares.modal.creator': 'Người tạo',
  'sourceInformation.mediaShares.modal.type': 'Định dạng file - Loại file',
  'sourceInformation.mediaShares.modal.createAt': 'Ngày tạo',
  'sourceInformation.mediaShares.modal.resourceUrl': 'File đính kèm hoặc link nội dung',
  'sourceInformation.mediaShares.modal.facility': 'Cơ sở chia sẻ',
  'sourceInformation.mediaShares.modal.duration': 'Thời lượng phát',
  'sourceInformation.mediaShares.modal.sharingTime': 'Ngày chia sẻ',
  'sourceInformation.mediaShares.modal.code.format.video': 'Video',
  'sourceInformation.mediaShares.modal.code.format.image': 'Hình ảnh',
  'sourceInformation.mediaShares.modal.code.format.stream': 'Phát trực tiếp',
  'sourceInformation.mediaShares.modal.code.format.fmhz': 'Kênh FM',
  'sourceInformation.mediaShares.modal.code.format.audio': 'Âm thanh',
};
