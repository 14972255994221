import '@shared/assets/css/animation.css';
import '@styles/styles.scss';
import 'leaflet/dist/leaflet.css';
import 'node_modules/video-react/dist/video-react.css';
import 'react-phone-input-2/lib/style.css';
import 'antd/dist/reset.css';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import Cookies from 'js-cookie';
import lodash, { isEqual } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { WarningOutlined } from '@ant-design/icons';
import CONFIG from '@config';
import store from '@core/store/redux';
import LoadingComponent from '@layout/LoadingComponent';
import locale from '@locale/index';
import { RootState } from '@modules';
import authenticationPresenter from '@modules/authentication/authenticationPresenter';
import profileStore, {
  removeProfile,
  TokenSelector,
  UserSelector,
} from '@modules/authentication/profileStore';
import { PermissionEnum } from '@modules/permissions/entity';
import settingStore, { LanguageSelector } from '@modules/setting/settingStore';
import { typeUser } from '@modules/user/entity';
import PrivatePage from '@routers/component/PrivatePage';
import PublicPage from '@routers/component/PublicPage';
import { ConfirmReStart } from '@shared/components/ConfirmReStart';
import ThemeContext, { ThemeColors } from '@shared/hook/ThemeContext';
import { useSingleAsync } from '@shared/hook/useAsync';
import { loadLocalHistory, saveLocalHistory } from '@shared/hook/useBackRouter';
import { toSearch, useQueryParams } from '@shared/hook/useQueryParams';
import { useAltaIntl } from '@shared/hook/useTranslate';

import { routerPage403 } from './Page403/router';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const initStyle: ThemeColors = {
  colorPrimary: '#F26D21',
  colorPrimaryLight: '#FCE2D3',
  colorPrimaryBg: '#FEF1E9',
  colorPrimaryText: '#E05200',
  colorText: '#333333',
  colorTextSecondary: '#4D4D4D',
  colorLink: '#fff',
  colorBgContainer: '#fff',
  colorWhite: '#fff',
  colorBgLayout: '#f5f5f5',
  fontFamily: 'open-san',
  colorError: 'red',
  colorTextBase: '#000',
  colorTextLightSolid: '#fff',
  colorTextBlue: '#001DB8',
  colorErrorBg: '#ff4d4f',
  colorPrimaryGradient: 'linear-gradient(184.26deg, #FF9960 32.21%, #FF7729 70.97%)',
};

function removeFieldsFromQuery(queryString, fields) {
  // Loại bỏ dấu '?' nếu có trong chuỗi query ban đầu
  if (queryString.startsWith('?')) {
    queryString = queryString.slice(1);
  }

  // Chia query thành các cặp key=value
  const paramsArray = queryString.split('&');

  // Lọc bỏ những cặp key=value có key thuộc danh sách cần loại bỏ
  const filteredParams = paramsArray.filter(param => {
    const [key] = param.split('=');
    return !fields.includes(key);
  });

  // Ghép lại thành chuỗi query
  return '?' + filteredParams.join('&');
}

const MainView = memo(({ statusLogin }: { statusLogin: boolean }) => {
  const { formatMessage } = useAltaIntl();
  const navigate = useNavigate();
  const token = useSelector(TokenSelector);
  const { login } = authenticationPresenter;
  const loginByAccount = useSingleAsync(login);
  const queryParams: any = useQueryParams();
  const { listPermissionCode } = useSelector((state: RootState) => state.profile);
  const { user } = useSelector(UserSelector);
  const dispatch = useDispatch();
  const { language } = useSelector(LanguageSelector);
  const [isLogIn, setIsLogIn] = useState<boolean>(false);
  const isLoggingOut = useSelector((state: RootState) => state.profile.isLoggingOut);
  const location = useLocation();

  useEffect(() => {
    const { pathname, search, hash } = window.location;
    const fullPath = `${pathname}${search}${hash}`;
    const localHistory = loadLocalHistory();

    if (!localHistory || localHistory?.length === 0) {
      const initialHistory = [
        { path: '/', fullPath: '/' },
        { path: pathname, fullPath: fullPath },
      ];
      saveLocalHistory(initialHistory);
    } else {
      const lastEntry = localHistory?.at(-1);

      const updatedHistory =
        lastEntry?.path === pathname
          ? [{ ...localHistory?.[0] }, { path: lastEntry.path, fullPath }]
          : [...localHistory, { path: pathname, fullPath }]?.slice(-2);

      saveLocalHistory(updatedHistory);
    }
  }, [location]);

  useEffect(() => {
    const updateProfilePermissions = () => {
      if (!listPermissionCode || user?.type === typeUser.Organization) return;
      const mergedPermissions = [...listPermissionCode, ...Object.values(PermissionEnum)];
      const uniquePermissions = [...new Set(mergedPermissions)];
      if (!isEqual(uniquePermissions, listPermissionCode)) {
        dispatch(profileStore.actions.updateProfile({ listPermissionCode: uniquePermissions }));
      }
    };
    updateProfilePermissions();
  }, [listPermissionCode, user?.type, dispatch]);

  useEffect(() => {
    if (queryParams && queryParams.hasOwnProperty('code')) {
      setIsLogIn(true);
      if (queryParams?.hasOwnProperty('removeAutoAccess')) {
        Cookies.remove('autoAccess');
      }
      loginByAccount
        .execute({
          code: queryParams?.code,
          redirectUri: (queryParams?.redirectUris || queryParams?.redirectUri) + location?.hash,
        })
        .then(() => {
          setIsLogIn(false);
          authenticationPresenter.getProfile().then(res => {
            if (lodash.isEmpty(res.role?.permissions)) {
              navigate(routerPage403.path);
            }
          });
          switch (queryParams.language) {
            case 'vi':
              store.dispatch(settingStore.actions.updateLanguage('vi'));
              break;
            case 'en':
              store.dispatch(settingStore.actions.updateLanguage('en'));
              break;
            default:
              break;
          }

          navigate({
            pathname: location.pathname,
            search: removeFieldsFromQuery(location.search, ['code', 'redirectUris', 'language']),
          });
        })
        .catch(err => {
          setIsLogIn(false);
          ConfirmReStart({
            modifiers: 'red',
            icon: <WarningOutlined />,
            hiddenCancel: true,
            okText: formatMessage('common.logout'),
            title: formatMessage('profile.code.title'),
            content: formatMessage('profile.code.content'),
            handleOk: () => {
              store.dispatch(removeProfile());
              window.location.href = CONFIG.LOGIN_PAGE;
            },
          });
        });
    } else if (lodash.isEmpty(token)) {
      if (queryParams && queryParams.hasOwnProperty('sessionId')) {
        return;
      }

      window.location.href =
        CONFIG.LOGIN_PAGE +
        '?' +
        toSearch({
          redirectUris: `${window.location.href}`,
          clientId: CONFIG.CLIENT_ID,
          language: language,
          autoAccess: 'true',
          // scope: CONFIG.SCOPE,
        });
      Cookies.set('autoAccess', 'true');
    }
  }, [queryParams]);

  if (isLogIn || isLoggingOut) {
    return <LoadingComponent />;
  }

  return <>{statusLogin ? <PrivatePage /> : <PublicPage />}</>;
});
const App: React.FC = () => {
  const token = useSelector(TokenSelector);
  const { language } = useSelector(LanguageSelector);
  const memoLangData = useMemo(() => {
    return locale[language];
  }, [language]);
  const queryParam = useQueryParams();
  useEffect(() => {
    switch (queryParam.language) {
      case 'vi':
        store.dispatch(settingStore.actions.updateLanguage('vi'));
        break;
      case 'en':
        store.dispatch(settingStore.actions.updateLanguage('en'));
        break;
      default:
        break;
    }
  }, [queryParam.language]);
  return (
    <IntlProvider locale={language} messages={memoLangData}>
      <ThemeContext token={initStyle}>
        <MainView statusLogin={!lodash.isEmpty(token)} />
      </ThemeContext>
    </IntlProvider>
  );
};

export default App;
