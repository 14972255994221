import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerTariffInfo: IRouter = {
  path: '/tariff/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'tariff.TariffManagement.info.router.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.PackagesViewDetail],
};
