import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerPlaylistAdd: IRouter = {
  path: '/source-information/playlist/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.playlist.create.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.PlaylistsCreate,

  masterLayout: true,
};

export const routerPlaylistUpdate: IRouter = {
  path: '/source-information/playlist/update/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.playlist.update.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.PlaylistsUpdate,
  masterLayout: true,
};

export const routerOrganizationPlaylistAdd: IRouter = {
  path: '/organization/:organizationId/source-information/playlist/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.playlist.create.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerOrganizationPlaylistUpdate: IRouter = {
  path: '/organization/:organizationId/source-information/playlist/update/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.playlist.update.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
