export default {
  'profile.code.title': 'Account Suspended',
  'profile.code.content':
    'Your account is currently suspended, so you cannot access the system. Please contact the system admin for resolution.',
  'profile.router.name': 'Profile',
  'profile.title': 'Basic Information',
  'profile.fullName': 'Full Name',
  'profile.phone': 'Phone Number',
  'profile.role': 'Role',
  'profile.timezone': 'Timezone',
  'profile.email': 'Email',
  'profile.tariffPackagesMonth': 'Current Subscription',
  'profile.tariffPackagesTime': 'Valid To',
  'profile.tariffPackages.ghn': 'Renew Now',
  'profile.tariffPackages.btn.ghn': 'Renew',
  'profile.tariffPackagesUpdate': 'Subscription Upgrade',
  'profile.tariffPackagesUpdate.btn': 'Upgrade',
  'profile.tariffPackages.history': 'Renewal History',
  'profile.upgradePackage.warning.title': 'Upgrade Subscription Failed',
  'profile.upgradePackage.warning.content':
    'Your account already has a subscription awaiting use. To change to a different subscription, please contact the system Admin.',
  'profile.modal.extend.tariff.upgradePackage.warning.title': 'Upgrade Subscription Failed',
  'profile.modal.extend.tariff.upgradePackage.warning.content':
    'Your account already has a subscription awaiting use. To change to a different subscription, please contact the system Admin.',
  'profile.modal.extend.tariff.status': 'Status',
  'profile.modal.extend.tariff.end': 'Discontinued',
  'profile.modal.extend.tariff.error.1': 'Please select a different subscription to use',
  'profile.modal.extend.tariff.capacity': 'Capacity',
  'profile.modal.extend.tariff.time': 'Validity Period',
  'profile.router.renewalHistory.name': 'Profile',
  'profile.renewalHistory.title.1': 'Subscription Information',
  'profile.renewalHistory.title.2': 'Subscription Renewal History',
  'profile.renewalHistory.tariffPackagesMonth': 'Current Subscription',
  'profile.renewalHistory.tariffPackagesTime': 'Expiration Time',
  'profile.renewalHistory.tariffPackages.ghn': 'Renew Now',
  'profile.renewalHistory.tariffPackages.btn.ghn': 'Renew',
  'profile.renewalHistory.tariffPackagesUpdate': 'Upgrade Subscription',
  'profile.renewalHistory.tariffPackagesUpdate.btn': 'Upgrade',
  'profile.renewalHistory.createdAt': 'Renewal Time',
  'profile.renewalHistory.upgradePackage.warning.title': 'Upgrade Subscription Failed',
  'profile.renewalHistory.upgradePackage.warning.content':
    'Your account already has a subscription awaiting use. To change to a different subscription, please contact the system Admin.',
  'profile.renewalHistory.table.payer.name': 'Renewal Person',
  'profile.renewalHistory.table.payer.phoneNumber': 'Phone Number',
  'profile.renewalHistory.table.gracetime': 'Renewal Time',
  'profile.renewalHistory.table.effectiveTime': 'Effective Time',
  'profile.renewalHistory.table.capacity': 'Renewed Subscription',
  'profile.renewalHistory.table.price': 'Subscription Price',
  'profile.renewalHistory.table.paymentStatus': 'Status',
  'profile.renewalHistory.table.paymentStatus.params':
    '{status,select, 1 {Success} 2 {Processing} 3 {Failed} 4 {Cancelled} other {Other}}',
  'profile.renewalHistory.table.paymentMethod': 'Payment Method',
  'profile.renewalHistory.table.paymentMethod-1': 'Direct',
  'profile.renewalHistory.table.paymentMethod-2': 'E-Wallet',
  'profile.renewalHistory.table.paymentMethod-3': 'VNPay',
  'profile.renewalHistory.table.paymentMethod-5': 'PayPal',
  'profile.renewalHistory.table.paymentMethod-6': 'OnePay',
  'profile.renewalHistory.table.paymentMethod-4': '--',
  'profile.renewalHistory.table.paymentMethod-other': 'Other Methods',
};
