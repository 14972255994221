import dayjs from 'dayjs';

import FacilitiesEntity from '@modules/facilities/facilitiesEntity';
import OrganizationsEntity from '@modules/organizations/organizationsEntity';
import PermissionsEntity from '@modules/permissions/entity';
import RoleEntity from '@modules/roles/RoleEntity';
import TimezonesEntity from '@modules/timezones/timezonesEntity';

export enum Status {
  Active = 1,
  Inactive = 2,
}

export enum typeUser {
  System = 1,
  Organization = 2,
}

class UserEntity {
  username: string = '';

  name?: string = '';

  avatarPicture?: string = '';

  identifierNumber: string = '';

  email: string = '';

  phoneNumber: string = '';

  permissions: PermissionsEntity[] = [];

  isOrganizationOwner?: boolean;

  updateAt = '';

  role?: RoleEntity;

  status?: Status;

  roleId: string = '';

  id: string = '';

  createdAt: string = '';

  facility: FacilitiesEntity = {} as FacilitiesEntity;

  type?: typeUser;

  lastAccessedAt: string = '';

  timezone?: TimezonesEntity;

  organization?: OrganizationsEntity;

  newsletterCreationMode?: any;

  constructor(user?: Partial<UserEntity>) {
    if (!user) {
      return;
    }
    Object.assign(this, user);
    this.createdAt = user?.createdAt ? dayjs(user?.createdAt).format('DD/MM/YYYY') : '';
    this.lastAccessedAt = user?.lastAccessedAt
      ? dayjs(user?.lastAccessedAt).format('DD/MM/YYYY HH:mm:ss')
      : '';
  }

  static createArrayUser(arrUser: Array<Partial<UserEntity>>): Array<UserEntity> {
    if (!arrUser) {
      return [];
    }
    return arrUser.map(x => new UserEntity(x));
  }
}

export default UserEntity;
