import React from 'react';

import Icon from '@ant-design/icons';

const icon = () => (
  <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 7.66797V15.668M13 15.668L10.3333 13.0013M13 15.668L15.6667 13.0013M8.2 20.3346C4.22355 20.3346 1 17.1405 1 13.2004C1 9.93474 3.4 6.91797 7 6.33464C8.12925 3.6498 10.8019 1.66797 13.919 1.66797C17.9121 1.66797 21.1756 4.76465 21.4 8.66797C23.5191 9.59448 25 11.8684 25 14.3305C25 17.6465 22.3137 20.3346 19 20.3346L8.2 20.3346Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </svg>
);
export const SourceIcon = props => <Icon component={icon} {...props} />;
