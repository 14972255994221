import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';

export const routerNewsletter: IRouter = {
  path: '/newsletter',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UserOutlined />,
  },
};

export const routerOrganizationNewsletter: IRouter = {
  path: '/organization/:organizationId/newsletter',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  activePath: '/newsletter',
  generatePath(organizationId) {
    return `/organization/${organizationId}/newsletter`;
  },
};
