import React from 'react';

import { TariffIcon } from '@assets/icon/tarifficon';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerTariff: IRouter = {
  path: '/tariff',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'tariff.router.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'tariff.router.nameBreadcrumb',
  masterLayout: true,
  menu: {
    icon: <TariffIcon className="icon-tariff" />,
  },
  permissionCode: [PermissionEnum.PackagesView, PermissionEnum.PackagesPaymentHistoriesView],
  generatePath(_, listPermissions: string[]) {
    const permissionTabs = [
      PermissionEnum.PackagesView,
      PermissionEnum.PackagesPaymentHistoriesView,
    ];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/tariff?tab=${permissionTabs.find(item => listPermissions.includes(item))}`;
    }
    return `/tariff?tab=${PermissionEnum.PackagesView}`;
  },
};
export const routerTariffManagement: IRouter = {
  path: `/tariff?tab=${PermissionEnum.PackagesView}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'tariff.router.TariffManagement', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.PackagesView,
};
export const routerTariffRenewalHistory: IRouter = {
  path: `/tariff?tab=${PermissionEnum.PackagesPaymentHistoriesView}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'tariff.router.TariffRenewalHistory', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.PackagesPaymentHistoriesView,
};
