import React from 'react';

import { FacilityIcon } from '@assets/icon/facilityIcon';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerFacility: IRouter = {
  path: '/facility/:facilityId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'facility.router.nameBreadcrumb',
  menu: {
    icon: <FacilityIcon />,
    activePath: '/facility/',
  },
  permissionCode: [
    PermissionEnum.FacilitiesViewDetail,
    PermissionEnum.FacilitiesUsersView,
    PermissionEnum.FacilitiesDevicesView,
    PermissionEnum.FacilitiesMediasView,
    PermissionEnum.FacilitiesTemplatesView,
    PermissionEnum.FacilitiesPlaylistsView,
  ],
  generatePath(facilityId, listPermissionCode: string[]) {
    const permissionTabs = [
      PermissionEnum.FacilitiesViewDetail,
      PermissionEnum.FacilitiesUsersView,
      PermissionEnum.FacilitiesDevicesView,
      PermissionEnum.FacilitiesMediasView,
      PermissionEnum.FacilitiesTemplatesView,
      PermissionEnum.FacilitiesPlaylistsView,
    ];
    if (permissionTabs.find(item => listPermissionCode?.includes(item))) {
      return `/facility/${facilityId}?tab=${permissionTabs.find(item =>
        listPermissionCode?.includes(item),
      )}`;
    }
    return `/facility/${facilityId}?tab=${PermissionEnum.FacilitiesViewDetail}`;
  },
};

export const routerFacilitySystem: IRouter = {
  path: '/organizations/:organizationId/facility/:facilityId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    activePath: '/facility',
    icon: <FacilityIcon />,
  },
  permissionCode: [
    PermissionEnum.FacilitiesViewDetail,
    PermissionEnum.FacilitiesUsersView,
    PermissionEnum.FacilitiesDevicesView,
    PermissionEnum.FacilitiesMediasView,
    PermissionEnum.FacilitiesTemplatesView,
    PermissionEnum.FacilitiesPlaylistsView,
  ],
  generatePath(organizationId) {
    return `/organizations/${organizationId}/facility/${organizationId}?tab=${PermissionEnum.FacilitiesViewDetail}`;
  },
};
