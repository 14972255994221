import React from 'react';

import { IRouter } from '@routers/interface';

export const routerPackageManagement: IRouter = {
  path: '/package-management',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'packageManagement.router.name',
  masterLayout: false,
};
