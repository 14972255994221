import React from 'react';

import { FacilityIcon } from '@assets/icon/facilityIcon';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerOrganizations: IRouter = {
  path: '/organizations',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'organizations.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'organizations.router.nameBreadcrumb',
  menu: {
    icon: <FacilityIcon />,
  },
  permissionCode: PermissionEnum.OrganizationsView,
};

export const routerOrganizationsPageAdd: IRouter = {
  path: '/organizations/add',
  loader: React.lazy(() => import('./Pages/PageAdd/index')),
  exact: true,
  name: 'organizations.modal.create.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.OrganizationsCreate,
};
