import { RootState } from '@modules';
import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';

import UserEntity from './entity';

interface IUserStore {
  user?: UserEntity;
}

const userStore = createSlice({
  name: 'organizations',
  initialState: {
    user: undefined,
  } as IUserStore,
  reducers: {
    setUser: (state, action: PayloadAction<UserEntity>) => {
      state.user = action.payload;
    },
    removeUser: state => {
      state.user = undefined;
    },
  },
});
export const userSelector: Selector<RootState, UserEntity | undefined> = state =>
  state.userStore.user;

export default userStore;
