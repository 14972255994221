export default {
  'tariff.router.name': 'Gói cước',
  'tariff.router.nameBreadcrumb': 'Quản lý gói cước',
  'tariff.optionsFilter.TariffManagement': 'Danh sách gói cước',
  'tariff.optionsFilter.TariffRenewalHistory': 'Lịch sử giao dịch',
  'tariff.router.TariffManagement': 'Danh sách gói cước',
  'tariff.router.TariffRenewalHistory': 'Lịch sử giao dịch',
  'tariff.TariffManagement.title': 'Danh sách gói cước',
  'tariff.TariffManagement.table.capacity': 'Số dung lượng',
  'tariff.TariffManagement.table.name': 'Tên gói cước',
  'tariff.TariffManagement.table.periodByMonth': 'Thời hạn',
  'tariff.TariffManagement.table.price': 'Giá gói cước',
  'tariff.TariffManagement.table.accessibility': 'Công khai gói cước',
  'tariff.TariffManagement.table.numberOfPurchases': 'Số lượng đăng ký',
  'tariff.TariffManagement.table.createdAt': 'Thời gian tạo',
  'tariff.TariffManagement.table.businessStatusExtend': 'Trạng thái',
  'tariff.TariffManagement.table.businessStatus.param':
    '{status,select, 1 {Đang kinh doanh} 2 {Ngưng kinh doanh} 3 {Chưa kinh doanh} 4 {Đã kết thúc} other {Undefined}}',
  'tariff.TariffManagement.deleteTitle': 'Xác nhận xóa gói cước',
  'tariff.TariffManagement.deleteContent': 'Gói cước này sẽ bị xóa và không thể khôi phục. ',
  'tariff.TariffManagement.filter.businessStatus': 'Trạng thái',
  'tariff.TariffManagement.filter.businessStatus.inBusiness': 'Đang kinh doanh',
  'tariff.TariffManagement.filter.businessStatus.outOfBusiness': 'Ngưng kinh doanh',
  'tariff.TariffManagement.filter.periodByMonth': 'Thời hạn',
  'tariff.TariffManagement.router.add.name': 'Thêm gói cước',
  'tariff.TariffManagement.router.update.name': 'Cập nhật gói cước',
  'tariff.TariffManagement.update.title': 'Cập nhật gói cước',
  'tariff.TariffManagement.add.title': 'Thêm gói cước',
  'tariff.TariffManagement.form.capacity': 'Số dung lượng',
  'tariff.TariffManagement.form.capacity.msg': 'Dung lượng phải là bội số 0.5',
  'tariff.TariffManagement.form.code': 'Mã gói cước',
  'tariff.TariffManagement.form.name': 'Tên gói cước',
  'tariff.TariffManagement.form.tariffName': 'Tên dung lượng',
  'tariff.TariffManagement.form.periodByMonth': 'Thời hạn',
  'tariff.TariffManagement.form.periodByMonth.blank': 'Vui lòng chọn thời hạn',
  'tariff.TariffManagement.form.periodByMonth.msg': 'Trùng thời hạn',
  'tariff.TariffManagement.form.price': 'Giá gói cước',
  'tariff.TariffManagement.form.businessStatus': 'Trạng thái',
  'tariff.TariffManagement.form.accessibility.public': 'Công khai gói cước',
  'tariff.TariffManagement.form.accessibility.imited': 'Trong khoảng thời gian',
  'tariff.TariffManagement.form.accessibility': 'Công khai gói cước',
  'tariff.TariffManagement.form.applicableTime.msg': 'Vui lòng chọn thời gian',
  'tariff.TariffManagement.form.numberOfPurchases': 'Số lượng đăng ký',
  'tariff.TariffManagement.form.createdAt': 'Thời gian tạo',
  'tariff.TariffManagement.form.btn.add': 'Thêm gói cước cho thời hạn khác',
  'tariff.TariffManagement.form.businessStatus.inBusiness': 'Đang kinh doanh',
  'tariff.TariffManagement.form.businessStatus.outOfBusiness': 'Ngưng kinh doanh',
  'tariff.TariffManagement.info.businessStatus.inBusiness': 'Đang kinh doanh',
  'tariff.TariffManagement.info.businessStatus.outOfBusiness': 'Ngưng kinh doanh',
  'tariff.TariffManagement.info.title': 'Thông tin gói cước',
  'tariff.TariffManagement.info.router.title': 'Chi tiết gói cước',
  'tariff.TariffManagement.info.capacity': 'Dung lượng',
  'tariff.TariffManagement.info.code': 'Mã gói cước',
  'tariff.TariffManagement.info.name': 'Tên gói cước',
  'tariff.TariffManagement.info.periodByMonth': 'Thời hạn',
  'tariff.TariffManagement.info.price': 'Giá gói cước',
  'tariff.TariffManagement.info.businessStatus': 'Trạng thái',
  'tariff.TariffManagement.info.createdAt': 'Thời gian tạo',
  'tariff.TariffManagement.info.creator': 'Người tạo',
  'tariff.TariffManagement.info.accessibility': 'Công khai gói cước',
  'tariff.TariffManagement.info.numberOfPurchases': 'Số lượng đăng ký',
  'tariff.TariffManagement.info.time': 'Ngày hiệu lực',
  'tariff.TariffManagement.info.error': 'Gói cước của bạn đã ngưng kinh doanh.',
  'tariff.TariffManagement.info.tariffName': 'Tên dung lượng',
  'tariff.TariffRenewalHistory.table.accessibility.public.unlimited': 'Không thời hạn đăng ký',
  'tariff.TariffRenewalHistory.table.accessibility.private': 'Không công khai',
  'tariff.TariffRenewalHistory.summary.totalTariffPackage': 'Tổng số gói cước',
  'tariff.TariffRenewalHistory.summary.totalRevenue': 'Tổng doanh thu',
  'tariff.TariffRenewalHistory.title': 'Lịch sử giao dịch',
  'tariff.TariffRenewalHistory.table.organizationCode': 'Mã định danh tổ chức',
  'tariff.TariffRenewalHistory.table.organization': 'Tổ chức',
  'tariff.TariffRenewalHistory.table.organizationName': 'Tên tổ chức',
  'tariff.TariffRenewalHistory.table.gracetime': 'Thời gian gia hạn',
  'tariff.TariffRenewalHistory.table.capacity': 'Dung lượng',
  'tariff.TariffRenewalHistory.table.periodByMonth': 'Thời hạn',
  'tariff.TariffRenewalHistory.table.EffectiveTime': 'Thời gian hiệu lực',
  'tariff.TariffRenewalHistory.table.price': 'Giá gói cước',
  'tariff.TariffRenewalHistory.table.payerName': 'Người thanh toán',
  'tariff.TariffRenewalHistory.table.paymentMethod': 'Phương thức thanh toán',
  'tariff.TariffRenewalHistory.table.paymentStatus': 'Trạng thái thanh toán',
  'tariff.TariffRenewalHistory.table.paymentStatus.param':
    '{status,select, 1 {Thành công} 2 {Đang xử lý} 3 {Thất bại} 4 {Đã hủy} 5 {Chưa thanh toán} other {Khác}}',
  'tariff.TariffRenewalHistory.filter.isLimited.no': 'Không thời hạn',
  'tariff.TariffRenewalHistory.filter.organization': 'Tổ chức',
  'tariff.TariffRenewalHistory.filter.periodByMonth': 'Thời hạn',
  'tariff.TariffRenewalHistory.filter.paymentStatus': 'Trạng thái thanh toán',
  'tariff.TariffRenewalHistory.filter.gracetime': 'Thời gian gia hạn',
  'tariff.TariffRenewalHistory.filter.paymentStatus.success': 'Thành công',
  'tariff.TariffRenewalHistory.filter.paymentStatus.processing': 'Đang xử lí',
  'tariff.TariffRenewalHistory.filter.paymentStatus.failure': 'Thất bại',
  'tariff.TariffRenewalHistory.filter.paymentStatus.cancel': 'Đã hủy',
  'tariff.TariffRenewalHistory.filter.paymentStatus.unpaid': 'Chưa thanh toán',
  'tariff.TariffManagement.form.limitField.title': 'Giới hạn số lượng thiết bị kết nối',
  'tariff.TariffManagement.form.limitField.label.unlimited': 'Không Giới Hạn',
  'tariff.TariffManagement.form.limitField.label.limited': 'Giới Hạn Số Lượng',
  'tariff.TariffManagement.form.limitField.input.placeholder': 'Nhập số lượng',
  'tariff.TariffManagement.form.limitField.input.messageRequired':
    'Vui lòng nhập số lượng thiết bị',
  'tariff.TariffManagement.form.limitField.input.unit': 'Thiết bị/Tổ chức',
  'tariff.TariffManagement.form.publicField.title': 'Công khai lên trang bán gói cước',
  'tariff.TariffManagement.form.publicField.label.notPublic': 'Không Công Khai',
  'tariff.TariffManagement.form.publicField.label.publicUnlimited': 'Công Khai Không Thời Hạn',
  'tariff.TariffManagement.form.publicField.label.publicLimited': 'Công Khai Có Thời Hạn',
  'tariff.TariffManagement.info.deviceLimit': 'Giới hạn thiết bị',
  'tariff.TariffManagement.info.deviceLimit.unlimited': 'Không giới hạn',
  'tariff.TariffManagement.table.deviceLimit': 'GH thiết bị',
  'tariff.TariffManagement.table.deviceLimit.unlimited': 'Không giới hạn',
  'tariff.TariffRenewalHistory.table.periodByMonth.infinite': 'Vô hạn',
};
