import React from 'react';

import { IRouter } from '@routers/interface';

export const routerFacilityDevicesInfo: IRouter = {
  path: '/facility/:facilityId/facility-device/info/:deviceId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.router.device.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(deviceId: string, facilityId: string, listPermissions: string[]) {
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/facility/${facilityId}/facility-device/info/${deviceId}?tab=${permissionTabs.find(
        item => listPermissions.includes(item),
      )}`;
    }
    return `/facility/${facilityId}/facility-device/info/${deviceId}?tab=tab1`;
  },
};

export const routerOrganizationFacilityDevicesInfo: IRouter = {
  path: '/organization/:organizationId/facility/:facilityId/facility-device/info/:deviceId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.router.device.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(
    deviceId: string,
    facilityId: string,
    listPermissions: string[],
    organizationId: string,
  ) {
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/organization/${organizationId}/facility/${facilityId}/facility-device/info/${deviceId}?tab=${permissionTabs.find(
        item => listPermissions.includes(item),
      )}`;
    }
    return `/organization/${organizationId}/facility/${facilityId}/facility-device/info/${deviceId}?tab=tab1`;
  },
};
