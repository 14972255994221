import React from 'react';

import { ControlOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerOrganizationSetting: IRouter = {
  path: '/settings/organization-setting',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.organizationSetting.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'settings.endUser.router.organizationSetting.name',
  icon: <ControlOutlined />,
  permissionCode: PermissionEnum.OrganizationsSettingsView,
};

export const routerOrganizationOrganizationSetting: IRouter = {
  path: '/settings/organization/:organizationId/organization-setting',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.organizationSetting.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'settings.endUser.router.organizationSetting.name',
  icon: <ControlOutlined />,
  activePath: '/organization-setting',
  generatePath(organizationId) {
    return `/settings/organization/${organizationId}/organization-setting`;
  },
  permissionCode: PermissionEnum.OrganizationsSettingsView,
};
