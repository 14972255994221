import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerTemplateFacility: IRouter = {
  path: `/facility/:facilityId/?tab=${PermissionEnum.FacilitiesTemplatesView}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.template.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.FacilitiesTemplatesView,
};
