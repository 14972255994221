import React from 'react';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerOrganizationsInfoEdit: IRouter = {
  path: '/organizations/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'organizations.router.info.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.OrganizationsViewDetail,
  masterLayout: true,
};
