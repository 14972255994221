import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import FacilitiesEntity from '@modules/facilities/facilitiesEntity';
import FieldsEntity from '@modules/fields/fieldsEntity';
import MediaFormatsEntity from '@modules/mediaFormats/mediaFormatsEntity';
import { TemplateUsageStatus } from '@modules/template/templateEntity';
import UserEntity from '@modules/user/entity';

export enum ApprovalStatus {
  Approve = 1,
  NotApprovedYet = 2,
  Refuse = 3,
}
export enum SourceCreation {
  SelfCreated = 1,
  Shared = 2,
}
export enum ProcessingStatus {
  Processing = 1,
  Completed = 2,
}

class MediasEntity {
  //copy props from backend:
  id: string = '';

  mediaId = '';

  createdAt = '';

  name = '';

  field?: FieldsEntity;

  description = '';

  content = '';

  resourceUrl = '';

  thumbnail = '';

  format?: MediaFormatsEntity;

  extension = '';

  duration = 0;

  size = 0;

  checksum = 0;

  approver?: UserEntity;

  creator?: UserEntity;

  facility?: FacilitiesEntity;

  positionX: string = '0';

  approvalStatus: ApprovalStatus = ApprovalStatus.NotApprovedYet;

  positionY: string = '0';

  positionZ = '1';

  sizeWidth = '30';

  sizeHeight = '30';

  isMute = false;

  media: any;

  reasonRefusal = '';

  templateId = '';

  value = '';

  label = '';

  usageStatus: TemplateUsageStatus = TemplateUsageStatus.NotUsedYet;

  sourceCreation?: number;

  processingStatus?: number;

  resourceType?: number;

  fmChanel?: any;

  constructor(medias: Partial<MediasEntity>) {
    if (!medias) {
      return;
    }
    Object.assign(this, medias);
    if (medias.media != null) {
      Object.assign(this, medias.media);
    }
    // convert entity type here
    this.createdAt = medias.createdAt ? dayjs(medias.createdAt).format('DD/MM/YYYY HH:mm') : '';
    this.id = medias.media ? medias.media.id : medias.id;
    this.positionX = medias.positionX ? medias.positionX.toString() : '0';
    this.positionY = medias.positionY ? medias.positionY.toString() : '0';
    this.positionZ = medias.positionZ ? medias.positionZ.toString() : '1';
    this.sizeWidth =
      medias.sizeWidth != null && medias.media != null ? medias.sizeWidth.toString() : '30';
    this.sizeHeight =
      medias.sizeHeight != null && medias.media != null ? medias.sizeHeight.toString() : '30';
    this.templateId = uuidv4();
    this.mediaId = this.id;
    this.value = medias.id ? medias.id : '';
    this.label = medias.name ? medias.name : '';
  }

  static createListMedias(listMedias: Array<Partial<MediasEntity>>) {
    if (!Array.isArray(listMedias)) {
      return [];
    }
    return listMedias.map((medias: Partial<MediasEntity>) => {
      return new MediasEntity(medias);
    });
  }
}
export default MediasEntity;
