import React from 'react';

import { IRouter } from '@routers/interface';

export const routerPlansPage: IRouter = {
  path: '/plans-page',
  loader: React.lazy(() => import('@shared/components/StepView')),
  exact: true,
  masterLayout: false,
};
