import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerFacilitiesUsers: IRouter = {
  path: `/facilities-users?tab=${PermissionEnum.FacilitiesUsersView}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.router.user.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <UserOutlined />,
  permissionCode: PermissionEnum.FacilitiesUsersView,
};
