export default {
  'settings.endUser.router.deviceGroup.name': 'Nhóm thiết bị',
  'settings.endUser.router.deviceGroup.nameBreadcrumb': 'Danh mục nhà phân phối thiết bị',
  'settings.endUser.deviceGroup.title': 'Danh sách nhóm thiết bị',
  'settings.endUser.deviceGroup.table.name': 'Tên nhóm thiết bị',
  'settings.endUser.deviceGroup.table.description': 'Mô tả',
  'settings.endUser.deviceGroup.table.device': 'Số thiết bị thuộc nhóm',
  'settings.endUser.deviceGroup.table.update': 'Cập nhật',
  'settings.endUser.deviceGroup.modal.form.name.validator':
    'Tên nhóm thiết bị không đúng định dạng',
  'settings.endUser.deviceGroup.modal.form.name': 'Tên nhóm thiết bị',
  'settings.endUser.deviceGroup.modal.form.description': 'Mô tả',
  'settings.endUser.deviceGroup.modal.title.update': 'Cập nhật nhóm thiết bị',
  'settings.endUser.deviceGroup.modal.title.create': 'Thêm nhóm thiết bị',
  'settings.endUser.deviceGroup.delete.title': 'Xác nhận xóa nhóm thiết bị',
  'settings.endUser.deviceGroup.delete.content': 'Nhóm thiết bị này sẽ bị xóa khỏi hệ thống',
};
