export default {
  'settings.systemUser.router.name': 'System Installation',
  'settings.systemUser.router.role.name': 'User Management',
  'settings.systemUser.router.role.breadcrumb': 'Role Management',
  'settings.systemUser.user.tab.name': 'Users',
  'settings.systemUser.role.tab.name': 'Role List',
  'settings.systemUser.user.breadCrumbs': 'Users',
  'settings.systemUser.role.breadCrumbs': 'Role List',
  'settings.systemUser.user.havePermission': 'Authorized',
  'settings.systemUser.user.noPermission': 'Unauthorized',
  'settings.systemUser.user.noPermission.title': 'Unauthorized User List',
  'settings.systemUser.user.title': 'Authorized User List',
  'settings.systemUser.user.table.username': 'Login Username',
  'settings.systemUser.user.table.name': 'Full Name',
  'settings.systemUser.user.table.phoneNumber': 'Phone Number',
  'settings.systemUser.user.table.email': 'Email',
  'settings.systemUser.user.table.role.name': 'Role',
  'settings.systemUser.user.table.status': 'Status',
  'settings.systemUser.user.table.status.param':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'settings.systemUser.user.table.lastAccessedAt': 'Last Accessed',
  'settings.systemUser.user.delete.title': 'Confirm user Deletion?',
  'settings.systemUser.user.delete.content': 'This user information will be deleted.',
  'settings.systemUser.user.filter.role.name': 'Role',
  'settings.systemUser.user.filter.status': 'Status',
  'settings.systemUser.user.filter.status.active': 'Active',
  'settings.systemUser.user.filter.status.notActive': 'Inactive',
  'settings.systemUser.user.modal.update': 'Update User',
  'settings.systemUser.user.modal.information': 'User Information',
  'settings.systemUser.user.modal.create': 'Create User',
  'settings.systemUser.user.modal.remind.password':
    "If you do not enter a password, it will be automatically generated and sent to the user's email.",
  'settings.systemUser.user.modal.form.username': 'Login Username',
  'settings.systemUser.user.modal.form.validatorUserName': 'Invalid login username format',
  'settings.systemUser.user.modal.form.name': 'Full Name',
  'settings.systemUser.user.modal.form.validatorUndefined': 'Invalid full name format',
  'settings.systemUser.user.modal.form.email': 'Email',
  'settings.systemUser.user.modal.form.password': 'Password',
  'settings.systemUser.user.modal.form.username.placeholder': 'Please enter the login username',
  'settings.systemUser.user.modal.form.username.note': 'The username to access is',
  'settings.systemUser.user.modal.form.password.required':
    'Password must be at least 8 characters long and include lowercase letters, uppercase letters, numbers, and special characters.',
  'settings.systemUser.user.modal.form.identifierNumber': 'ID card number',
  'settings.systemUser.user.modal.form.roleId': 'Role',
  'settings.systemUser.user.modal.form.roleId.validator': 'Please enter role',
  'settings.systemUser.user.modal.form.isActive': 'Status',
  'settings.systemUser.user.modal.form.isActive.validator': 'Please enter status',
  'settings.systemUser.user.modal.form.status.active': 'Active',
  'settings.systemUser.user.modal.form.status.notActive': 'Inactive',
  'settings.systemUser.user.modal.form.userPhone': 'Phone Number',
  'settings.systemUser.user.modal.form.timezoneId': 'Timezone',
  'settings.systemUser.user.modal.form.timezoneId.validator': 'Please enter timezone',
  'settings.systemUser.user.modal.form.confirmPassWord': 'Confirm Password',
  'settings.systemUser.user.modal.form.confirmPassWord.required': 'Passwords Do Not Match',
  'settings.systemUser.user.modal.form.userAvatar': 'Avatar',
  'settings.systemUser.role.title': 'Role List',
  'settings.systemUser.role.table.name': 'Role Name',
  'settings.systemUser.role.table.code': 'Role Code',
  'settings.systemUser.role.table.description': 'Description',
  'settings.systemUser.role.delete.title': 'Confirm Role Deletion?',
  'settings.systemUser.role.delete.content': 'This role information will be deleted.',
  'settings.systemUser.router.role.add': 'Add Role',
  'settings.systemUser.router.role.update': 'Update Role',
  'settings.systemUser.router.role.info': 'Role Information',
  'settings.systemUser.role.info.titleRight': 'Functionalities',
  'settings.systemUser.role.info.required': 'Please select functionalities',
  'settings.systemUser.role.form.table.resource': 'Group of Functions',
  'settings.systemUser.role.form.table.permissions': 'Function',
  'settings.systemUser.role.form.name': 'Role Name',
  'settings.systemUser.role.form.name.validatorUndefined': 'Please enter role name',
  'settings.systemUser.role.form.name.validatorName': 'Invalid role name format',
  'settings.systemUser.role.form.code': 'Role Code',
  'settings.systemUser.role.form.code.validatorCode': 'Invalid role code format',
  'settings.systemUser.role.form.code.validatorUndefinedCode': 'Please enter role code',
  'settings.systemUser.role.form.description': 'Description',
  'settings.endUser.router.user.name': 'System Installation',
  'settings.endUser.user.title': 'System Users List',
  'settings.endUser.router.userLogs.name': 'User Action Audit',
  'settings.endUser.userLogs.title': 'User Action Audit',
  'settings.endUser.userLogs.ApprovalStatus.params.media':
    '{status, select, 1 {Approved} 2 {Waiting for Approval} 3 {Refused} other {--}}',
  'settings.endUser.userLogs.ApprovalStatus.params.news':
    '{status, select, 1 {Approved} 2 {Waiting for Approval} 3 {Refused} other {--}}',
  'settings.endUser.userLogs.OperationStatus.params':
    '{status,select, 1 {Active} 2 {Inactive} other {Unspecified}}',
  'settings.endUser.userLogs.PlayingStatus.params':
    '{status, select, 1 {Unprocessed} 2 {Broadcasting} 3 {Paused} 4 {Canceled} 5 {Finished} 6 {Waiting for Broadcast}  other {--}}',
  'settings.endUser.userLogs.ConnectionStatus.params':
    '{status,select, 1 {Active} 2 {Inactive} other {Other}}',
  'settings.endUser.userLogs.PlayMode.params':
    '{status,select, 1 {In Order} 2 {Random Order} other {Other}}',
  'settings.endUser.userLogs.SourceType.params':
    '{status,select, 1 {Download from URL or File} 2 {Live Streaming} 3 {FM Channel} other {Other}}',
  'settings.endUser.userLogs.Status.params': '{status,select, 1 {Active} 2 {Inactive} other {--}}',
  'settings.endUser.userLogs.table.actor.name': 'Actor',
  'settings.endUser.userLogs.table.impactTime': 'Action Time',
  'settings.endUser.userLogs.table.actionType': 'Affected Function',
  'settings.endUser.userLogs.table.properties.oldValue': 'Old Value',
  'settings.endUser.userLogs.table.properties.newValue': 'New Value',
  'settings.endUser.userLogs.filter.createdAt': 'Time',
  'settings.endUser.userLogs.actionType.create': 'Create',
  'settings.endUser.userLogs.actionType.update': 'Update',
  'settings.endUser.userLogs.actionType.delete': 'Delete',
  'settings.endUser.userLogs.actionType.approve': 'Approve',
  'settings.endUser.userLogs.actionType.resufe': 'Reject',
  'settings.endUser.userLogs.actionType.share': 'Share',
  'settings.endUser.userLogs.actionType.reception': 'Receive',
  'settings.endUser.userLogs.actionType.cancel': 'Cancel',
  'settings.endUser.userLogs.resourceType.facility': 'Location',
  'settings.endUser.userLogs.resourceType.user': 'User',
  'settings.endUser.userLogs.resourceType.device': 'Device',
  'settings.endUser.userLogs.resourceType.media': 'Media',
  'settings.endUser.userLogs.resourceType.template': 'Template',
  'settings.endUser.userLogs.resourceType.playlist': 'Playlist',
  'settings.endUser.userLogs.resourceType.newsletter': 'Broadcast Schedule',
  'settings.endUser.userLogs.resourceType.field': 'Category',
  'settings.endUser.userLogs.resourceType.supplier': 'Device Provider',
  'settings.endUser.userLogs.resourceType.tariffPackage': 'Storage Subscription',
  'settings.endUser.userLogs.resourceType.organization': 'Account',
  'settings.endUser.userLogs.Username': 'Full Name',
  'settings.endUser.userLogs.Email': 'Email',
  'settings.endUser.userLogs.Name': 'Name',
  'settings.endUser.userLogs.PhoneNumber': 'Phone Number',
  'settings.endUser.userLogs.Status': 'Status',
  'settings.endUser.userLogs.IdentifierNumber': 'ID Card Number',
  'settings.endUser.userLogs.Role': 'Role',
  'settings.endUser.userLogs.Code': 'Code',
  'settings.endUser.userLogs.ResolutionWidth': 'Width',
  'settings.endUser.userLogs.ResolutionHeight': 'Height',
  'settings.endUser.userLogs.Duration': 'Duration',
  'settings.endUser.userLogs.Source': 'Filter',
  'settings.endUser.userLogs.Field.Name': 'Category',
  'settings.endUser.userLogs.PlayMode': 'Play Mode',
  'settings.endUser.userLogs.Title': 'Title',
  'settings.endUser.userLogs.ReasonRefusal': 'Reason for Rejection',
  'settings.endUser.userLogs.SourceType': 'Media Type',
  'settings.endUser.userLogs.ApprovalStatus': 'Approval Status',
  'settings.endUser.userLogs.Media.Name': 'Media Name',
  'settings.endUser.userLogs.Template.Name': 'Template Name',
  'settings.endUser.userLogs.Playlist.Name': 'Playlist Name',
  'settings.endUser.userLogs.Checksum': 'Checksum',
  'settings.endUser.userLogs.Approver.Name': 'Approver Name',
  'settings.endUser.userLogs.Facility.Name': 'Location Name',
  'settings.endUser.userLogs.Parent.Name': 'Superior Location Name',
  'settings.endUser.userLogs.Account.Name': 'Login Username',
  'settings.endUser.userLogs.IsAutoRenewal': 'Automatic Renewal',
  'settings.endUser.userLogs.OperationStatus': 'Status',
  'settings.endUser.userLogs.TariffPackage.Code': 'Code',
  'settings.endUser.userLogs.SpecificAddress': 'Address',
  'settings.endUser.userLogs.PlayingStatus': 'Playing Status',
  'settings.endUser.userLogs.ConnectionStatus': 'Connection Status',
  'settings.endUser.userLogs.Supplier.Name': 'Provider Name',
  'settings.endUser.userLogs.Content': 'Content',
  'settings.endUser.userLogs.Role.Name': 'Role',
  'settings.endUser.userLogs.FormatCode': 'Media Type',
  'settings.endUser.userLogs.ParentId': 'Superior Loacation ID',
  'settings.endUser.userLogs.ParentName': 'Superior Loacation Name',
  'settings.endUser.suppliers.title': 'List of Device Provider',
  'settings.endUser.suppliers.router.name': 'Device Provider',
  'settings.endUser.suppliers.router.nameBreadcrumb': 'Device Provider Management',
  'settings.endUser.suppliers.table.code': 'Provider Code',
  'settings.endUser.suppliers.table.name': 'Provider Name',
  'settings.endUser.suppliers.table.description': 'Description',
  'settings.endUser.suppliers.delete.title': 'Confirm Delete Provider',
  'settings.endUser.suppliers.delete.content': 'This provider will be deleted from the system',
  'settings.endUser.suppliers.modal.create': 'Add Provider Information',
  'settings.endUser.suppliers.modal.update': 'Update provider information',
  'settings.endUser.suppliers.modal.form.code': 'Provider Code',
  'settings.endUser.suppliers.modal.form.validatorCode': 'Invalid provider code',
  'settings.endUser.suppliers.modal.form.name': 'Provider Name',
  'settings.endUser.suppliers.modal.form.validatorName': 'Invalid provider name',
  'settings.endUser.suppliers.modal.form.description': 'Description',
  'settings.endUser.router.fields.name': 'Category',
  'settings.endUser.router.fields.nameBreadcrumb': 'Category Management',
  'settings.endUser.fields.title': 'List of Categories',
  'settings.endUser.fields.delete.title': 'Confirm Delete Categories',
  'settings.endUser.fields.delete.content': 'This categories will be deleted from the system',
  'settings.endUser.fields.table.name': 'Category Name',
  'settings.endUser.fields.table.code': 'Category Code',
  'settings.endUser.fields.modal.form.code': 'Category Code',
  'settings.endUser.fields.modal.form.code.validatorCode': 'Invalid category code',
  'settings.endUser.fields.modal.form.name': 'Category Name',
  'settings.endUser.fields.modal.form.name.validatorName': 'Invalid category name',
  'settings.endUser.fields.modal.create': 'Add New Category',
  'settings.endUser.fields.modal.update': 'Update Category',
  'settings.endUser.fields.modal.information': 'Category Information',
  'settings.endUser.router.organizationSetting.name': 'System Setting',
  'settings.endUser.organizationSetting.title': 'System Setting',
  'settings.endUser.organizationSetting.newsletterCreationMode': 'Broadcasting Mode',
  'settings.endUser.organizationSetting.newsletterCreationMode.DistributeOnFacility':
    'Broadcast by Location',
  'settings.endUser.organizationSetting.newsletterCreationMode.DistributeOnDevice':
    'Broadcast by Device',
  'settings.endUser.organizationSetting.timezoneId': 'Timezone',
  'settings.endUser.organizationSetting.timezoneId.placeholder': 'Select Timezone',
  'settings.endUser.organizationSetting.modal.form.newsletterCreationMode': 'Broadcasting Mode',
  'settings.endUser.organizationSetting.modal.form.newsletterCreationMode.DistributeOnFacility':
    'Broadcast by Location',
  'settings.endUser.organizationSetting.modal.form.newsletterCreationMode.DistributeOnDevice':
    'Broadcast by Device',
  'settings.endUser.organizationSetting.modal.form.timezoneId': 'Timezone',
  'settings.endUser.organizationSetting.modal.form.timezoneId.placeholder': 'Select Timezone',
  'settings.endUser.organizationSetting.modal.update': 'Edit System Setting',
  DevicesCreate: 'Create device',
  DevicesDelete: 'Delete device',
  DevicesUpdate: 'Update device',
  DevicesView: 'View device list',
  DevicesViewDetail: 'View device detail',
  FacilitiesCreate: 'Create sub-location',
  FacilitiesDelete: 'Delete sub-location',
  FacilitiesDevicesCreate: 'Create device for sub-location',
  FacilitiesDevicesDelete: 'Delete device for sub-location',
  FacilitiesDevicesUpdate: 'Update device for sub-location',
  FacilitiesDevicesView: 'View device list of sub-location',
  FacilitiesDevicesViewDetail: 'View device detail of sub-location',
  FacilitiesMediasApprove: 'Approve media for sub-location',
  FacilitiesMediasCreate: 'Create media for sub-location',
  FacilitiesMediasDelete: 'Delete media for sub-location',
  FacilitiesMediasShare: 'Share media for sub-location',
  FacilitiesMediasUpdate: 'Update media for sub-location',
  FacilitiesMediasView: 'View media list of sub-location',
  FacilitiesMediasViewDetail: 'View media detail of sub-location',
  FacilitiesPlaylistsCreate: 'Create playlist for sub-location',
  FacilitiesPlaylistsDelete: 'Delete playlist for sub-location',
  FacilitiesPlaylistsUpdate: 'Update playlist for sub-location',
  FacilitiesPlaylistsView: 'View playlists of sub-location',
  FacilitiesPlaylistsViewDetail: 'View playlist detail of sub-location',
  FacilitiesTemplatesCreate: 'Create template for sub-location',
  FacilitiesTemplatesDelete: 'Delete template for sub-location',
  FacilitiesTemplatesUpdate: 'Update template for sub-location',
  FacilitiesTemplatesView: 'View template list of sub-location',
  FacilitiesTemplatesViewDetail: 'View template detail of sub-location',
  FacilitiesUpdate: 'Update location',
  FacilitiesUsersCreate: 'Create user for sub-location',
  FacilitiesUsersDelete: 'Delete user for sub-location',
  FacilitiesUsersUpdate: 'Update user for sub-location',
  FacilitiesUsersView: 'View user list of sub-location',
  FacilitiesUsersViewDetail: 'View user detail of sub-location',
  FacilitiesView: 'View sub-location list',
  FacilitiesViewDetail: 'View sub-location detail',
  FieldsCreate: 'Create category',
  FieldsDelete: 'Delete category',
  FieldsUpdate: 'Update category',
  FieldsView: 'View category list',
  FieldsViewDetail: 'View category detail',
  MediasApprove: 'Approve media',
  MediasCreate: 'Create media',
  MediasDelete: 'Delete media',
  MediaSharesDelete: 'Delete shared media',
  MediaSharesReception: 'Receive/Delete shared media',
  MediaSharesView: 'View shared media list',
  MediasShare: 'Share media',
  MediasUpdate: 'Update media',
  MediasView: 'View media list',
  MediasViewDetail: 'View media detail',
  NewslettersApprove: 'Approve/reject broadcast',
  NewslettersCancel: 'Cancel broadcast',
  NewslettersCreate: 'Create broadcast',
  NewslettersDelete: 'Delete broadcast',
  NewslettersUpdate: 'Update broadcast',
  NewslettersView: 'View broadcast list',
  NewslettersViewDetail: 'View broadcast detail',
  OrganizationsCreate: 'Create account',
  OrganizationsDelete: 'Delete account',
  OrganizationsUpdate: 'Update account',
  OrganizationsView: 'View account',
  OrganizationsViewDetail: 'View account detail',
  PlaylistsCreate: 'Create playlist',
  PlaylistsDelete: 'Delete playlist',
  PlaylistsUpdate: 'Update playlist',
  PlaylistsView: 'View playlists',
  PlaylistsViewDetail: 'View playlist detail',
  RolesCreate: 'Create role',
  RolesDelete: 'Delete role',
  RolesUpdate: 'Update role',
  RolesView: 'View role list',
  RolesViewDetail: 'View role detail',
  SuppliersCreate: 'Create provider',
  SuppliersDelete: 'Delete provider',
  SuppliersUpdate: 'Update provider',
  SuppliersView: 'View provider list',
  SuppliersViewDetail: 'View provider detail',
  TemplatesCreate: 'Create template',
  TemplatesDelete: 'Delete template',
  TemplatesUpdate: 'Update template',
  TemplatesView: 'View template list',
  TemplatesViewDetail: 'View template detail',
  UsersCreate: 'Create user',
  UsersDelete: 'Delete user',
  UsersUpdate: 'Update user',
  UsersView: 'View user list',
  UsersViewDetail: 'View user detail',
  UsersViewUnAuthorized: 'View unauthorized user list',
  AnnouncementsCreate: 'Create notification',
  AnnouncementsDelete: 'Delete notification',
  AnnouncementsUpdate: 'Update notification',
  AnnouncementsView: 'View notification',
  AnnouncementsViewDetail: 'View notification detail',
  DevicesAdjustVolume: 'Adjust device volume',
  DevicesReboot: 'Reboot device',
  FacilitiesDevicesAdjustVolume: 'Adjust volume of sub-location device',
  FacilitiesDevicesReboot: 'Reboot sub-location device',
  NewslettersPlayback: 'Stop/Resume playback',
  FacilitiesDevicesPlaylogsView: 'View play history of sub-location device',
  FacilitiesMediaSharesView: 'View shared media of sub-location',
  Facilities: 'Location',
  Devices: 'Devices',
  Fields: 'Category',
  Medias: 'Medias',
  MediaShares: 'Shared Media',
  Newsletters: 'Broadcast Schedule',
  Playlists: 'Playlists',
  Roles: 'Roles',
  Suppliers: 'Device Provider',
  Templates: 'Templates',
  Users: 'Users',
  Organizations: 'Account',
  OverviewsEndUser: 'End User Dashboard',
  OverviewsEndUserView: 'View end user dashboard',
  NewslettersPlaylogsView: 'View broadcast play history',
  NewslettersSubUnitView: 'View sub-location broadcast',
  NewslettersSubUnitViewDetail: 'View sub-location broadcast detail',
  NewslettersPlayLog: 'View sub-location broadcast detail',
  NewslettersSubUnitPlaylogsView: 'View sub-location broadcast play history',
  OrganizationsPaymentHistoriesView: 'View account payment history',
  OrganizationsSettingsUpdate: 'Edit settings',
  OrganizationsSettingsView: 'View settings',
  OrganizationsPackagesRenewal: 'Renew subscription',
  OrganizationsPackagesUnUsed: 'Update subscription',
  DevicesPlaylogsView: 'View device play history',
  Announcements: 'Notification',
  OrganizationsRenewal: 'Renew account subscription',
  OrganizationsTruncate: 'Cancel account',
  OrganizationsUnUsed: 'Upgrade account subscription',
  OrganizationsManage: 'Manage location',
  Packages: 'Storage subscription',
  PackagesCreate: 'Add subscription',
  PackagesDelete: 'Delete subscription',
  PackagesPaymentHistoriesView: 'View transaction history',
  PackagesUpdate: 'Update subscription',
  PackagesView: 'View subscription',
  PackagesViewDetail: 'View subscription detail',
  OverviewsSystemUser: 'System Overview',
  OverviewsSystemUserView: 'View system overview',
  DevicesActivitieslogsView: 'View device activity logs',
  FacilitiesDevicesSchedulesView: 'View sub-location device schedule',
  FacilitiesDevicesActivitieslogsView: 'View sub-location device activity logs',
  PaymentMethods: 'Payment Methods',
  PaymentMethodsUpdate: 'Update payment methods',
  PaymentMethodsView: 'View payment methods',

  // Update device group
  DeviceGroups: 'Device groups',
  DeviceGroupsCreate: 'Create device group',
  DeviceGroupsDelete: 'Delete device group',
  DeviceGroupsUpdate: 'Update device group',
  DeviceGroupsView: 'View device group',
};
