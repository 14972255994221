export default {
  'news.router.name.1': 'Bản tin',
  'news.router.name.2': 'Bản tin của cơ sở',
  'news.router.nameBreadcrumb': 'Quản lý bản tin',
  'news.tabs.label.1': 'Bản tin của cơ sở',
  'news.tabs.label.2': 'Bản tin cơ sở con',
  'news.title': 'Danh sách bản tin của cơ sở',
  'news.title.media': 'Danh sách bản tin sử dụng',
  'news.approval.title': 'Xác nhận duyệt bản tin',
  'news.approval.content': 'Bản tin bạn chọn sẽ được phê duyệt',
  'news.create.continue': 'Tạo bản tin tiếp nối',
  'news.lastTime.title': 'Không thể tạo bản tin',
  'news.lastTime.content': 'Không thể tạo bản tin tiếp nối vì đã hết ngày',
  'news.plus.square': 'Tạo bản sao',
  'news.icon.pause': 'Tạm ngưng',
  'news.pause.title': 'Xác nhận tạm ngưng phát bản tin',
  'news.pause.content':
    'Bản tin đang được phát trên thiết bị. Bạn có chắc chắn muốn tạm ngừng phát bản tin không?',
  'news.icon.continue': 'Tiếp tục phát',
  'news.continue.title': 'Xác nhận tiếp tục phát bản tin',
  'news.continue.content':
    'Bản tin đang được tạm ngưng phát trên thiết bị. Bạn có chắc chắn muốn tiếp tục phát bản tin không?',
  'news.cancelNews.title': 'Xác nhận hủy bản tin',
  'news.cancelNews.content': 'Bản tin bạn chọn sẽ bị hủy',
  'news.delete.title': 'Xác nhận xóa bản tin',
  'news.delete.content': 'Bản tin này sẽ xóa khỏi hệ thống',
  'news.filter.type': 'Loại bản tin',
  'news.filter.type.normal': 'Tin thường',
  'news.filter.type.prioritize': 'Tin ưu tiên',
  'news.filter.approvalStatus': 'Trạng thái',
  'news.filter.approvalStatus.pending': 'Chờ duyệt',
  'news.filter.approvalStatus.refuse': 'Từ chối duyệt',
  'news.filter.playingStatus.not.broadcasted': 'Chưa phát',
  'news.filter.playingStatus.broadcasting': 'Đang phát',
  'news.filter.playingStatus.pause': 'Tạm ngưng',
  'news.filter.playingStatus.cancelled': 'Đã hủy',
  'news.filter.playingStatus.ended': 'Đã kết thúc',
  'news.filter.playingStatus.waiting': 'Chờ phát',
  'news.filter.playingStatus.coming': 'Sắp tới lịch',
  'news.filter.repeatType': 'Kiểu lặp',
  'news.filter.repeatType.none': 'Không lặp',
  'news.filter.repeatType.daily': 'Hằng ngày',
  'news.filter.repeatType.weekly': 'Ngày trong tuần',
  'news.filter.repeatType.monthly': 'Ngày trong tháng',
  'news.filter.time': 'Thời gian bắt đầu phát',
  'news.refuse.approval.title': 'Xác nhận từ chối duyệt bản tin',
  'news.refuse.approval.content': 'Bạn có chắc chắn muốn từ chối duyệt bản tin này không?',
  'news.refuse.approval.label': 'Lý do từ chối',
  'news.form.refuse.approval.label': 'Lý do từ chối',
  'news.form.refuse.approval.placeholder': 'Vui lòng nhập lí do từ chối',
  'news.table.title': 'Tiêu đề',
  'news.table.type': 'Loại bản tin',
  'news.table.type.normal': 'Tin thường',
  'news.table.type.prioritize': 'Tin ưu tiên',
  'news.table.bulletinStatus': 'Trạng thái',
  'news.table.bulletinStatus.id':
    '{status, select, 1 {Chờ duyệt} 2 {Từ chối duyệt} 3 {Đã hủy} 4 {Chưa phát} 5 {Đang phát} 6 {Tạm ngưng} 7 {Đã kết thúc} 8 {Chờ phát} 9 {Sắp tới lịch} other {--}}',
  'news.table.repeatType': 'Kiểu lặp',
  'news.table.repeatType.none': 'Không lặp',
  'news.table.repeatType.daily': 'Hằng ngày',
  'news.table.repeatType.weekly': 'Ngày trong tuần',
  'news.table.repeatType.monthly': 'Ngày trong tháng',
  'news.table.finalDate': 'Ngày phát',
  'news.table.timeSlots': 'Giờ phát',
  'news.table.facilities': 'Cơ sở phát',
  'news.table.creator.name': 'Người tạo',
  'news.router.info.detail.name': 'Thông tin bản tin',
  'news.router.info.history.name': 'Lịch sử phát bản tin',
  'news.info.tabs.detail': 'Thông tin bản tin',
  'news.info.tabs.history': 'Lịch sử phát bản tin',
  'news.info.detail.lastTime.title': 'Không thể tạo bản tin',
  'news.info.detail.lastTime.content': 'Không thể tạo bản tin tiếp nối vì đã hết ngày',
  'news.info.detail.table.name': 'Nguồn tin',
  'news.info.detail.table.template': 'Bản mẫu',
  'news.info.detail.table.duration': 'Thời lượng',
  'news.info.detail.table.icon.play': 'Phát',
  'news.info.detail.title.label': 'Tiêu đề',
  'news.info.detail.type': 'Loại bản tin',
  'news.info.detail.type.normal': 'Tin thường',
  'news.info.detail.type.prioritize': 'Tin ưu tiên',
  'news.info.detail.field.name': 'Lĩnh vực',
  'news.info.detail.sourceType': 'Loại nguồn tin:',
  'news.info.detail.media': 'Media',
  'news.info.detail.template': 'Bản mẫu',
  'news.info.detail.playlist': 'Danh sách phát',
  'news.info.detail.sourceType.name': 'Nguồn phát bản tin',
  'news.info.detail.approvalStatus': 'Trạng thái',
  'news.info.detail.bulletinStatus.id':
    '{status, select, 1 {Chờ duyệt} 2 {Từ chối duyệt} 3 {Đã hủy} 4 {Chưa phát} 5 {Đang phát} 6 {Tạm ngưng} 7 {Đã kết thúc} 8 {Chờ phát} 9 {Sắp tới lịch} other {--}}',
  'news.info.detail.repeatType.none': 'Không lặp',
  'news.info.detail.repeatType.daily': 'Hằng ngày',
  'news.info.detail.repeatType.weekly': 'Ngày trong tuần',
  'news.info.detail.repeatType.monthly': 'Ngày trong tháng',
  'news.info.detail.sunday': 'Chủ nhật',
  'news.info.detail.monday': 'Thứ 2',
  'news.info.detail.tuesday': 'Thứ 3',
  'news.info.detail.wednesday': 'Thứ 4',
  'news.info.detail.thursday': 'Thứ 5',
  'news.info.detail.friday': 'Thứ 6',
  'news.info.detail.saturday': 'Thứ 7',
  'news.info.detail.finalDate': 'Ngày phát',
  'news.info.detail.timeSlots': 'Số lần phát',
  'news.info.detail.timeSlots.count.1': 'Phát',
  'news.info.detail.timeSlots.count.2': 'lần',
  'news.info.detail.type.same': 'Chế độ phát bản tin',
  'news.info.detail.repeatType.title': 'Chế độ lặp',
  'news.info.detail.action.add': 'Tạo bản tin tiếp nối',
  'news.info.detail.action.plusSquare': 'Tạo bản sao',
  'news.info.detail.icon.pause': 'Tạm ngưng',
  'news.info.detail.pause.title': 'Xác nhận tạm ngưng phát bản tin',
  'news.info.detail.pause.content':
    'Bản tin đang được phát trên thiết bị. Bạn có chắc chắn muốn tạm ngừng phát bản tin không?',
  'news.info.detail.icon.continue': 'Tiếp tục phát',
  'news.info.detail.continue.title': 'Xác nhận tiếp tục phát bản tin',
  'news.info.detail.continue.content':
    'Bản tin đang được tạm ngưng phát trên thiết bị. Bạn có chắc chắn muốn tiếp tục phát bản tin không?',
  'news.info.detail.approval.title': 'Xác nhận duyệt bản tin',
  'news.info.detail.approval.content': 'Bản tin bạn chọn sẽ được phê duyệt',
  'news.info.detail.cancelNews.title': 'Xác nhận hủy bản tin',
  'news.info.detail.cancelNews.content': 'Bản tin bạn chọn sẽ bị hủy',
  'news.info.detail.delete.name': 'Xóa bản tin',
  'news.info.detail.delete.title': 'Xác nhận xóa bản tin',
  'news.info.detail.delete.content': 'Bản tin này sẽ xóa khỏi hệ thống',
  'news.info.detail.title.1': '1. Thông tin cơ bản',
  'news.info.detail.title.2': '2. Cơ sở',
  'news.info.detail.title.3': '3. Danh sách nguồn tin',
  'news.info.detail.title.4': '4. Lịch phát bản tin',
  'news.info.detail.refuse.approval.label': 'Lý do từ chối',
  'news.info.detail.device.name': '2. Thiết bị',
  'news.info.detail.facilities.operationStatus': 'Trạng thái cơ sở',
  'news.info.detail.devices.operationStatus': 'Trạng thái thiết bị',
  'news.info.detail.title': 'Thông tin bản tin',
  'news.info.history.title': 'Lịch sử phát bản tin',
  'news.info.history.table.device.code': 'Mã thiết bị',
  'news.info.history.table.device.name': 'Tên thiết bị',
  'news.info.history.table.startTime': 'Thời gian bắt đầu phát',
  'news.info.history.table.endTime': 'Thời gian kết thúc phát',
  'news.info.history.table.newsletter.facilities': 'Cơ sở',
  'news.info.history.filter.startTime': 'Thời gian bắt đầu phát',
  'news.breadcrumbs.list': 'Danh sách bản tin',
  'news.breadcrumbs.edit': 'Chỉnh sửa bản tin',
  'news.breadcrumbs.add': 'Thêm bản tin',
  'news.create.title': 'Thêm mới bản tin',
  'news.edit.title': 'Chỉnh sửa bản tin',
  'news.create.facility.schedule.title': '2. Lịch phát bản tin',
  'news.create.facility.sunday': 'Chủ nhật',
  'news.create.facility.monday': 'Thứ 2',
  'news.create.facility.tuesday': 'Thứ 3',
  'news.create.facility.wednesday': 'Thứ 4',
  'news.create.facility.thursday': 'Thứ 5',
  'news.create.facility.friday': 'Thứ 6',
  'news.create.facility.saturday': 'Thứ 7',
  'news.create.facility.schedule.form.type': 'Chế độ phát bản tin',
  'news.create.facility.schedule.form.type.normal': 'Phát theo lịch',
  'news.create.facility.schedule.form.type.prioritize': 'Phát ngay sau khi phê duyệt',
  'news.create.facility.schedule.form.timeSlot': 'Giờ phát bản tin',
  'news.create.facility.schedule.form.timeSlot.timeRelease': 'Theo thời lượng',
  'news.create.facility.schedule.form.timeSlot.timeSlot': 'Theo khung giờ',
  'news.create.facility.schedule.form.dayFrom': 'Ngày phát bản tin',
  'news.create.facility.schedule.form.validatorReleaseDate':
    'Ngày bắt đầu phải nhỏ hơn ngày kết thúc.',
  'news.create.facility.schedule.form.validatorReleaseDateRequired': 'Vui lòng nhập ngày phát',
  'news.create.facility.schedule.form.dayTo': 'Ngày kết thúc bản tin',
  'news.create.facility.schedule.form.timeSlots.validator.wrongPosition':
    'Thời gian phát phải theo đúng tự từ nhỏ đến lớn',
  'news.create.facility.schedule.form.timeSlots.validator':
    'Thời gian lặp bản tin không được trùng nhau',
  'news.create.facility.schedule.form.timeSlots.validator-1':
    'Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc',
  'news.create.facility.schedule.form.timeSlots.validator-required': 'Vui lòng chọn thời gian.',
  'news.create.facility.schedule.form.timeSlots.endTime.required': 'Vui lòng chọn thời gian.',
  'news.create.facility.schedule.form.timeSlots.over.required':
    'Thời gian kết thúc không được lớn hơn 23:59:59',
  'news.create.facility.schedule.form.numberRepeat': 'Số lần phát',
  'news.create.facility.schedule.repeatForm.form.typeRepeat': 'Chế độ lặp',
  'news.create.facility.schedule.repeatForm.repeat.none': 'Không lặp',
  'news.create.facility.schedule.repeatForm.repeat.all': 'Lặp hằng ngày',
  'news.create.facility.schedule.repeatForm.repeat.day': 'Lặp theo tuần vào các ngày',
  'news.create.facility.schedule.repeatForm.repeat.date': 'Lặp theo tháng vào các ngày',
  'news.create.facility.schedule.info.form.name': 'Tiêu đề',
  'news.create.facility.schedule.info.form.field': 'Lĩnh vực',
  'news.create.facility.schedule.info.form.field.placeholder': 'Vui lòng chọn lĩnh vực',
  'news.create.facility.schedule.info.form.facility': 'Cơ sở',
  'news.create.facility.schedule.info.form.placeholder': 'Vui lòng chọn cơ sở',
  'news.create.facility.schedule.info.form.location': '1. Thông tin bản tin',
  'news.create.facility.schedule.info.form.sourceType': 'Loại nguồn tin',
  'news.create.facility.schedule.info.form.sourceType.placeholder': 'Vui lòng chọn loại nguồn tin',
  'news.create.facility.schedule.info.form.template': 'Bản mẫu',
  'news.create.facility.schedule.info.form.media': 'Media',
  'news.create.facility.schedule.info.form.playlist': 'Danh sách phát',
  'news.create.facility.schedule.info.form.sourceId': 'Nguồn phát bản tin',
  'news.create.facility.schedule.info.form.sourceId.placeholder':
    'Vui lòng chọn nguồn phát bản tin',
  'news.create.facility.schedule.info.form.sourceDuration': 'Thời lượng',
  'news.create.facility.schedule.info.form.validatorMinimumDuration':
    'Thời lượng phải lớn hơn 00:00:00',
  'news.create.facility.schedule.info.form.validatorRequiredDuration':
    'Thời lượng không được bỏ trống',
  'news.create.facility.schedule.modal.conflict.title': 'Danh sách bản tin bị trùng lịch',
  'news.create.facility.schedule.modal.conflict.okText': 'Ghi đè',
  'news.create.facility.schedule.modal.conflict.facilityPlay': 'Cơ sở phát',
  'news.create.facility.schedule.modal.conflict.facilityLastItem':
    'Phải có ít nhất một cơ sở phát.',
  'news.create.facility.schedule.modal.conflict.table.newsletter.title': 'Bản tin',
  'news.create.facility.schedule.modal.conflict.table.newsletter.facility.name': 'Cơ sở tạo',
  'news.create.facility.schedule.modal.conflict.table.facility': 'Cơ sở phát',
  'news.create.facility.schedule.modal.conflict.table.newsletter': 'Thời gian phát',
  'news.create.facility.schedule.modal.conflict.table.repeat.none': 'Không lặp',
  'news.create.facility.schedule.modal.conflict.table.repeat.all': 'Lặp hằng ngày',
  'news.create.facility.schedule.modal.conflict.table.repeat.day': 'Lặp theo tuần vào các ngày',
  'news.create.facility.schedule.modal.conflict.table.repeat.date': 'Lặp theo tháng vào các ngày',
  'news.create.facility.schedule.modal.conflict.table.nearestConflictTime': 'Lần trùng gần nhất',
  'news.create.facility.schedule.modal.conflict.table.overlap': 'Trùng',
  'news.create.facility.schedule.modal.conflict.table.rejectNews': 'Từ chối duyệt',
  'news.create.facility.schedule.modal.conflict.table.cancelNews': 'Hủy bản tin',
  'news.create.facility.schedule.modal.conflict.table.cancelNews.title': 'Xác nhận hủy bản tin',
  'news.create.facility.schedule.modal.conflict.table.cancelNews.content':
    'Bản tin bạn chọn sẽ bị hủy',
  'news.create.device.sunday': 'Chủ nhật',
  'news.create.device.monday': 'Thứ 2',
  'news.create.device.tuesday': 'Thứ 3',
  'news.create.device.wednesday': 'Thứ 4',
  'news.create.device.thursday': 'Thứ 5',
  'news.create.device.friday': 'Thứ 6',
  'news.create.device.saturday': 'Thứ 7',
  'news.create.device.form.validate': 'Thiết bị không được để trống',
  'news.create.device.source.title': '3. Nguồn phát bản tin',
  'news.create.device.source.form.sourceType': 'Loại nguồn tin',
  'news.create.device.source.form.sourceType.placeholder': 'Vui lòng chọn loại nguồn tin',
  'news.create.device.source.form.template': 'Bản mẫu',
  'news.create.device.source.form.media': 'Media',
  'news.create.device.source.form.playlist': 'Danh sách phát',
  'news.create.device.source.form.sourceId': 'Nguồn phát bản tin',
  'news.create.device.source.form.sourceId.placeholder': 'Vui lòng chọn nguồn phát bản tin',
  'news.create.device.source.form.sourceDuration': 'Thời lượng',
  'news.create.device.source.form.validatorMinimumDuration': 'Thời lượng phải lớn hơn 00:00:00',
  'news.create.device.source.form.validatorRequiredDuration': 'Thời lượng không được bỏ trống',
  'news.create.device.schedule.form.type': 'Chế độ phát bản tin',
  'news.create.device.schedule.form.schedule.': 'Phát theo lịch',
  'news.create.device.schedule.form.schedule.after-approve': 'Phát ngay sau khi phê duyệt',
  'news.create.device.schedule.form.timeSlot': 'Giờ phát bản tin',
  'news.create.device.schedule.form.timeSlot.timeRelease': 'Theo thời lượng',
  'news.create.device.schedule.form.timeSlot.timeSlot': 'Theo khung giờ',
  'news.create.device.schedule.form.dayFrom': 'Ngày phát bản tin',
  'news.create.device.schedule.form.validatorReleaseDate':
    'Ngày bắt đầu phải nhỏ hơn ngày kết thúc.',
  'news.create.device.schedule.form.validatorReleaseDateRequired': 'Vui lòng nhập ngày phát',
  'news.create.device.schedule.form.dayTo': 'Ngày kết thúc bản tin',
  'news.create.device.schedule.titleSchedule': '4. Lịch phát bản tin',
  'news.create.device.schedule.form.validatorTimeSlots-wrongPosition':
    'Thời gian phát phải theo đúng tự từ nhỏ đến lớn',
  'news.create.device.schedule.form.validatorTimeSlots':
    'Thời gian lặp bản tin không được trùng nhau',
  'news.create.device.schedule.form.validatorTimeSlots-1':
    'Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc',
  'news.create.device.schedule.form.validatorTimeSlots-required': 'Vui lòng chọn thời gian.',
  'news.create.device.schedule.form.validatorTimeSlots-overDay':
    'Thời gian kết thúc không được lớn hơn 23:59:59',
  'news.create.device.schedule.form.numberRepeat': 'Số lần phát',
  'news.create.device.schedule.repeatForm.typeRepeat': 'Chế độ lặp',
  'news.create.device.schedule.repeatForm.typeRepeat.none': 'Không lặp',
  'news.create.device.schedule.repeatForm.typeRepeat.all': 'Lặp hằng ngày',
  'news.create.device.schedule.repeatForm.typeRepeat.day': 'Lặp theo tuần vào các ngày',
  'news.create.device.schedule.repeatForm.typeRepeat.date': 'Lặp theo tháng vào các ngày',
  'news.create.device.compo.title': '2. Thiết bị',
  'news.create.device.compo.placeholderName': 'Nhập tên thiết bị',
  'news.create.device.compo.table.name': 'Thiết bị',
  'news.create.device.compo.table.facility.name': 'Cơ sở lắp đặt',
  'news.create.device.compo.table.specificAddress': 'Địa chỉ lắp đặt',
  'news.create.device.compo.modal.search.text': 'Danh sách thiết bị đã chọn',
  'news.create.device.compo.modal.title': 'Chọn thiết bị phát bản tin',
  'news.create.device.compo.modal.facility': 'Cơ sở',
  'news.create.device.compo.modal.facility.placeholder': 'Vui lòng chọn cơ sở',
  'news.create.device.compo.modal.tableDevices.table.name': 'Thiết bị',
  'news.create.device.compo.modal.tableDevices.table.facility.name': 'Cơ sở lắp đặt',
  'news.create.device.compo.modal.tableDevices.table.specificAddress': 'Địa chỉ lắp đặt',
  'news.create.device.compo.modal.tableDevicesSelected.table.name': 'Thiết bị',
  'news.create.device.compo.modal.tableDevicesSelected.table.facility.name': 'Cơ sở lắp đặt',
  'news.create.device.compo.modal.tableDevicesSelected.table.specificAddress': 'Địa chỉ lắp đặt',
  'news.create.device.compo.modal.tableDevicesSelected.table.remove': 'Gỡ',
  'news.create.device.modal.conflict.title': 'Danh sách bản tin bị trùng lịch',
  'news.create.device.modal.conflict.okText': 'Ghi đè',
  'news.create.device.modal.conflict.deviceName': 'Thiết bị',
  'news.create.device.modal.conflict.deviceLastItem': 'Phải có ít nhất một thiết bị phát',
  'news.create.device.modal.conflict.table.newsletter.title': 'Bản tin',
  'news.create.device.modal.conflict.table.newsletter.facility.name': 'Cơ sở tạo',
  'news.create.device.modal.conflict.table.device': 'Thiết bị',
  'news.create.device.modal.conflict.table.newsletter': 'Thời gian phát',
  'news.create.device.modal.conflict.table.nearestConflictTime': 'Lần trùng gần nhất',
  'news.create.device.modal.conflict.table.repeat.none': 'Không lặp',
  'news.create.device.modal.conflict.table.repeat.all': 'Lặp hằng ngày',
  'news.create.device.modal.conflict.table.repeat.day': 'Lặp theo tuần vào các ngày',
  'news.create.device.modal.conflict.table.repeat.date': 'Lặp theo tháng vào các ngày',
  'news.create.device.modal.conflict.table.overlap': 'Trùng',
  'news.create.device.modal.conflict.table.rejectNews': 'Từ chối duyệt',
  'news.create.device.modal.conflict.table.cancelNews': 'Hủy bản tin',
  'news.create.device.modal.conflict.table.cancelNews.title': 'Xác nhận hủy bản tin',
  'news.create.device.modal.conflict.table.cancelNews.content': 'Bản tin bạn chọn sẽ bị hủy',
  'news.create.device.info.location.title': '1. Thông tin bản tin',
  'news.create.device.info.form.name': 'Tiêu đề',
  'news.create.device.info.form.field': 'Lĩnh vực',
  'news.create.device.info.form.field.placeholder': 'Vui lòng chọn lĩnh vực',
  'subNews.router.info.detail.name': 'Thông tin bản tin',
  'subNews.router.info.history.name': 'Lịch sử phát bản tin',
  'subNews.router.name': 'Bản tin cơ sở con',
  'subNews.title': 'Danh sách bản tin cơ sở con',
  'subNews.table.title': 'Tiêu đề',
  'subNews.table.type': 'Loại bản tin',
  'subNews.table.bulletinStatus': 'Trạng thái',
  'subNews.table.repeatType': 'Kiểu lặp',
  'subNews.table.finalDate': 'Ngày phát',
  'subNews.table.timeSlots': 'Giờ phát',
  'subNews.table.facilities': 'Cơ sở phát',
  'subNews.table.creator.name': 'Người tạo',
  'subNews.info.tabs.label.1': 'Thông tin bản tin',
  'subNews.info.tabs.label.2': 'Lịch sử phát bản tin',
  'subNews.info.history.title': 'Lịch sử phát bản tin',
  'subNews.info.history.table.device.code': 'Mã thiết bị',
  'subNews.info.history.table.device.name': 'Tên thiết bị',
  'subNews.info.history.table.startTime': 'Thời gian bắt đầu phát',
  'subNews.info.history.table.endTime': 'Thời gian kết thúc phát',
  'subNews.info.history.table.newsletter.facilities': 'Cơ sở',
  'subNews.info.history.filter.startTime': 'Thời gian bắt đầu phát',

  //
  'news.create.device.compo.modal.tableDevicesSelected.table.add.all': 'Thêm tất cả',
  'news.create.device.compo.modal.tableDevicesSelected.table.remove.all': 'Bỏ tất cả',

  // update device group
  'news.create.device.compo.table.deviceGroup': 'Nhóm thiết bị',
  'news.create.device.compo.modal.deviceGroup': 'Nhóm thiết bị',
  'news.create.device.compo.modal.deviceGroup.placeholder': 'Vui lòng chọn nhóm thiết bị',
  'news.create.device.compo.modal.device.list': 'Danh sách thiết bị',
  'news.info.detail.table.device.name': 'Thiết bị',
  'news.info.detail.table.device.facility.name': 'Cơ sở lắp đặt',
  'news.info.detail.table.device.deviceGroups': 'Nhóm thiết bị',
};
