import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerMediaInfo: IRouter = {
  path: '/source-information/media/info/:id/',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.media.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.MediasViewDetail,
};

export const routerOrganizationMediaInfo: IRouter = {
  path: '/organization/:organizationId/source-information/media/info/:id/',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.media.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath: (organizationId, id) =>
    `/organization/${organizationId}/source-information/media/info/${id}`,
};

export const routerMediaInfoFacility: IRouter = {
  path: '/source-information/media/info/:id/:facilityId/',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.media.info.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.MediasViewDetail,
  masterLayout: true,
};

export const routerOrganizationMediaInfoFacility: IRouter = {
  path: '/organization/:organizationId/source-information/media/info/:id/:facilityId/',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.media.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath: (organizationId, id) =>
    `/organization/${organizationId}/source-information/media/info/${id}`,
};
