export default {
  'announcements.router.name': 'Notification',
  'announcements.router.nameBreadcrumb': 'Notification Management',
  'announcements.title': 'List of Notification',
  'announcements.table.subject': 'Title',
  'announcements.table.content': 'Content',
  'announcements.table.sendingStatus': 'Status',
  'announcements.table.organizations': 'Received Account',
  'announcements.table.sendTime': 'Time to sent',
  'announcements.table.createdAt': 'Created Time',
  'announcements.table.creator.name': 'Creator',
  'announcements.table.status.param': '{status,select, 1 {Sent} 2 {Unsent} other {Unknown}}',
  'announcements.filter.sendingStatus': 'Status',
  'announcements.filter.sendingStatus.sent': 'Sent',
  'announcements.filter.sendingStatus.unsent': 'Unsent',
  'announcements.action.delete.title': 'Notification Deletion Confirmation',
  'announcements.action.delete.content':
    'This notification will be deleted and can not be restored',
  'announcements.modal.create.checkbox.system': 'System Notification',
  'announcements.modal.create.checkbox.email': 'Email',
  'announcements.modal.create.subject': 'Title',
  'announcements.modal.create.organizationIds': 'Received Account',
  'announcements.modal.create.sendTime': 'To be sent at',
  'announcements.modal.create.channels': 'Channel',
  'announcements.modal.create.content': 'Content',
  'announcements.modal.create': 'Add Notification',
  'announcements.modal.create.validator-subject': 'Please enter a title',
  'announcements.modal.create.validatorUndefined': 'Title format is incorrect',
  'announcements.modal.information': 'Notification Information',
  'announcements.modal.update': 'Update Notification',
  'announcements.modal.create.validatorTime': 'To be sent must be later than the current time',
};
