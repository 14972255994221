import React from 'react';
import { BookOpen } from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerUserLogs: IRouter = {
  path: '/settings/user-logs',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.userLogs.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <BookOpen />,
};

export const routerOrganizationUserLogs: IRouter = {
  path: '/settings/organization/:organizationId/user-logs',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.userLogs.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <BookOpen />,
  activePath: '/user-logs',
  generatePath(organizationId) {
    return `/settings/organization/${organizationId}/user-logs`;
  },
};
