import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerRole: IRouter = {
  path: '/settings/roles',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.systemUser.router.role.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'settings.systemUser.router.role.breadcrumb',
  masterLayout: true,
  icon: <UserOutlined />,
  permissionCode: [
    PermissionEnum.UsersView,
    PermissionEnum.RolesView,
    PermissionEnum.UsersViewUnAuthorized,
  ],
  generatePath(_, listPermission) {
    const permissionTabs: any = [PermissionEnum.UsersView, PermissionEnum.RolesView];
    if (permissionTabs.find(item => listPermission?.includes(item))) {
      return `/settings/roles?tab=${permissionTabs.find(item => listPermission.includes(item))}`;
    }
    return `/settings/roles?tab=${PermissionEnum.RolesView}`;
  },
};

export const routerOrganizationRole: IRouter = {
  path: '/settings/organization/:organizationId/roles',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.systemUser.router.role.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'settings.systemUser.router.role.breadcrumb',
  masterLayout: true,
  activePath: '/roles',
  icon: <UserOutlined />,
  generatePath(organizationId) {
    return `settings/organization/${organizationId}/roles`;
  },
};
