/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Popover } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { iconDot } from '@assets/images';
import CONFIG from '@config';
import { LanguageSelector } from '@modules/setting/settingStore';

const IFrame = () => {
  const { language } = useSelector(LanguageSelector);

  const Content = (
    <iframe
      src={`${CONFIG.LOGIN_PAGE}application-iframe?language=${language}`}
      width="100%"
      height="100%"
    />
  );

  return (
    <Popover
      content={Content}
      trigger="click"
      overlayClassName="ifame-popover"
      getPopupContainer={() => document.getElementById('header') as any}
      placement="rightBottom"
      //@ts-ignore
      arrow={false}
    >
      <img src={iconDot} alt="" className="icon-dot" />
    </Popover>
  );
};

export default React.memo(IFrame);
