import { RootState } from '@modules';
import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';

import OrganizationsEntity from './organizationsEntity';

interface IOrganizationsStore {
  organization?: OrganizationsEntity;
}

const organizationsStore = createSlice({
  name: 'organizations',
  initialState: {
    organization: undefined,
  } as IOrganizationsStore,
  reducers: {
    setOrganization: (state, action: PayloadAction<OrganizationsEntity>) => {
      state.organization = action.payload;
    },
    removeOrganization: state => {
      state.organization = undefined;
    },
  },
});
export const OrganizationsSelector: Selector<RootState, OrganizationsEntity | undefined> = state =>
  state.organizationsStore.organization;

export default organizationsStore;
