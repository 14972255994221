import { Dayjs } from 'dayjs';

import ScheduleEntity from '@modules/devices/scheduleEntity';

export enum ViewModeEnum {
  Hour = 1,
  Minute = 2,
}
export interface IDate {
  endValue: Dayjs | null;
  startValue?: Dayjs;
  startDate: Dayjs;
  endDate: Dayjs;
}

export interface IOptionNewHour {
  label: string;
  value: number;
  row: number;
  height?: number;
}

export interface ICard {
  id: string;
  data: ScheduleEntity;
  startTime: string;
  endTime: string;
}

export interface IFormatData extends ICard {
  level: number;
  sameIds: string[];
  status: boolean;
}
