export default {
  'notifications.title': 'Thông báo',
  'notifications.more': 'Xem thêm',
  'notifications.readAll': 'Đánh dấu tất cả đã đọc',
  'notifications.tabs.organizations': 'Thông báo tổ chức',
  'notifications.tabs.internal': 'Thông báo nội bộ',
  'notifications.today': 'Hôm nay',
  'notifications.time': '{value} phút trước',
  'notifications.hour': '{value} giờ trước',
  APPROVE_MEDIA: 'Nguồn tin được phê duyệt',
  REFUSE_MEDIA: 'Từ chối media',
  SHARE_MEDIA: 'Chia sẻ media',
  RECEPT_MEDIA: 'Tiếp nhận media',
  APPROVE_NEWSLETTER: 'Bản tin được phê duyệt',
  REFUSE_NEWSLETTER: 'Từ chối duyệt bản tin',
  OVERLAP_NEWSLETTER: 'Trùng lịch phát bản tin',
  EXPIRING_TARIFF_PACKAGE: 'Sắp hết hạn gói cước',
  LOWSTORAGE_TARIFF_PACKAGE: 'Sắp hết dung lượng',
  MEDIA_FILE: 'Media file',
  APPROVED_BY: 'đã được phê duyệt bởi',
  REFUSED_BY: 'đã bị từ chối duyệt bởi',
  YOU_HAVE: 'Bạn có',
  SHARED_BY: 'được chia sẻ từ cơ sở',
  YOUR_FACILITY_HAVE: 'Cơ sở của bạn có',
  RECEPTED_BY: 'được tiếp nhận bởi',
  NEWSLETTER: 'Bản tin',
  OVERLAP_WITH: 'đang bị phát trùng lịch với bản tin',
  CREATED_BY_FACILITY: 'tạo bởi cơ sở',
  TARIFF_PACKAGE: 'Gói cước',
  LEFT: 'còn',
  WILL_EXPIRE: 'ngày nữa sẽ hết hạn.',
  WILL_LOW_ON_STORAGE: 'nữa sẽ hết dung lượng.',
  MEDIA_NEED_APPROVE: 'Nguồn tin cần phê duyệt',
  CREATED_BY: 'được tạo bởi',
  NEEDS_TO_BE_APPROVED: 'cần được phê duyệt',
  NEWSLETTER_NEED_APPROVE: 'Bản tin cần phê duyệt',
};
