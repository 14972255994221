export default {
  'packageManagement.router.name': 'Subscription Management',
  'packageManagement.menu.name': 'Subscription Management',
  'packageManagement.summary.current.title': 'Current Subscription',
  'packageManagement.summary.current.infinite': 'unlimited',
  'packageManagement.summary.current.infinite.2': 'Unlimited',
  'packageManagement.summary.current.paypal.text': 'PayPal',
  'packageManagement.summary.current.btn.cancel': 'Cancel Auto-Renew',
  'packageManagement.summary.current.btn.upgrade': 'Upgrade',
  'packageManagement.summary.current.btn.renew': 'Renew',
  'packageManagement.summary.future.nextRenewal': 'Next Renewal',
  'packageManagement.summary.future.nextPackage': 'Next Subscription',
  'packageManagement.summary.future.expiryTime': 'Valid To',
  'packageManagement.summary.future.label.startTime': 'Valid From',
  'packageManagement.summary.current.cancel.title.1': 'Confirm Cancel Auto-Renew',
  'packageManagement.summary.current.cancel.title.2': 'Confirm Cancel Subscription',
  'packageManagement.summary.current.cancel.content.title.1': 'Renewal Subscription',
  'packageManagement.summary.current.cancel.content.title.2': 'Next Subscription',
  'packageManagement.summary.current.cancel.content.capacity': 'Capacity',
  'packageManagement.summary.current.cancel.content.duration': 'Duration',
  'packageManagement.summary.current.cancel.content.label': 'Content',
  'packageManagement.summary.current.cancel.content.text.1':
    'When you cancel auto-renewal, if you do not register for renewal by {date}',
  'packageManagement.summary.current.cancel.content.text.2':
    'your organization will be deactivated, and data will <strong>be permanently deleted 30 days</strong> after expiration.',
  'packageManagement.summary.current.cancel.content.text.3':
    'If you cancel this next package, we will continue to renew your current subscription for your next renewal period.',
  'packageManagement.modal.extend.tariff.upgradePackage.warning.title':
    'Subscription Upgrade Failed',
  'packageManagement.modal.extend.tariff.upgradePackage.warning.content':
    'Your organization already has a subscription waiting to be used. To switch to another subscription, please contact the system admin.',
  'packageManagement.summary.upgradePackage.warning.title': 'Subscription Upgrade Failed',
  'packageManagement.summary.upgradePackage.warning.content':
    'Your organization already has a subscription waiting to be used. To switch to another subscription, please contact the system admin.',
  'packageManagement.modal.extend.tariff.status': 'Status',
  'packageManagement.modal.extend.tariff.content.title': 'Renewed Subscription',
  'packageManagement.modal.extend.tariff.end': 'Discontinued',
  'packageManagement.modal.extend.tariff.error.1': 'Please select another subscription to use',
  'packageManagement.modal.extend.tariff.capacity': 'Capacity',
  'packageManagement.modal.extend.tariff.time': 'Duration',
  'packageManagement.modal.extend.tariff.btn.otherPackage': 'Other Subscription',
  'packageManagement.renewalHistory.title.1': 'Subscription Information',
  'packageManagement.renewalHistory.title.2': 'Subscription Renewal History',
  'packageManagement.renewalHistory.tariffPackagesMonth': 'Current Subscription',
  'packageManagement.renewalHistory.tariffPackagesTime': 'Valid To',
  'packageManagement.renewalHistory.tariffPackages.ghn': 'Renew Now',
  'packageManagement.renewalHistory.tariffPackages.btn.ghn': 'Renew',
  'packageManagement.renewalHistory.tariffPackagesUpdate': 'Upgrade Subscription',
  'packageManagement.renewalHistory.tariffPackagesUpdate.btn': 'Upgrade',
  'packageManagement.renewalHistory.createdAt': 'Renewal Period',
  'packageManagement.renewalHistory.upgradePackage.warning.title': 'Subscription Upgrade Failed',
  'packageManagement.renewalHistory.upgradePackage.warning.content':
    'Your organization already has a subscription waiting to be used. To switch to another subscription, please contact the system admin.',
  'packageManagement.renewalHistory.table.payer.name': 'Payer',
  'packageManagement.renewalHistory.table.payer.phoneNumber': 'Phone Number',
  'packageManagement.renewalHistory.table.gracetime': 'Renewal Period',
  'packageManagement.renewalHistory.table.effectiveTime': 'Validity Period',
  'packageManagement.renewalHistory.table.capacity': 'Renewed Subscription',
  'packageManagement.renewalHistory.table.price': 'Subscription Price',
  'packageManagement.renewalHistory.table.paymentStatus': 'Status',
  'packageManagement.renewalHistory.table.paymentStatus.params':
    '{status,select, 1 {Success} 2 {Processing} 3 {Failed} 4 {Cancelled} 5 {Unpaid} other {Other}}',
  'packageManagement.renewalHistory.table.paymentMethod': 'Payment Method',
  'packageManagement.renewalHistory.table.paymentMethod-1': 'Direct',
  'packageManagement.renewalHistory.table.paymentMethod-2': 'E-wallet',
  'packageManagement.renewalHistory.table.paymentMethod-3': 'VNPay',
  'packageManagement.renewalHistory.table.paymentMethod-5': 'PayPal',
  'packageManagement.renewalHistory.table.paymentMethod-6': 'OnePay',
  'packageManagement.renewalHistory.table.paymentMethod-4': '--',
  'packageManagement.renewalHistory.table.paymentMethod-other': 'Other Method',
  //
  'packageManagement.summary.current.deviceQuantity': 'Up to {deviceQuantity} device(s)',
  'packageManagement.summary.current.cancel.content.deviceLimit.unlimited': 'Unlimited',
  'packageManagement.summary.current.cancel.content.deviceLimit': 'Device',
  'packageManagement.modal.extend.tariff.deviceQuantity': 'Up to {deviceQuantity} device(s)',
  'packageManagement.modal.extend.tariff.deviceLimit.unlimited': 'Unlimited',
  'packageManagement.modal.extend.tariff.deviceLimit': 'Device',
};
