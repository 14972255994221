import { DependencyList, useCallback, useState } from 'react';

export interface IState<T> {
  status: 'ready' | 'loading' | 'error';
  value?: T | any;
  error: any;
  execute?: any;
}

export function useSingleAsync<T = any>(
  asyncFunction: (...params: any[]) => Promise<T>,
  deps: DependencyList = [],
) {
  const [state, setState] = useState<IState<T>>({
    status: 'ready',
    value: undefined,
    error: null,
  });

  const onSuccess = useCallback((response: T) => {
    setState(prevState => ({
      ...prevState,
      status: 'ready',
      value: response,
      error: null,
    }));
    return Promise.resolve(response);
  }, deps);

  const onError = useCallback((error: any) => {
    setState(prevState => ({
      ...prevState,
      status: 'error',
      error: error,
      value: undefined,
    }));
    return Promise.reject(error);
  }, deps);

  const execute = useCallback(
    (...args: any[]) => {
      setState(prevState => ({
        ...prevState,
        status: 'loading',
      }));
      return asyncFunction(...args)
        .then(onSuccess)
        .catch(onError);
    },
    [onError, onSuccess, deps],
  );
  return { execute, ...state };
}
