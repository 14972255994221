import React from 'react';

import { UilFileAlt } from '@iconscout/react-unicons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

const mainRoute = 'news-management';

export const routerNewsManagement: IRouter = {
  path: `/${mainRoute}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'news.router.name.1', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'news.router.nameBreadcrumb',
  masterLayout: true,
  menu: {
    icon: <UilFileAlt />,
  },
  permissionCode: [PermissionEnum.NewslettersView, PermissionEnum.NewslettersSubUnitView],
  generatePath(_, listPermission) {
    const permissionTabs = [PermissionEnum.NewslettersView, PermissionEnum.NewslettersSubUnitView];
    const tab = permissionTabs.find(it => listPermission?.some(item => item === it));
    if (tab) {
      return `/${mainRoute}?tab=${tab}`;
    }
    return `/${mainRoute}?tab=${PermissionEnum.NewslettersView}`;
  },
};

export const routerNewsManagementOrganizations: IRouter = {
  path: `/organizations/:organizationId/${mainRoute}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'news.router.name.1', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'news.router.nameBreadcrumb',
  masterLayout: true,
  activePath: `/${mainRoute}`,
  menu: {
    icon: <UilFileAlt />,
  },
  permissionCode: [PermissionEnum.NewslettersView, PermissionEnum.NewslettersSubUnitView],
  generatePath(organizationId, listPermission) {
    const permissionTabs: any = [
      PermissionEnum.NewslettersView,
      PermissionEnum.NewslettersSubUnitView,
    ];
    const tab = permissionTabs.find(it => listPermission?.some(item => item === it));
    if (tab) {
      return `/organizations/${organizationId}/${mainRoute}?tab=${tab}`;
    }
    return `/organizations/${organizationId}/${mainRoute}?tab=${PermissionEnum.NewslettersView}`;
  },
};

export const routerNewsManagementAdd: IRouter = {
  path: `/${mainRoute}/add`,
  loader: React.lazy(() => import('./components/News/component/Form/index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.NewslettersCreate,
};

export const routerOrganizationNewsManagementAdd: IRouter = {
  path: `/organization/:organizationId/${mainRoute}/add`,
  loader: React.lazy(() => import('./components/News/component/Form/index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  activePath: `/${mainRoute}/add`,
  permissionCode: PermissionEnum.NewslettersCreate,
  generatePath(organizationId) {
    return `/organization/${organizationId}/${mainRoute}/add`;
  },
};

export const routerNewsManagementEdit: IRouter = {
  path: `/${mainRoute}/edit/:id`,
  loader: React.lazy(() => import('./components/News/component/Form/index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.NewslettersUpdate,
};

export const routerOrganizationNewsManagementEdit: IRouter = {
  path: `/organization/:organizationId/${mainRoute}/edit/:id`,
  loader: React.lazy(() => import('./components/News/component/Form/index')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  activePath: `/${mainRoute}/edit/:id`,
  permissionCode: PermissionEnum.NewslettersUpdate,
  generatePath(organizationId) {
    return `/organization/${organizationId}/${mainRoute}/edit/:id`;
  },
};

export const routerNewsDetail: IRouter = {
  path: `/${mainRoute}/news-detail/:id`,
  loader: React.lazy(() => import('./components/News/component/NewsInfo')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.NewslettersViewDetail,
};

export const routerNewsDetailOrganizations: IRouter = {
  path: `/organizations/:organizationId/${mainRoute}/news-detail/:id`,
  loader: React.lazy(() => import('./components/News/component/NewsInfo')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.NewslettersViewDetail,
  generatePath(organizationId) {
    return `/organizations/${organizationId}/${mainRoute}/news-detail/:id`;
  },
};

export const routerNewsInfo: IRouter = {
  path: '',
  permissionCode: PermissionEnum.NewslettersViewDetail,
  name: 'news.router.info.detail.name', //translate here for breadcrumb and sidebar
};

export const routerNewsHistory: IRouter = {
  path: '',
  name: 'news.router.info.history.name', //translate here for breadcrumb and sidebar
};

export const routerSubNewsDetail: IRouter = {
  path: `/${mainRoute}/sub-news-detail/:id`,
  loader: React.lazy(() => import('./components/SubNews/component/SubNewsInfo')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.NewslettersSubUnitViewDetail,
};

export const routerSubNewsDetailOrganizations: IRouter = {
  path: `/organizations/:organizationId/${mainRoute}/sub-news-detail/:id`,
  loader: React.lazy(() => import('./components/SubNews/component/SubNewsInfo')),
  exact: true,
  name: '', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.NewslettersSubUnitViewDetail,
  generatePath(organizationId) {
    return `/organizations/${organizationId}/${mainRoute}/sub-news-detail/:id`;
  },
};

export const routerSubNewsInfo: IRouter = {
  path: '',
  name: 'subNews.router.info.detail.name', //translate here for breadcrumb and sidebar
};

export const routerSubNewsHistory: IRouter = {
  path: '',
  name: 'subNews.router.info.history.name', //translate here for breadcrumb and sidebar
};

export const routerNews: IRouter = {
  path: `/news-management?tab=${PermissionEnum.NewslettersView}`,
  name: 'news.router.name.2', //translate here for breadcrumb and sidebar
};

export const routerNewsOrganizations: IRouter = {
  path: `/organizations/:organizationId/${mainRoute}?tab=${PermissionEnum.NewslettersView}`,
  name: 'news.router.name.2', //translate here for breadcrumb and sidebar
  generatePath(organizationId) {
    return `/organizations/${organizationId}/${mainRoute}?tab=${PermissionEnum.NewslettersView}`;
  },
};

export const routerSubNews: IRouter = {
  path: `/news-management?tab=${PermissionEnum.NewslettersSubUnitView}`,
  name: 'subNews.router.name', //translate here for breadcrumb and sidebar
};

export const routerSubNewsOrganizations: IRouter = {
  path: `/organizations/:organizationId/${mainRoute}?tab=${PermissionEnum.NewslettersSubUnitView}`,
  name: 'subNews.router.name', //translate here for breadcrumb and sidebar
  generatePath(organizationId) {
    return `/organizations/${organizationId}/${mainRoute}?tab=${PermissionEnum.NewslettersSubUnitView}`;
  },
};
