import { RootState } from '@modules';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Selector } from 'react-redux';
import FacilitiesEntity from './facilitiesEntity';

interface IFacilityStore {
  facility: FacilitiesEntity;
}

const facilityStore = createSlice({
  name: 'facilityStore',
  initialState: {
    facility: {},
  } as IFacilityStore,
  reducers: {
    updateFacility: (state, action: PayloadAction<FacilitiesEntity>) => {
      return {
        ...state,
        facility: action.payload,
      };
    },
  },
});
export default facilityStore;

export const FacilitySelector: Selector<RootState, IFacilityStore> = state => {
  return {
    facility: state.facilityStore.facility,
  };
};
