export default {
  'stepsView.typing.title': 'Enter your account information',
  'stepsView.congratulation': 'Congratulations!',
  'stepsView.congratulation.desc': 'Your account has been successfully created!',
  'stepsView.go.mtc.solution': 'Go to Homepage',
  'stepsView.go.mtc.login': 'Go to MTC',
  'stepsView.form.organization.name': 'Account Name',
  'stepsView.form.organization.code': 'Account Identifier Code',
  'stepsView.form.organization.code.placeholder': 'Please enter the account identifier code',
  'stepsView.form.organization.code.maxLength': 'Account identifier must be up to 255 characters',
  'stepsView.form.organization.code.required': 'Invalid account identifier code format',
  'stepsView.form.organization.code.exited': 'Account identifier code already exists',
  'stepsView.form.organization.timeZone': 'Timezone',
  'stepsView.form.organization.timeZone.placeholder': 'Please select a timezone',
  'stepsView.form.fullName': 'Full Name',
  'stepsView.form.phoneNumber': 'Phone Number',
  'stepsView.form.email': 'Email',
  'stepsView.form.email.placeholder': 'Please enter email',
  'stepsView.form.email.invalid': 'Invalid email',
  'stepsView.form.email.already.exist': 'Email already exists',
  'stepsView.form.username': 'Username',
  'stepsView.form.username.placeholder': 'Please enter the login username',
  'stepsView.form.username.required': 'The username format is invalid.',
  'stepsView.form.username.note': 'The username to access is',
  'stepsView.form.password': 'Password',
  'stepsView.form.password.placeholder': 'Please enter the password',
  'stepsView.form.password.required':
    'The password must be at least 8 characters long and include lowercase letters, uppercase letters, numbers, and special characters.',
  'stepsView.form.confirmPassWord': 'Confirm Password',
  'stepsView.form.confirmPassWord.required': 'The two passwords do not match',
  'stepsView.form.confirmPassWord.placeholder': 'Please enter the confirmation password',
  'stepsView.form.role.label': 'Role',
  'stepsView.form.role.value': 'Account Administrator',
};
