export default {
  'common.empty': ' ',
  'common.ok': 'OK',
  'common.action': 'Action',
  'common.save': 'Save',
  'common.cancel': 'Cancel',
  'common.home': 'Home',
  'common.display': 'Display',
  'common.entries': 'items per page',
  'common.form.required': 'Please enter',
  'common.delete': 'Delete',
  'common.share': 'Share',
  'common.listen': 'Listen',
  'common.statusActive': 'Active',
  'common.add': 'Add',
  'common.addSchool': 'Register School',
  'common.edit': 'Update',
  'common.all': 'All',
  'common.keyword': 'Keyword',
  'common.statusDeactive': 'Inactive',
  'common.button.accept': 'Confirm',
  'common.button.resend.link': 'Resend link',
  'common.button.return': 'Return',
  'common.button.continue': 'Continue',
  'common.button.complete': 'Complete',
  'link.return.login': 'Return to login page',
  'common.answers': 'Answer List',
  'common.valid.required': 'Please fill in',
  'common.valid.whitespace': 'Please do not enter whitespace',
  'common.valid.email': 'Must be a valid format',
  'common.deviceType': 'Device Type',
  'common.email': 'Email Address',
  'common.update.user': 'Edit Profile',
  'common.update.change-password': 'Change Password',
  'common.yes': 'Yes',
  'common.download': 'Download',
  'common.cancelNews': 'Cancel Broadcast',
  'common.logOut': 'Log Out',
  'common.profile': 'Profile',
  'common.forgotPassword': 'Forgot Password',
  'common.volumn': 'Adjust Volume',
  'common.powerOn': 'Power On',
  'common.powerOff': 'Power Off',
  'common.refresh': 'Restart',
  'common.update': 'Update',
  'common.continue': 'Continue',
  'common.management': 'Management',
  'common.formNote': 'This is a required field',
  'common.active': 'Active',
  'common.deactive': 'Inactive',
  'common.status': 'Status',
  'common.statusNotApproval': 'Not Approved',
  'common.statusReject': 'Rejected',
  'common.invalid': 'Invalid format.',
  'common.input.placeholder': 'Please enter {label}',
  'common.status.param':
    '{status,select, 0 {Waiting for Approval} 1 {Approved} 2 {Refused} other {Undefined}}',
  'common.status.new': 'Not Approved',
  'common.status.approval': 'Approved',
  'common.approval': 'Approval',
  'common.file-back': 'Recall',
  'common.back': 'Go Back',
  'common.statusConnect': 'Connecting',
  'common.statusDisconnect': 'Disconnected',
  'common.StatusNewsletterPlaying': 'Playing Broadcast',
  'common.StatusNewsletterStop': 'Stopped Playing Broadcast',
  'common.goBack': 'Return',
  'common.required.password': 'Please enter a password',
  'common.info': 'Information',
  'dashboard.area': 'Area',
  'dashboard.radio_device': 'Radio Device',
  'dashboard.mtc_device': 'Public Electronic Device',
  'dashboard.audio_news': 'Radio News',
  'dashboard.mtc_news': 'Public News',
  'area.placeholder': 'Please enter area name',
  'common.remove': 'Remove',
  'common.play': 'Play',
  'common.no.': 'No.',
  'common.stt': 'No.',
  'common.detail': 'Detail',
  'common.non.title': 'Action',
  'common.turnOn': 'Turn On',
  'common.turnOff': 'Turn Off',
  'common.pause': 'Pause',
  'common.convert': 'Convert',
  'common.using': 'In Use',
  'common.notUse': 'Unused',
  'common.check': 'Save',
  'common.close': 'Close',
  'common.noti': 'Notification',
  'common.viewPlus': 'View More',
  'common.return': 'Return',
  'common.search.placeholder': 'Enter keyword to search',
  'common.eye': 'Show',
  'common.eyeOff': 'Hide',
  'common.hidden': 'Not Displayed',
  'common.in.process': 'Uploading',
  'common.logout': 'Log Out',
  'common.logout.title': 'Confirm Log Out',
  'common.logout.content': 'Do you really want to log out?',
  'common.change.password': 'Change Password',
  'common.report': 'Report',
  'common.page': 'Page',
  'common.search': 'Search',
  'common.transactions': 'Transactions',
  'common.product': 'Product',
  'common.today': 'Today',
  'common.file': 'Export file',
  'common.checkAll': 'Select All',
  'common.check-in': 'Checked In',
  'common.not-check-in': 'Not Checked In',
  'common.LifeBuoy': 'Check-In Log',
  'common.total': 'Total Amount',
  'common.month': 'month',
  'common.month2': 'Month',
  'common.day': 'day',
  'common.file-edit': 'Package Extension',
  'common.upload': 'Choose File',
  'common.file-check-green': 'Approve',
  'common.file-check-red': 'Reject Approval',
  'common.minus': 'Delete',
  'common.preview': 'Preview',
  'common.403error': '403 Error',
  'common.permission.notfound': 'You do not have access rights',
  'common.403note':
    'You do not have the permission for this system. Please contact the admin for support.',
  'common.selected': '{rows} items selected',
  'common.confirm': 'Confirm',
  'common.volume': 'Volume',
  'common.qr': 'QR Code',
  'common.upgrade': 'Upgrade',
  'common.backToSystem': 'Return to Management System',
  'common.pay': 'Pay',
  'common.pickOther': 'Choose Another Package',
  'common.control': 'Control',
  'common.operationStatus': 'Operation Status',
  'common.unlimited': 'Unlimited',
  'common.year': 'year',
  'common.usedPackage': 'Package in Use',
  'common.InActive': 'Inactive',
  'common.Used': 'In Used',
  'common.linkError': 'Sorry, the link is invalid.',
  'common.notPlay': 'Sorry, the system currently does not support playing this type of media.',
  'common.notImplementedYet': 'Feature under development',
  'common.qrCode': 'QR code',
  'common.renew': 'Renew Payment',
  'common-required-phone-country': 'Phone number must be between 5 and 15 characters!',
};
