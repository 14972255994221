import { combineReducers } from '@reduxjs/toolkit';

import profileStore from './authentication/profileStore';
import facilityStore from './facilities/facilitiesStore';
import organizationsStore from './organizations/organizationStore';
import settingStore from './setting/settingStore';
import userStore from './user/userStore';

const appReducer = combineReducers({
  profile: profileStore.reducer,
  settingStore: settingStore.reducer,
  facilityStore: facilityStore.reducer,
  organizationsStore: organizationsStore.reducer,
  userStore: userStore.reducer,
});

export type RootState = ReturnType<typeof appReducer>;
export default appReducer;
