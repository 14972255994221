export default {
  'announcements.router.name': 'Thông báo',
  'announcements.router.nameBreadcrumb': 'Quản lý thông báo',
  'announcements.title': 'Danh sách thông báo',
  'announcements.table.subject': 'Tiêu đề',
  'announcements.table.content': 'Nội dung',
  'announcements.table.sendingStatus': 'Trạng thái ',
  'announcements.table.organizations': 'Tổ chức nhận thông báo',
  'announcements.table.sendTime': 'Thời gian gửi',
  'announcements.table.createdAt': 'Thời gian tạo',
  'announcements.table.creator.name': 'Người tạo',
  'announcements.table.status.param':
    '{status,select, 1 {Đã gửi} 2 {Chưa gửi} other {Không xác định}}',
  'announcements.filter.sendingStatus': 'Trạng thái',
  'announcements.filter.sendingStatus.sent': 'Đã gửi',
  'announcements.filter.sendingStatus.unsent': 'Chưa gửi',
  'announcements.action.delete.title': 'Xác nhận xóa thông báo',
  'announcements.action.delete.content': 'Thông báo này sẽ bị xóa và không thể khôi phục.',
  'announcements.modal.create.checkbox.system': 'Thông báo hệ thống',
  'announcements.modal.create.checkbox.email': 'Email',
  'announcements.modal.create.subject': 'Tiêu đề',
  'announcements.modal.create.organizationIds': 'Tổ chức nhận thông báo',
  'announcements.modal.create.sendTime': 'Hẹn thời gian gửi',
  'announcements.modal.create.channels': 'Kênh gửi thông báo',
  'announcements.modal.create.content': 'Nội dung',
  'announcements.modal.create': 'Thêm thông báo',
  'announcements.modal.create.validator-subject': 'Vui lòng nhập tiêu đề',
  'announcements.modal.create.validatorUndefined': 'Tiêu đề không đúng định dạng',
  'announcements.modal.information': 'Thông tin thông báo',
  'announcements.modal.update': 'Cập nhật thông báo',
  'announcements.modal.create.validatorTime': 'Hẹn thời gian gửi phải lớn hơn thời gian hiện tại',
};
