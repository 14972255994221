import React from 'react';
import { Radio } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerDevices: IRouter = {
  path: '/devices',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'device.router.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'device.router.nameBreadcrumb',
  masterLayout: true,
  menu: {
    icon: <Radio />,
  },
  permissionCode: PermissionEnum.DevicesView,
};

export const routerOrganizationDevice: IRouter = {
  path: '/organization/:organizationId/devices',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'device.router.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'device.router.nameBreadcrumb',
  masterLayout: true,
  activePath: '/devices',
  menu: {
    icon: <Radio />,
  },
  permissionCode: PermissionEnum.DevicesView,
  generatePath(organizationId) {
    return `/organization/${organizationId}/devices`;
  },
};
